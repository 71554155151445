import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function TablePagination(theme: Theme) {
    return {
        MuiTablePagination: {

            styleOverrides: {
                root: {

                },
                input: {
                    '@media (max-width:1400px)': {
                        paddingRight: '15px !important',
                    }
                },
                selectIcon: {
                    '@media (max-width:1600px)': {
                        marginTop: '-2px !important',
                    }
                }
            },
        },
    }
}
