import React from 'react'
import { styled } from '@mui/material/styles'
import { TabList } from '@mui/lab'
import MTab from './MTab'

const StyledTabList = styled(TabList)(({ theme }) => ({
    '&:not(:last-child)': {
        marginRight: theme.spacing(0),
    },
    borderRadius: '5px 5px',
    backgroundColor: '#fff',
    marginBottom: theme.spacing(1),
}))

// @ts-ignore
const MTabList = ({ tabs, children = React.ReactNode, ...rest }) => (
    <StyledTabList
        {...rest}
        indicatorColor='primary'
        TabIndicatorProps={{
            style: {
                backgroundColor: 'transparent',
            },
        }}
        variant='fullWidth'
    >
        {tabs &&
            tabs.map((tab: any) => (
                <MTab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                />
            ))}
        {children}
    </StyledTabList>
)

export default MTabList
