import { useEffect, useRef } from 'react'

export default function usePrevious<T>(value: T): [T, (value: T) => void ] {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref: any = useRef<T>(value);
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return [ ref.current, (value: T) => ref.current = value ];
}
