// Pentagon reference: http://mathworld.wolfram.com/Pentagon.html
import {tau} from './math';
import {rotatePoint} from './rotatePoint';

var circumradiusCoeff = 1/10 * Math.sqrt(50 + 10 * Math.sqrt(5)); // ~ 0.85065080835204

function circumradius(side: number) { return side * circumradiusCoeff; }

function sideLength(area: number) {
    var num = 4 * area;
    var denom = Math.sqrt(5 * (5 + 2 * Math.sqrt(5))); // ~ 6.881909602355868

    return Math.sqrt(num / denom);
}

export default {
    draw: function(context, size) {
        const s = sideLength(size);
        const R = circumradius(s);
        const theta = -tau / 4; // Rotate 1/4 turn back so the shape is oriented with a point upward.

        // @ts-ignore
        context.moveTo.apply(context, rotatePoint(R, 0, theta));

        for (let i = 0; i < 5; ++i) {
            const a = tau * i / 5;
            const x = Math.cos(a) * R;
            const y = Math.sin(a) * R;
            // @ts-ignore
            context.lineTo.apply(context, rotatePoint(x, y, theta));
        }

        context.closePath();
    }
} as d3.SymbolType;