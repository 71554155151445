import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'hooks/useAppRedux';
import { getTopItem, highestPeriodFromWeek } from './utils';
import { SectionComponentProps } from 'views/insights/sections/type';
import { getInsightsEntityData } from 'store/slices/insights';
import DisplayPeriods from 'components/DisplayPeriods';


interface BusinessCapacityDivisionSectionProps extends SectionComponentProps {
    showSubDivisions?: boolean
    showTeams?: boolean
}

const BusinessCapacityDivisionSection = ({
                                             expanded,
                                             onChange,
                                             data,
                                             weeks,
                                             showSubDivisions = false,
                                             showTeams = false
                                         }: BusinessCapacityDivisionSectionProps) => {

    const divisions = useAppSelector(getInsightsEntityData).hierarchyEntities


    const renderContent = (): React.ReactElement => {
        const topDivision = (divisions || [])[0]
        if (!topDivision) {
            return (<Typography variant={'body2'}>No data</Typography>)
        }

        if (showSubDivisions) {
            const topSubDivsion = getTopItem(topDivision.children)
            if (!topSubDivsion) {
                return (<Typography variant={'body2'}>No data</Typography>)
            }

            if (showTeams) {
                const topTeam = getTopItem(topSubDivsion.children)
                if (!topTeam) {
                    return (<Typography variant={'body2'}>No data</Typography>)
                }
                const minH = Math.min(...topTeam.hours)
                const maxH = Math.max(...topTeam.hours)
                const highestPeriod =  highestPeriodFromWeek(topTeam.impacts, weeks)
                return (
                    <Typography variant={'body2'}>
                        <b>{topTeam.name}</b> within <b>{topSubDivsion.name}</b> has the highest impacts between <DisplayPeriods periods={highestPeriod} />.
                        , with weekly hours { minH === maxH ? minH : `between ${minH} and ${maxH}`}.
                    </Typography>
                )
            }
            const highestPeriod =  highestPeriodFromWeek(topSubDivsion.impacts, weeks)
            return (
                <Typography variant={'body2'}>
                    <b>{topSubDivsion.name}</b> within <b>{topDivision.name}</b>  has the highest impacts between <DisplayPeriods periods={highestPeriod} />
                </Typography>
            )

        }

        const highestPeriod =  highestPeriodFromWeek(topDivision.impacts, weeks)
        return (
            <Typography variant={'body2'}>
                <b>{topDivision.name}</b> has the highest impacts between <DisplayPeriods periods={highestPeriod} />
            </Typography>
        )
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>

                    {
                        (!showSubDivisions && !showTeams) ? 'Which division has the highest impacts?' : ''
                    }
                    {
                        (showSubDivisions && !showTeams) ? 'Which sub division has the highest impacts?' : ''
                    }
                    {
                        showTeams   ? 'Which team has the highest impacts?' : ''
                    }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}

export default BusinessCapacityDivisionSection