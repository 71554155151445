import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
    title?: string
    subTitle?: string
}

const NothingToShowYet = ({ title = 'Nothing to show yet', subTitle = '' }: Props) => (
    <Box
        justifyContent='center'
        sx={{ mt: 5 }}
    >
        <img
            src='/static/profile_log_empty.svg'
            alt='profile logo'
            style={{ display: 'block', margin: '0 auto' }}
        />
        <Box
            textAlign='center'
            sx={{ mt: 2 }}
        >
            <Typography
                justifyContent='center'
                variant='h5'
                gutterBottom
            >
                {title}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{subTitle && subTitle}</Typography>
        </Box>
    </Box>
)

export default NothingToShowYet
