import React, { useEffect, useMemo } from 'react'
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GridContainer, GridItem } from 'components/GridElements';
import { useAppDispatch, useAppSelector } from 'hooks/useAppRedux';
import { getCalendarWeeks, reportsDefaultDateRange } from 'utils/date';
import InsightsSkeleton from 'components/SkeletonLoaders/InsightsSkeleton';
import BusinessCapacitySection from './sections/BusinessCapacitySection';
import { DateRange } from 'components/DateRangePicker';
import { formatDate } from 'utils/formatDate';
import InsightsIcon from '@mui/icons-material/Insights';
import { isEmpty } from 'lodash';
import { dummy } from 'store/slices/initiative';
import SequencingRisksSection from './sections/SequencingRisksSection';
import RecommendationsSection from 'views/insights/sections/RecommendationsSection';
import { fetchInsightsData, getAIInsightsData, getInsightsData, isLoadingInsightsData } from 'store/slices/insights'
import InitiativeImpactSection from 'views/insights/sections/InitiativeImpactSection';
import { getStakeholderList, getStakeholders } from 'store/slices/impact/stakeholders';
import AIInsightsSection from './sections/AIInsightsSection'

interface InsightsProps {
    closeHandler?: VoidFunction
    dateRange?: DateRange<Date> | null | undefined
}

const Insights = ({ closeHandler, dateRange }: InsightsProps) => {
    const dispatch = useAppDispatch()
    const employeeStakeHolderList =useAppSelector(getStakeholders)
    const isLoading  = useAppSelector(isLoadingInsightsData)
    const data  = useAppSelector(getInsightsData)
    const aiData  = useAppSelector(getAIInsightsData)

    const selectedDateRange  = dateRange || reportsDefaultDateRange

    const [ loading, setLoading ] = React.useState(false)

    const [ openedTabs, setOpenedTabs ] = React.useState<Record<string, boolean>>({})

    useEffect(() => {
        if (loading) {
            return
        }
        setLoading(true)
        Promise.all([
            isEmpty(employeeStakeHolderList) ? dispatch(getStakeholderList()) : dispatch(dummy()),
            dispatch(fetchInsightsData(...selectedDateRange))
        ]).finally(() => setLoading(false))
    }, [ selectedDateRange ])

    const calendarWeeks = useMemo(
        () => getCalendarWeeks(...selectedDateRange),
        [ selectedDateRange ]
    )


    if (loading || isLoading) return (<InsightsSkeleton />)

    return (<>
        <GridContainer>
            <GridItem>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InsightsIcon /> &nbsp;&nbsp;
                    <Typography variant={'subtitle1'}> Insights</Typography>
                </Box>
                <Typography variant={'subtitle2'}>
                    From: {formatDate(selectedDateRange[0])} To: {formatDate(selectedDateRange[1])}
                </Typography>
            </GridItem>
            <GridItem xs={2}>
                <IconButton
                    sx={{ position: 'relative', float: 'right' }}
                    title={'Close Insights'}
                    onClick={() => closeHandler && closeHandler() }>
                    <CloseIcon />
                </IconButton>
            </GridItem>
        </GridContainer>
        <BusinessCapacitySection
            data={data}
            expanded={openedTabs['BusinessCapacity'] === true}
            weeks={calendarWeeks}
            onChange={() => setOpenedTabs({ ...openedTabs, BusinessCapacity: !Boolean(openedTabs['BusinessCapacity']) })} />

        <InitiativeImpactSection
            data={data}
            weeks={calendarWeeks}
            expanded={openedTabs['InitiativeImpac'] === true}
            onChange={() => setOpenedTabs({ ...openedTabs, InitiativeImpac: !Boolean(openedTabs['InitiativeImpac']) })} />

        <SequencingRisksSection
            data={data}
            weeks={calendarWeeks}
            expanded={openedTabs['SequencingRisks'] === true}
            onChange={() => setOpenedTabs({ ...openedTabs, SequencingRisks: !Boolean(openedTabs['SequencingRisks']) })} />

        <RecommendationsSection
            data={data}
            weeks={calendarWeeks}
            expanded={openedTabs['RecommendationsSections'] === true}
            onChange={() => setOpenedTabs({ ...openedTabs, RecommendationsSections: !Boolean(openedTabs['RecommendationsSections']) })} />

        <AIInsightsSection
            data={aiData}
            weeks={calendarWeeks}
            expanded={openedTabs['AIInsightsSection'] === true}
            onChange={() => setOpenedTabs({ ...openedTabs, AIInsightsSection: !Boolean(openedTabs['AIInsightsSection']) })}/>
    </>)
}

export default Insights

