import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig as testMsal } from 'msa/config/test';
import { msalConfig as NWMsal } from 'msa/config/northWestern';
import { msalConfig as nzAAMsal } from 'msa/config/nzAA';

const msaAuthProviderEnabled = true //!window.location.host.includes('tool.')

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const msalLoginRequest = {
    scopes: ["User.Read"]
};

const PCA = new PublicClientApplication(testMsal);
const NZAA = new PublicClientApplication(nzAAMsal);
const NWPCA = new PublicClientApplication(NWMsal);

export {
    PCA,
    NWPCA,
    NZAA,
    msalLoginRequest,
    msaAuthProviderEnabled
}