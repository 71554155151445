import React from 'react'
import { styled } from '@mui/material/styles'
import { Tab } from '@mui/material'

const StyledTab = styled(Tab)(() => ({
    '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#F07060',
    },
    borderRadius: '0 0 0 0',
    fontSize: '0.875rem',
}))

// @ts-ignore
const MTab = ({ sx = {}, ...rest }) => (
    <StyledTab
        {...rest}
        aria-controls={rest.id}
        wrapped={true}
        sx={{
            ...sx,
            ...{
                '&:not(:last-child)': {
                    marginRight: 0,
                },
                padding: '10px 10px',
            },
        }}
    />
)

export default MTab
