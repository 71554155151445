import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths'
import SkeletonAuthenticatedApp from 'components/SkeletonLoaders/SkeletonAuthenticatedApp';

// ----------------------------------------------------------------------

type CompanyAdminGuardProps = {
    children: React.ReactNode
}

export default function CompanyAdminGuard({ children }: CompanyAdminGuardProps) {
    const { isLoading, isAuthenticated, isCompanyAdmin } = useAuth()

    if (isLoading) {
        return <SkeletonAuthenticatedApp />
    }

    if (!isAuthenticated) {
        return <Navigate to={PATH_AUTH.login} />
    }

    if (!isCompanyAdmin) {
        return <Navigate to={PATH_DASHBOARD.root} />
    }

    /*if (!isValidSubscription && !location.pathname.includes(PATH_SETTINGS.settings.subscription)) {
        return <Navigate to={PATH_SETTINGS.settings.subscription}/>;
    }*/

    return <>{children}</>
}
