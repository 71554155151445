import { Stack } from '@mui/system'
import React from 'react'
import { Skeleton } from '@mui/material';

const SkeletonInitiativeWizard = () => (
    <Stack>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'150px'} sx={{ mb: 1 }} />
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'150px'} sx={{ mb: 1 }} />
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'50vh'} sx={{ mb: 1 }} />
    </Stack>
)

export default SkeletonInitiativeWizard