import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'

const initialState = {
    isLoading: false,
    saving: null,
    error: false,
    customerTypes: [],
    customerNumbers: [],
}

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        setSaving(state, action) {
            state.saving = action.payload
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getTypeSuccess(state, action) {
            state.isLoading = false
            state.customerTypes = action.payload
        },
        getNumbersSuccess(state, action) {
            state.isLoading = false
            state.customerNumbers = action.payload
        },
    },
})

export default slice.reducer

export function getCustomerTypes() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/ImpactCustomerType/GetImpactCutomerTypeList?request=d')
            dispatch(slice.actions.getTypeSuccess(responseData(response.data)))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getCustomerNumbers() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/ImpactNumberCustomer/GetNumberCustomerByCompanyID?request=d')
            dispatch(slice.actions.getNumbersSuccess(responseData(response.data)))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

/*
 getResponseData('/api/ImpactCustomerType/GetImpactCutomerTypeList?request=d',{}).then((data:any) => {setCustomerType(data); })
        getResponseData('/api/ImpactNumberCustomer/GetNumberCustomerByCompanyID?request=d',{}).then((data:any) => {setNumberOfCustomer(data);

 */
