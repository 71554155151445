import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'

const initialState = {
    isLoading: false,
    error: false,
    DashboardDetail: {
        ActiveCompaniesCount: 0,
        CompaniesWithFreeTrial: 0,
        CompaniesWithSubscription: 0,
        InActiveCompaniesCount: 0,
        PermanentCompaniesCount: 0,
        TotalCompaniesCount: 0,
        returnUrl: null,
    },
    usersOnline: [],
}

const slice = createSlice({
    name: 'manage',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        finishLoading(state) {
            state.isLoading = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        setDashBoardDetails(state, action) {
            state.DashboardDetail = action.payload
        },

        setUsersOnline(state, action) {
            state.usersOnline = action.payload
        },
    },
})

export default slice.reducer

export function getDashBoardReport() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/Company/DashboardDetail')
            dispatch(slice.actions.setDashBoardDetails({ ...responseData(response.data) }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        } finally {
            dispatch(slice.actions.finishLoading())
        }
    }
}

export function getUsersOnlineReport() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.get('/api/Company/GetOnlineUsers')
            dispatch(slice.actions.setUsersOnline([...(response?.data?.OnlineUsers || [])]))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.finishLoading())
        }
    }
}
