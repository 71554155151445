import { Theme } from '@mui/material/styles'
import { FONT_PRIMARY } from 'theme/typography'

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
    return {
        MuiTypography: {
            defaultProps: { fontFamily: FONT_PRIMARY },

            styleOverrides: {
                paragraph: {
                    marginBottom: theme.spacing(2),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1),
                },
            },
        },
    }
}
