import React, { useMemo } from 'react'
// material
import { CssBaseline, Direction, Theme } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
// hooks
import useSettings from 'hooks/useSettings'
//
import shape from './shape'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import GlobalStyles from './globalStyles'
import componentsOverride from './overrides'
import shadows, { customShadows } from './shadows'

// ----------------------------------------------------------------------

declare module '@mui/material/styles/createTheme' {
    interface ThemeOptions {
        customShadows: Record<string, any>
    }
}

declare module '@mui/system' {
    interface Theme {
        customShadows: Record<string, any>
    }
}

type ThemeConfigProps = {
    children: React.ReactNode
}

export const getTheme = (isLight: boolean, themeDirection: Direction): Theme => {
    const themeOptions: ThemeOptions = {
        palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
        typography,
        shape,
        breakpoints,
        direction: themeDirection,
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
        components: {
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
        },
    }

    const theme = createTheme(themeOptions)
    theme.components = componentsOverride(theme)
    return theme
}

export default function ThemeConfig({ children }: ThemeConfigProps) {
    const { themeMode, themeDirection } = useSettings()
    const isLight = themeMode === 'light'

    const theme: Theme = useMemo(() => getTheme(isLight, themeDirection), [isLight, themeDirection])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
