import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getDateFormat } from 'utils/formatDate'
import { LocalizationProvider } from '@mui/x-date-pickers';
import enLocale from 'date-fns/locale/en-GB';

interface LocalizationProviderCompassProps {
    children: React.ReactNode
}

const LocalizationProviderCompass = ({ children }: LocalizationProviderCompassProps) => (
    <LocalizationProvider
        dateAdapter={AdapterDateFns}
        dateFormats={{ keyboardDate: getDateFormat() }}
        adapterLocale={enLocale}
    >
        {children}
    </LocalizationProvider>
)

export default LocalizationProviderCompass
