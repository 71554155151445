import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { PATH_AUTH } from 'routes/paths'
import { validSubscription } from 'store/slices/company'
import useClosableSnackbar from '../hooks/useClosableSnackbar'
import { useAppSelector } from 'hooks/useAppRedux';
import SkeletonAuthenticatedApp from 'components/SkeletonLoaders/SkeletonAuthenticatedApp';

// ----------------------------------------------------------------------

type AuthProtectProps = {
    children: React.ReactNode
}

export default function AuthProtect({ children }: AuthProtectProps) {
    const { isLoading, isAuthenticated } = useAuth()

    const { errorEnqueueSnackbar } = useClosableSnackbar()
    // @ts-ignore
    const isValidSubscription = useAppSelector(validSubscription)

    if (isLoading) {
        return <SkeletonAuthenticatedApp />
    }

    if (!isAuthenticated) {
        return <Navigate to={PATH_AUTH.login} />
    }

    if (!isValidSubscription) {
        errorEnqueueSnackbar('Your subscription has expired.  Please contact your Administrator.')
    }

    return <>{children}</>
}
