// @ts-nocheck
import * as d3 from 'd3'
import * as d3_7 from 'd3-7'
import './css/styles.css'


import './css/tooltip.css'
import React from 'react';
import D3Sign from 'components/D3Sign';
import { colors } from 'utils/colors';
import { filterEmpty, randomItem } from 'utils/array';
import { availableSymbols } from 'views/reports/ExecutiveSummary/d3scripts';
import { find, isString } from 'lodash';
import { octagon } from './symbols/octagon'
import Countersink from './symbols/Countersink'
import { hexagon } from './symbols/hexagon'
import { triangleDown, triangleRight, triangleLeft } from './symbols/triangle'
import pentagon from './symbols/pentagon'
import { pillow, drop, minus, clover, vbar, cloud, pin, cresent, sheild } from './symbols/symbolsRaw'
import { getResponseData } from 'utils/axios'


const colorRange = ['#59C6DF', '#4CB1D8', '#3A95CD', '#4462EC', '#7B5DE9', '#EB78AC']

export function addGradientDefs(gradientSvg) {
    const linearGradient = gradientSvg.append('defs').append('linearGradient').attr('id', 'linear-gradient')
    linearGradient.append('stop').attr('offset', '12%').attr('stop-color', colorRange[0])
    linearGradient.append('stop').attr('offset', '24%').attr('stop-color', colorRange[1])
    linearGradient.append('stop').attr('offset', '44%').attr('stop-color', colorRange[2])
    linearGradient.append('stop').attr('offset', '64%').attr('stop-color', colorRange[3])
    linearGradient.append('stop').attr('offset', '80%').attr('stop-color', colorRange[4])
    linearGradient.append('stop').attr('offset', '96%').attr('stop-color', colorRange[5])
}

//----------- PROTOTYPE FUNCTIONS  ----------------------
export const patternify = function (gradientSvg, params) {
    var container = gradientSvg
    var selector = params.selector
    var elementTag = params.tag
    var data = params.data || [selector]

    // Pattern in action
    var selection = container.selectAll('.' + selector).data(data, (d, i) => {
        if (typeof d === 'object') {
            if (d.id) {
                return d.id
            }
        }
        return i
    })
    selection.exit().remove()
    selection = selection.enter().append(elementTag).merge(selection)
    selection.attr('class', selector)
    return selection
}

export const drawGradient = (elem) => {
    const gradientWidth = 200
    const gradientHeight = 15
    // @ts-ignore
    const gradientSvg = d3
        .select(elem)
        .append('svg')
        .classed('gradient-svg', true)
        .attr('width', gradientWidth)
        .attr('height', gradientHeight)
        .attr('id', 'gradient-svg')
        .classed('hidden-print', true)
    addGradientDefs(gradientSvg)

    patternify(patternify(gradientSvg, { tag: 'g', selector: 'chart' }), { tag: 'rect', selector: 'gradient-rect' })
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', gradientWidth)
        .attr('height', gradientHeight)
        .style('fill', 'url(#linear-gradient)')
}

export const getSymbolPreview = (type: string, color: string = 'yellow', width: number = 24, height: number = 24) => {
    switch (type) {
        case 'star':
            return <D3Sign symbol={d3_7.symbolStar} color={color} width={width} height={height} />
        case 'triangle':
            return <D3Sign symbol={d3_7.symbolTriangle}  color={color} width={width} height={height} />
        case 'square':
            return <D3Sign symbol={d3_7.symbolSquare}  color={color} width={width} height={height} />
        case 'symbolCircle':
            return <D3Sign symbol={d3_7.symbolCircle}  color={color} width={width} height={height} />
        case 'symbolCross':
            return <D3Sign symbol={d3_7.symbolCross} color={color} width={width} height={height} />
        case 'symbolDiamond':
            return <D3Sign symbol={d3_7.symbolDiamond} color={color} width={width} height={height} />
        case 'symbolWye':
            return <D3Sign symbol={d3_7.symbolWye} color={color} width={width} height={height} />
        ///////////////////////////////// NEW  /////////////////////////////////
        case 'symbolAsterisk':
            return <D3Sign symbol={d3_7.symbolAsterisk} color={color} width={width} height={height} />
        case 'symbolDiamond2':
            return <D3Sign symbol={d3_7.symbolDiamond2} color={color} width={width} height={height} />
        case 'symbolPlus':
            return <D3Sign symbol={d3_7.symbolPlus} color={color} width={width} height={height} />
        case 'symbolSquare2':
            return <D3Sign symbol={d3_7.symbolSquare2} color={color} width={width} height={height} />
        case 'symbolTriangle2':
            return <D3Sign symbol={d3_7.symbolTriangle2} color={color} width={width} height={height} />
        case 'symbolTimes':
            return <D3Sign symbol={d3_7.symbolTimes} color={color} width={width} height={height} />
        case 'Countersink':
            return <D3Sign symbol={Countersink} color={color} width={width} height={height} />
        case 'hexagon':
            return <D3Sign symbol={hexagon} color={color} width={width} height={height} />
        case 'octagon':
            return <D3Sign symbol={octagon} color={color} width={width} height={height} />
        case 'triangleDown':
            return <D3Sign symbol={triangleDown} color={color} width={width} height={height} />
        case 'triangleLeft':
            return <D3Sign symbol={triangleLeft} color={color} width={width} height={height} />
        case 'triangleRight':
            return <D3Sign symbol={triangleRight} color={color} width={width} height={height} />
        case 'pentagon':
            return <D3Sign symbol={pentagon} color={color} width={width} height={height} />
        case 'pillow':
            return <D3Sign symbol={pillow} color={color} width={width} height={height} />
        case 'drop':
            return <D3Sign symbol={drop} color={color} width={width} height={height} />
        case 'minus':
            return <D3Sign symbol={minus} color={color} width={width} height={height} />
        case 'vbar':
            return <D3Sign symbol={vbar} color={color} width={width} height={height} />
        case 'clover':
            return <D3Sign symbol={clover} color={color} width={width} height={height} />
        case 'cloud':
            return <D3Sign symbol={cloud} color={color} width={width} height={height} />
        case 'pin':
            return <D3Sign symbol={pin} color={color} width={width} height={height} />
        case 'cresent':
            return <D3Sign symbol={cresent} color={color} width={width} height={height} />
        case 'sheild':
            return <D3Sign symbol={sheild} color={color} width={width} height={height} transform={'translate(1,2)'} />  // '}
        default:
            return ''
    }
}
// pillow


export const buildActivitySymbols = (activities: string[], ReportSettings: any, isSandbox: boolean = false): ActivitySymbol[] => {
    const symbolSettings = filterEmpty(ReportSettings?.symbolSettings||[]);
    const symbolsTmp = [ ...availableSymbols ]
    const colorsTmp = [ ...colors ]

    let changed = false

    let activitySymbolArr = filterEmpty((activities||[])).map((activity) => {
        const symbol = find(symbolSettings, { name: activity })
        if (symbol && availableSymbols.includes(symbol?.sign||'')) {
            return symbol;
        }
        changed = true
        if (activity.includes('Go Live')) {
            return { name: activity, sign: 'star', color: 'yellow' }
        }
        let sign = symbolsTmp.shift()
        let color = 'yellow'
        if (!sign) {
            sign = randomItem(availableSymbols)
            color = colorsTmp.shift() ?? randomItem(colors)
        }
        return { name: activity, sign: sign, color }
    }) as ActivitySymbol[]

    const notUsed = availableSymbols.filter((i) => !activitySymbolArr.find((j) => j.sign === i))


    activitySymbolArr = activitySymbolArr.map((i) => {
        if (i.name.includes('Go Live') && i.sign !== 'star') {
            changed = true
            return { ...i, sign: 'star', color: 'yellow' }
        }
        if (notUsed.length === 0) {
            return i
        }
        if (activitySymbolArr.filter((ii) => ii.sign === i.sign).length > 1) {
            changed = true
            return { ...i, sign: notUsed.pop() }
        }
        return i
    })

    if (!isSandbox) {
        // if sandbox data we do not change the settings
        if (changed || symbolSettings.length !== activitySymbolArr.length) {
            getResponseData(
                `/umm/api/account/report-settings`,
                { reportSettings: { ...ReportSettings, symbolSettings: activitySymbolArr } }
            ).catch(() => {});
        }
    }


    return activitySymbolArr.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en', { numeric: true });
    });
}

export const migrateSymbols = (data: any[] | undefined, allSymbols: ActivitySymbol[]) => {
    return (data||[]).map((i: string|object ) => {
        if (isString(i)) {
            return find(allSymbols, { name: i })
        }
        return find(allSymbols, { name: i.name })
    }).filter((i: string) => i)
}

export interface ActivitySymbol {
    name: string
    sign: string
    color: string
}

