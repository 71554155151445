import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getResponseData, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { createSelector } from 'reselect'
import FilterPreference from '../../@types/FilterPreference'
import { reportError } from 'utils/errorReport'
import { fromPairs, groupBy } from 'lodash'
import { formatISO, parseISO } from 'date-fns';
import { RootState } from 'store';

export interface FiltersStateProps {
    isLoading: boolean
    isSaving: boolean
    error: boolean
    data: Record<string, FilterPreference>
}

const initialState: FiltersStateProps = {
    isLoading: false,
    isSaving: false,
    error: false,
    data: {},
}

const slice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },
        startSaving(state) {
            state.isSaving = true
        },
        stopSaving(state) {
            state.isSaving = false
        },
        stopLoading(state) {
            state.isLoading = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        setFilterItems(state, action) {
            state.isLoading = false
            state.data = action.payload
        },
    },
})

export default slice.reducer

////const employeeLevels: any[] = useSelector((state) => employeeLevel(state))
///export const customerLevel = (state: any) => sortBy(filter(state.company.settings.CompanyLevels, {LevelType: 'CusImpt'}))

export const getFilterData = (filterName: string) =>
    createSelector(
        (state: RootState) => state.filters.data,
        (filters: Record<string, FilterPreference>): FilterPreference => filters[filterName] || { FilterId: 0, Data: {} }
    )

export function fetchFilterData() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.startLoading())
        try {
            const resp = await axiosInstance.get('/api/SaveFilter/GetReportFilter', {
                params: {
                    // @ts-ignore
                    userId: getState()?.authJwt?.user?.id || '',
                },
            })
            const data: FilterPreference[] = Object.values(
                groupBy(responseData(resp.data), 'FilterName')
            ).map((i: FilterPreference[]) => {
                const sorted = i.filter((i) => i).map(
                    (w) => {
                        let mod = new Date()
                        try {
                            mod = parseISO(w.LastModifiedAt as string)
                        } catch (e) {
                            reportError(e)
                        }
                        try {
                            return { ...w, LastModifiedAt: mod, Data: JSON.parse(""+w.Data) }
                        } catch (e) {
                            return { ...w, LastModifiedAt: mod, Data: [] }
                        }
                    }
                ).sort(
                    (a, b) => a.LastModifiedAt.valueOf() - b.LastModifiedAt.valueOf()
                )
                const last = sorted.pop()
                Promise.allSettled([
                    sorted.map((i) => getResponseData('/api/SaveFilter/DeleteReportFilter', {}, { params: { filterId: i.FilterId } }))
                ])
                return last as FilterPreference
            }).filter((i) => i)

            dispatch(
                slice.actions.setFilterItems(
                    fromPairs(data.map((i: Record<string, any>) =>  [i.FilterName, { ...i }]))
                )
            )
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopLoading())
        }
    }
}

export function saveFilterData(
    filterName: string,
    filterData: Record<string, any>,
    successCallback?: () => void,
    errorCallback? : (err:any) => void
) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.startSaving())
        try {

            // @ts-ignore
            const { FilterId, ...item } = getState().filters.data[filterName] || { FilterId: 0, Data: {} }

            const resp = await axiosInstance.post(FilterId === 0 ? '/api/SaveFilter/SaveReportFilter' : '/api/SaveFilter/UpdateReportFilter', {
                ...item,
                Entity: filterName,
                FilterName: filterName,
                ...(FilterId !== 0 ? { FilterId } : {}),
                Data: filterData,
                // @ts-ignore
                UserId: getState()?.authJwt?.user?.id || '',
                LastModifiedAt: formatISO((new Date()), { representation: 'date' }) + 'T00:00:00'
            })
            const data: FilterPreference = responseData(resp.data)
            successCallback && successCallback()
            // @ts-ignore
            dispatch(
                slice.actions.setFilterItems({
                    ...getState().filters.data,
                    [filterName]: { ...data, Data: filterData },
                })
            )
        } catch (error) {
            reportError(error)
            errorCallback && errorCallback(error)
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopSaving())
        }
    }
}
