import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function Pickers(theme: Theme) {
    return {
        MuiDatePicker: {
            defaultProps: { disableMaskedInput: true },
        },
        MuiDateRangePicker: {
            defaultProps: { disableMaskedInput: true },
        },
    }
}
