import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'store/store';
import axiosInstance, { responseData, responseTrasnform } from 'utils/axios'
import { addMinutes, endOfDay, format, getWeekOfMonth, parseISO, startOfDay } from 'date-fns';
import { createSelector } from 'reselect';
import { filterImpactBasic } from 'utils/reportFilters';
import { getXAxisDataPeriod } from 'views/reports/Heatmaps/utils';
import { parseImpactTimingString } from 'utils/date';
import { ItemEntity } from 'views/insights/sections/BusinessCapacitySubSections/types';
import {
    buildByGeography,
    buildByHierarchy,
    buildByStakeHolders,
    isGoLiveImpact,
    sortByCount
} from 'views/insights/sections/BusinessCapacitySubSections/utils';
import { getImpactGeographyTree } from 'store/slices/initiative';
import { createSlice } from '@reduxjs/toolkit';
import { getCompanyHierarchyTree, getImpactLevelsTree } from 'store/slices/company';
import { impactWorkingHours } from 'utils/iniatives';
import { ChangeResourceItem, ChangeToleranceLevel, ChangeToleranceLevelExtended } from '../../@types/initiative';
import { getStakeholders } from 'store/slices/impact/stakeholders';

export interface ExtendedChangeResourceItem extends ChangeResourceItem {
    bubble: Record<string, any>
}

export interface HighlightDates {
    division: string
    dates: Date[]
}

export interface AIInsightData {
    "Insight Title": string,
    Details: string,
    Recommendation: string,
}

export interface InsightsEntitiesStateProps {
    geographyEntities: ItemEntity[]
    hierarchyEntities: ItemEntity[]
    goLiveHierarchyEntities: ItemEntity[]
    stakeholdersEntities: ItemEntity[]
    changeToleranceLevels: ChangeToleranceLevelExtended[]
    plainHierarchy: Record<string, ItemEntity>
    highlightDates: HighlightDates[]
    changeResources: ExtendedChangeResourceItem[]
}

export interface InsightsStateProps extends InsightsEntitiesStateProps {
    isLoading: boolean
    data: Record<string, any>
    aiData: AIInsightData[]
}

const initialState: InsightsStateProps = {
    isLoading: false,
    data: {},
    geographyEntities: [],
    hierarchyEntities: [],
    stakeholdersEntities: [],
    goLiveHierarchyEntities: [],
    changeToleranceLevels: [],
    plainHierarchy: {},
    highlightDates: [],
    changeResources: [],
    aiData: [],
}


const slice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },
        stopLoading(state) {
            state.isLoading = false
        },
        setData(state, action) {
            state.data = action.payload
        },
        setAIData(state, action) {
            state.aiData = action.payload
        },
        setKnownEntities(state, action) {
            state.geographyEntities = action.payload.geographyEntities || []
            state.hierarchyEntities = action.payload.hierarchyEntities || []
            state.stakeholdersEntities = action.payload.stakeholdersEntities || []
            state.goLiveHierarchyEntities = action.payload.goLiveHierarchyEntities || []
            state.changeToleranceLevels = action.payload.changeToleranceLevels || []
            state.plainHierarchy = action.payload.changeToleranceHierarchy || []
            state.highlightDates = action.payload.highlightDates || []
            state.changeResources = action.payload.changeResources || []
        }
    },
})

export default slice.reducer

let insightsDataAxiosSource = axios.CancelToken.source()


export function fetchInsightsData(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        insightsDataAxiosSource.cancel()
        insightsDataAxiosSource = axios.CancelToken.source()
        dispatch(slice.actions.startLoading())
        try {
            const { data } = await axiosInstance.get('/api/insights/auto-generate', {
                ///cancelToken: insightsDataAxiosSource.token,
                transformResponse: responseTrasnform
            })
            console.log('AIInsightData', data)
            dispatch(slice.actions.setAIData(data as AIInsightData[]))
        } catch (error) {
            reportError(error)
        }
        try {
            const response = await axiosInstance.get('/api/Report/InsightFeatures', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                },
                cancelToken: insightsDataAxiosSource.token,
            })
            const data = responseData(response.data)

            const changeToleranceLevels: ChangeToleranceLevel[] = ((data.EmployeeChangeTolerance || []) as ChangeToleranceLevel[]).map((item) => {
                const from = parseISO(item.From.toString())
                return {
                    ...item,
                    from,
                    year: from.getFullYear(),
                    month: from.getMonth(),
                    week: getWeekOfMonth(from),
                    to: parseISO(item.To.toString()),
                }
            })

            const highlightDates =  (data.highlightDates || []).map((i: Record<string, any>) => ({
                ...i,
                dates: i.dates.map((d: any) => new Date(d)),
            }))

            const changeResources: ExtendedChangeResourceItem[] =  (data?.ChangeResources || []).map((cr: ChangeResourceItem) =>
                ({ ...cr, From: parseISO(cr.From as string), To: parseISO(cr.To as string) })
            )

            let hierarchyEntities: Record<string, ItemEntity> = {}
            let geographyEntities: Record<string, ItemEntity> = {}
            let stakeholdersEntities: Record<string, ItemEntity> = {}
            let goLiveHierarchyEntities: Record<string, ItemEntity> = {}
            const companyHierarchyTree = getCompanyHierarchyTree(getState())
            const impactGeographyTree = getImpactGeographyTree(getState())
            const impactLevels = getImpactLevelsTree(getState())

            const goLiveImpacts: Record<string, any>[] = []
            const stakeholderList = getStakeholders(getState())
            dispatch(slice.actions.setData({
                ...data,
                impacts:
                    filterImpactBasic(data.impacts).map((i: any) => {
                        const impact = {
                            ...i,
                            From: addMinutes(startOfDay(parseISO(i.From)), 10),
                            To: addMinutes(endOfDay(parseISO(i.To)), -10),
                            Periods: parseImpactTimingString(i.Periods),
                            range: getXAxisDataPeriod(i),
                            ImpactHours: impactWorkingHours(i, impactLevels),
                        }
                        if (isGoLiveImpact(impact)) {
                            goLiveImpacts.push(impact)
                        }
                        hierarchyEntities = buildByHierarchy(hierarchyEntities, impact, companyHierarchyTree)
                        geographyEntities = buildByGeography(geographyEntities, impact, impactGeographyTree)
                        stakeholdersEntities = buildByStakeHolders(stakeholdersEntities, impact, companyHierarchyTree, stakeholderList)

                        return impact
                    }),
            }))
            goLiveImpacts.forEach((impact) => {
                goLiveHierarchyEntities = buildByHierarchy(goLiveHierarchyEntities, impact, companyHierarchyTree)
            })

            const changeToleranceHierarchy: Record<string, ItemEntity> = {}
            Object.keys(hierarchyEntities).forEach((item) => {
                changeToleranceHierarchy[item] = hierarchyEntities[item]
                Object.entries(hierarchyEntities[item]?.children || []).forEach(([ id, child]) => {
                    changeToleranceHierarchy[id] = child
                    Object.entries(child?.children || []).forEach(([ id2, child2]) => {
                        changeToleranceHierarchy[id2] = child2
                    })
                })
            })

            dispatch(slice.actions.setKnownEntities({
                geographyEntities: sortByCount(geographyEntities),
                hierarchyEntities: sortByCount(hierarchyEntities),
                stakeholdersEntities: sortByCount(stakeholdersEntities),
                goLiveHierarchyEntities: Object.values(goLiveHierarchyEntities),
                changeToleranceLevels: changeToleranceLevels,
                changeToleranceHierarchy: changeToleranceHierarchy,
                highlightDates: highlightDates,
                changeResources: changeResources
            }))
            dispatch(slice.actions.stopLoading())
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                dispatch(slice.actions.stopLoading())
            }
        }
    }
}


export const isLoadingInsightsData = createSelector(
    (state: RootState) => state,
    (state): boolean => state.insights.isLoading
)

export const getInsightsData = createSelector(
    (state: RootState) => state,
    (state): Record<string, any> => state.insights.data
)

export const getAIInsightsData = createSelector(
    (state: RootState) => state,
    (state): AIInsightData[] => state.insights.aiData
)


export const getInsightsEntityData = createSelector(
    (state: RootState) => state.insights,
    (insights): InsightsEntitiesStateProps => ({
        geographyEntities: insights.geographyEntities,
        hierarchyEntities: insights.hierarchyEntities,
        stakeholdersEntities: insights.stakeholdersEntities,
        goLiveHierarchyEntities: insights.goLiveHierarchyEntities,
        changeToleranceLevels: insights.changeToleranceLevels,
        plainHierarchy: insights.plainHierarchy,
        highlightDates: insights.highlightDates,
        changeResources: insights.changeResources,
    })
)