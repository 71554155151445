import React, { useState } from 'react'
import { Avatar, Backdrop, Box, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { setAvatarFromURL } from 'store/slices/authJwt/authJwt'
import { MCircularProgress } from 'components/@material-extend'
import { reportError } from 'utils/errorReport'
import useClosableSnackbar from 'hooks/useClosableSnackbar'
import { useAppDispatch } from 'hooks/useAppRedux';

const avatars = [
    '/images/avatars/captain.png',
    '/images/avatars/copilot.png',
    '/images/avatars/hostess.png',
    '/images/avatars/pilot-4.png',
    '/images/avatars/representative.png',
    '/images/avatars/stewardess.png',
]

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px',
        justifyContent: 'center',
    },
    avatar: {
        width: '250px',
        height: '250px',
        cursor: 'pointer',
        border: '0.1px solid lightgray',
    },
})

interface AvatarDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AvatarDialog = ({ isOpen, onClose }: AvatarDialogProps) => {
    const styles = useStyles()
    const dispatch = useAppDispatch()
    const [uploadingSrc, setUploadingSrc] = useState<string | undefined>(undefined)
    const { successEnqueueSnackbar, errorEnqueueSnackbar } = useClosableSnackbar()

    // events
    const onAvatarClick = (imgSrc: string) => async () => {
        if (uploadingSrc) return
        try {
            // change state
            setUploadingSrc(imgSrc)
            // async dispatch function
            await dispatch(setAvatarFromURL(imgSrc))
            // close & gratify
            onClose()
            successEnqueueSnackbar('Avatar changed successfully')
        } catch (error: any) {
            // show error & to console
            reportError(error)
            errorEnqueueSnackbar('Problems with changing Avatar')
        } finally {
            // stop uploading
            setUploadingSrc(undefined)
        }
    }
    const onCloseRequest = () => {
        if (!uploadingSrc) onClose()
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={isOpen}
            onClose={onCloseRequest}
            scroll='body'
        >
            <DialogTitle>Click on desired avatar</DialogTitle>
            <DialogContent className={styles.container}>
                {avatars.map((imgSrc, i) => (
                    <Box
                        key={i}
                        onClick={onAvatarClick(imgSrc)}
                        position='relative'
                    >
                        <Avatar
                            src={imgSrc}
                            className={styles.avatar}
                        />
                        <Backdrop
                            open={uploadingSrc === imgSrc}
                            style={{ position: 'absolute', borderRadius: '50%' }}
                        >
                            <MCircularProgress />
                        </Backdrop>
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    )
}

export default AvatarDialog
