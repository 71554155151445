import React from 'react'
import { SnackbarProvider } from 'notistack'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
// material
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Box, Theme } from '@mui/material'
// @types
import { ColorSchema } from '../@types/theme'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme: Theme) => {
    const isLight = theme.palette.mode === 'light'

    return {
        containerRoot: {
            '& .MuiCollapse-wrapperInner': {
                width: '100%',
            },
        },

        contentRoot: {
            width: '100%',
            padding: theme.spacing(1.5),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            //backgroundColor: 'none !important'///theme.palette.grey[isLight ? 900 : 0],
        },
        message: {
            padding: 0,
            fontWeight: theme.typography.fontWeightMedium,
        },
        action: {
            marginRight: -4,
            '& svg': {
                width: 20,
                height: 20,
                opacity: 0.48,
                '&:hover': { opacity: 1 },
            },
        },
        success: {
            color: "#43a047",
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
        error: {
            color: "#d32f2f",
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
        warning: {
            color: "#ff9800",
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
        info: {
            color: "#2196f3",
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
    }
})

// ----------------------------------------------------------------------

type SnackbarIconProps = {
    icon: any
    color: ColorSchema
}

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
    const Component = icon
    return (
        <Box
            component='span'
            sx={{
                mr: 1.5,
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                justifyContent: 'center',
                color: `${color}.main`,
                bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
            }}
        >
            <Component width={24} height={24}/>
        </Box>
    )
}

type NotistackProviderProps = {
    children: React.ReactNode
}

function NotistackProvider({ children }: NotistackProviderProps) {
    const classes = useStyles()

    return (
        <SnackbarProvider
            dense
            maxSnack={5}
            preventDuplicate
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            iconVariant={{
                success: (
                    <SnackbarIcon
                        icon={CheckCircleOutlineOutlinedIcon}
                        color='success'
                    />
                ),
                error: (
                    <SnackbarIcon
                        icon={InfoOutlinedIcon}
                        color='error'
                    />
                ),
                warning: (
                    <SnackbarIcon
                        icon={WarningAmberOutlinedIcon}
                        color='warning'
                    />
                ),
                info: (
                    <SnackbarIcon
                        icon={ErrorOutlineOutlinedIcon}
                        color='info'
                    />
                ),
            }}
            classes={classes}
        >
            {children}
        </SnackbarProvider>
    )
}

export default NotistackProvider
