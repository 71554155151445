import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { isEmpty } from 'lodash'

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
    src?: string | null
}

export default function Logo({ src = '/static/logo.png', ...props }: LogoProps) {
    const { sx } = props

    // @ts-ignore
    return (<Box component='img' alt='logo' src={isEmpty(src) ? '/static/logo.png' : src} {...props} sx={{ ...(sx||{}), height: 36 }}/>
    )
}
