import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SectionComponentProps } from './type';
import SequencingRisksImpactsHoursSection from './SequencingRisksSections/SequencingRisksImpactsHoursSection';
import SequencingRisksGoLiveSection from 'views/insights/sections/SequencingRisksSections/SequencingRisksGoLiveSection';
import SequencingRisksActivitySection
    from 'views/insights/sections/SequencingRisksSections/SequencingRisksActivitySection';
import RiskOfExceedingChangeTolerance
    from 'views/insights/sections/SequencingRisksSections/RiskOfExceedingChangeTolerance';
import BlackoutRisks from 'views/insights/sections/SequencingRisksSections/BlackoutRisks';


const SequencingRisksSection = ({ expanded, onChange, data, weeks } : SectionComponentProps) => {

    const [ page, setPage ] = React.useState<Record<string, boolean>>({})

    // re-sequenced
    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Sequencing Risks</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <SequencingRisksImpactsHoursSection
                    weeks={weeks}
                    expanded={ page['impacts'] === true }
                    onChange={() => setPage({ ...page, impacts: !Boolean(page['impacts']) })}
                    data={data} />

                <SequencingRisksGoLiveSection
                    weeks={weeks}
                    expanded={ page['golive'] === true }
                    onChange={() => setPage({ ...page, golive: !Boolean(page['golive']) })}
                    data={data} />

                <SequencingRisksActivitySection
                    weeks={weeks}
                    expanded={ page['activity_hours'] === true }
                    onChange={() => setPage({ ...page, activity_hours: !Boolean(page['activity_hours']) })}
                    data={data} />

                <RiskOfExceedingChangeTolerance
                    weeks={weeks}
                    expanded={ page['RiskOfExceedingChangeTolerance'] === true }
                    onChange={() => setPage({ ...page, RiskOfExceedingChangeTolerance: !Boolean(page['RiskOfExceedingChangeTolerance']) })}
                    data={data} />

                <BlackoutRisks
                    weeks={weeks}
                    expanded={ page['BlackoutRisks'] === true }
                    onChange={() => setPage({ ...page, BlackoutRisks: !Boolean(page['BlackoutRisks']) })}
                    data={data} />
            </AccordionDetails>
        </Accordion>
    )
}

export default SequencingRisksSection