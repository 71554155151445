import React, { ElementType, Suspense } from 'react';
import SkeletonReportLoader from 'components/SkeletonLoaders/SkeletonReportLoader';
import SkeletonDashboard from 'components/SkeletonLoaders/SkeletonDashboard';
import SkeletonGenericPageLoading from 'components/SkeletonLoaders/SkeletonGenericPageLoading';
import SkeletonInitiativeWizard from 'components/SkeletonLoaders/SkeletonInitiativeWizard';
import SkeletonAuthenticatedApp from 'components/SkeletonLoaders/SkeletonAuthenticatedApp';
import useClosableSnackbar from 'hooks/useClosableSnackbar';
import { getItemSession, removeItemSession } from 'utils/storage';

const LoadableScreen = () => {
    const pathname  = window.location.pathname

    const { warningEnqueueSnackbar } = useClosableSnackbar()

    if (Boolean(getItemSession('page-reloaded-due-network-issue', 0))) {
        warningEnqueueSnackbar("We encountered problems loading some required application files, so the page has been reloaded.", 2000)
        removeItemSession('page-reloaded-due-network-issue')
    }

    if (pathname.includes('reports')) {
        return (<SkeletonReportLoader />)
    }

    if (pathname.includes('dashboard')) {
        return (<SkeletonDashboard />)
    }

    if (pathname.includes('auth') || pathname === '/' || pathname.includes('saml')) {
        return (<SkeletonAuthenticatedApp />)
    }

    if (pathname.includes('initiatives/wizard')) {
        return (<SkeletonInitiativeWizard />)
    }

    return (<SkeletonGenericPageLoading />)
}

const Loadable = (Component: ElementType) => (props: any) => (
    <Suspense fallback={<LoadableScreen />}>
        <Component {...props} />
    </Suspense>
)

export default Loadable