import React, { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SectionComponentProps } from 'views/insights/sections/type';
import { find, groupBy } from 'lodash';
import { sortImpactReportByWorkingHours } from 'utils/iniatives';
import InitiativeLink from 'components/InitiativeLink';
import DisplayPeriods from 'components/DisplayPeriods';

const InitiativeImpactSection = ({ expanded, onChange, data, weeks } : SectionComponentProps) => {

    const [ page, setPage ] = React.useState<Record<string, boolean>>({})

    const initiatives = useMemo(() => {
        const res = Object.values(
            groupBy(data?.impacts || [], (impact: Record<string, any>) => impact.InitiativeId)
        ).map( (impacts: Record<string, any>[]) => (
            {
                impacts,
                maxHours: Math.max(...impacts.map((impact: Record<string, any>) => impact.ImpactHours)),
            })
        )
        res.sort((a: Record<string, any>, b: Record<string, any>) => b.maxHours - a.maxHours)
        return res

    }, [data?.inpatcs])

    const first = initiatives[0]
    const second = first
        ? find(initiatives, (initiative: Record<string, any>) => initiative.maxHours !== first?.maxHours)
        : undefined
    
    const renderItem = (_page: string, initiative: Record<string, any>, second: boolean): React.ReactElement => {
        const topImpact = sortImpactReportByWorkingHours(initiative.impacts)[0]
        return (
            <Accordion
                expanded={ page[_page] === true }
                onChange={() => setPage({ ...page, [_page]: !Boolean(page[_page]) })}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>
                        Which initiative has the { second ? '2nd' : '' } highest impact?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <InitiativeLink id={topImpact.InitiativeId} name={topImpact.Initiatives}/>
                    has the { second ? '2nd' : '' } highest overall impact during
                    <DisplayPeriods periods={[[ topImpact.From, topImpact.To ]]} />
                </AccordionDetails>
            </Accordion>
        )
    }

    if (!first) {
        return (<></>)
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Initiative impact</Typography>
            </AccordionSummary>
            <AccordionDetails>
                { first && renderItem('highestImpact', first, false ) }
                { second && renderItem('secondHighestImpact', second, true) }
            </AccordionDetails>
        </Accordion>
    )
}

export default InitiativeImpactSection