import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Popover(theme: Theme) {
    return {
        MuiPopover: {
            styleOverrides: {
                root: {
                  zIndex: 9999999999999
                },
                paper: {
                    boxShadow: theme.customShadows.z12,
                },
            },
        },
    }
}
