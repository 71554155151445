import React from 'react'
import { Grid, GridProps } from '@mui/material';

export const GridContainer = ({ children, ...props }: GridProps) => <Grid { ...props } container spacing={1}>{ children }</Grid>

export const GridItem = ({ children, ...props }: GridProps) => <Grid xs { ...props } zeroMinWidth item>{ children }</Grid>

export const GridItemRightAlign = ({ children, ...props }: GridProps) => <Grid
    xs
    { ...props }
    sx={{ ...(props.sx || {}), justifyContent: 'end', display: 'flex', alignItems: 'center' }}
    zeroMinWidth
    item >
        { children }
</Grid>

export const GridItemContainerColumn = ({ children, ...props }: GridProps) => <GridItem
    { ...props }
    container
    direction="column"
    justifyContent="center"
    alignItems="center">
    { children }
</GridItem>

export const GridColumnContainer = ({ children, ...props }: GridProps) => <GridContainer
    { ...props }
    container
    direction="column"
    justifyContent="center"
    alignItems="center">
    { children }
</GridContainer>

export const GridItemContainer = ({ children, ...props }: GridProps) => <GridItem { ...props } container>{ children }</GridItem>