import { Stack } from '@mui/system'
import React from 'react'
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { range } from 'lodash';

const SkeletonTableLoading = () => {
    const renderCell = (index: number) => (
        <TableCell key={`skeleton-index-${index}`}>
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} sx={{ mb: 1 }}/>
        </TableCell>
    )

    return (
        <Stack>
            <TableContainer>
                <Table>
                    <TableHead>
                            <TableRow>
                                    {range(0, 5).map(renderCell)}
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            range(0, 5).map((index: number) => {
                                return (<TableRow key={`table-row${index}`}>{range(0, 5).map(renderCell)}</TableRow>)
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default SkeletonTableLoading