import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'hooks/useAppRedux';
import { getTopItem, highestPeriodFromWeek } from 'views/insights/sections/BusinessCapacitySubSections/utils';
import { SectionComponentProps } from 'views/insights/sections/type';
import { getInsightsEntityData } from 'store/slices/insights';
import DisplayPeriods from 'components/DisplayPeriods';


const BusinessCapacityStakeHolderSection = ({
                                                expanded,
                                                onChange,
                                                data,
                                                weeks
                                            }: SectionComponentProps) => {

    const stakeholders = useAppSelector(getInsightsEntityData).stakeholdersEntities

    const topStakeHolder = (stakeholders||[])[0]

    const renderTopStakeHolder = () => {
        const topDivisionName = getTopItem(topStakeHolder.children)?.name
        const minH = Math.min(...topStakeHolder.hours)
        const maxH = Math.max(...topStakeHolder.hours)
        const otherStakeHolders = (stakeholders||[]).filter(
            (stakeholder) => stakeholder.hours.some((n) => n >= 5)
        )
        const highestPeriod =  highestPeriodFromWeek(topStakeHolder?.impacts, weeks)
        return (
            <Typography variant={'body2'}>
                <b>{topStakeHolder.name}</b> {topDivisionName ? <>within <b>{topDivisionName}</b></> : ''} has the highest impacts between <DisplayPeriods periods={highestPeriod} />
                , with weekly hours { minH === maxH ? minH : `between ${minH} and ${maxH}`}.
                <br/>
                {
                    otherStakeHolders.map(
                        (stakeholder) => {
                            const maxH = Math.max(...stakeholder.hours)
                            const highestPeriod2 =  highestPeriodFromWeek(stakeholder?.impacts, weeks)
                            return (
                                <div key={'stakeholdermaxHmaxHmaxH'+stakeholder.name}>
                                    <b>{stakeholder.name}</b> within <b>{getTopItem(topStakeHolder.children)?.name}</b>
                                    have more than {maxH} hours a week between <DisplayPeriods periods={highestPeriod2} />
                                </div>
                            )
                        }
                    )
                }
            </Typography>
        )

    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    Which stakeholder within which part of the organization has the highest impact levels?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    !topStakeHolder && (
                        <Typography variant={'body2'}>
                            No data
                        </Typography>
                    )
                }
                {
                    topStakeHolder && renderTopStakeHolder()
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default BusinessCapacityStakeHolderSection