import React, { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SectionComponentProps } from 'views/insights/sections/type';
import usePrevious from 'hooks/usePrevious';
import { useAppSelector } from 'hooks/useAppRedux';
import { ItemEntity } from 'views/insights/sections/BusinessCapacitySubSections/types';
import { impactReportWorkingHours } from 'utils/iniatives';
import { ImpactTimingRange } from '../../../../@types/impact';
import { dateRangeInImpactTiming, dayRange } from 'utils/date';
import { addWeeks, endOfWeek, startOfWeek } from 'date-fns';
import InitiativeLink from 'components/InitiativeLink';
import { getInsightsEntityData } from 'store/slices/insights';
import DisplayPeriods from 'components/DisplayPeriods';
import { uniqWith } from 'lodash';


interface OvelapsWith {
    impact: Record<string, any>,
    withImpacts: Record<string, any>[]
    onDates: ImpactTimingRange[]
}

const SequencingRisksGoLiveSection: React.FC<SectionComponentProps> = ({
                                          expanded,
                                          onChange,
                                          data,
                                          weeks
                                      }) => {

    const goLiveHierarchyEntities = useAppSelector(getInsightsEntityData).goLiveHierarchyEntities
    const [ previous, setPrevious ] = usePrevious<any[]>([])

    const impacts: OvelapsWith[] = useMemo(() => {
        if (!expanded || previous.length > 0) {
            return previous
        }
        let res: Record<string, OvelapsWith> = {}
        goLiveHierarchyEntities.forEach((entity: ItemEntity) => {
            entity.impacts.forEach((impact: Record<string, any>) => {
                const overlaps: OvelapsWith = {
                    impact,
                    withImpacts: [],
                    onDates: []
                }
                entity.impacts.forEach((innerImpact: Record<string, any>) => {
                    if (innerImpact.ImpactId === impact.ImpactId) {
                        return;
                    }

                    let isOverlaps = false

                    impact.Periods.Ranges.forEach((range: ImpactTimingRange) => {
                        if (dateRangeInImpactTiming(innerImpact.Periods, [addWeeks(range.From, -1), addWeeks(range.To, 1)])) {
                            overlaps.onDates.push(range)
                            isOverlaps = true
                        }
                    })

                    impact.Periods.Dates.forEach((date: Date) => {
                        if (dateRangeInImpactTiming(innerImpact.Periods, [startOfWeek(addWeeks(date, -1)), endOfWeek(addWeeks(date, 1))]) ) {
                            overlaps.onDates.push(dayRange(date))
                            isOverlaps = true
                        }
                    });
                    if (isOverlaps) {
                        overlaps.withImpacts.push(innerImpact)
                    }
                })
                if (overlaps.onDates.length > 0) {
                    res = {
                        ...res,
                        [impact.ImpactId]: {
                            ...overlaps,
                            onDates: uniqWith(overlaps.onDates, (a: ImpactTimingRange, b: ImpactTimingRange) => a.From === b.From && a.To === b.To)
                        }
                    }
                }
            })
        })
        const result = Object.values(res)
        result.sort((a, b) => impactReportWorkingHours(b.impact) - impactReportWorkingHours(a.impact))
        setPrevious(result)
        return result
    }, [ expanded ])

    const renderContent = (): React.ReactElement => {
        if (impacts.length === 0) {
            return (<Typography variant={'body2'}>No data</Typography>)
        }

        return (
            <Typography variant={'body2'}>
                The following Go Live activities with that are quite closely together. You may want to spread out them
                {
                    impacts.slice(0, 5).map(
                        (overlap: OvelapsWith) => {

                            return (
                                <p
                                    key={`${overlap.impact.ChangeImpactName} from ${overlap.impact.Initiatives}`}>
                                    <b>{overlap.impact.ChangeImpactName}</b>
                                    from <InitiativeLink id={overlap.impact.InitiativeId} name={overlap.impact.Initiatives} />
                                    overlaps between <DisplayPeriods periods={overlap.onDates.map(({ From, To }) => [ From, To ])} />
                                    with other:
                                    <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                        {
                                            overlap.withImpacts.map(
                                                (withImpact: Record<string, any>) => (<li key={`${withImpact.ChangeImpactName}`}>
                                                   - <b>{withImpact.ChangeImpactName}</b>
                                                    &nbsp;from&nbsp;<InitiativeLink id={withImpact.InitiativeId} name={withImpact.Initiatives} />
                                                </li>)
                                            )
                                        }
                                    </ul>
                                </p>
                            )
                        }
                    )
                }
            </Typography>
        )
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    Risk of multiple Go Lives
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}

export default SequencingRisksGoLiveSection