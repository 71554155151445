import { createSlice } from '@reduxjs/toolkit'
import { unAuthUser } from './const'
import { Dispatch } from 'redux'
import axiosInstance, { blobToB64 } from 'utils/axios'
import { isEmpty } from 'lodash'
import { reportError } from 'utils/errorReport'

// ----------------------------------------------------------------------
const initialState = {
    isLoading: true,
    isAuthenticated: false,
    user: unAuthUser,
}

const slice = createSlice({
    name: 'authJwt',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },
        // START LOADING
        stopLoading(state) {
            state.isLoading = false
        },

        // INITIALISE
        getInitialize(state, action) {
            /// state.isLoading = false;
            state.isAuthenticated = action.payload.isAuthenticated
            state.user = action.payload.user
        },

        // LOGIN
        loginSuccess(state, action) {
            state.isAuthenticated = true
            state.user = action.payload.user
        },
        // REGISTER
        registerSuccess(state, action) {
            // state.isAuthenticated = true;
            state.user = action.payload.user
        },

        // LOGOUT
        logoutSuccess(state) {
            state.isAuthenticated = false
            state.user = unAuthUser
        },

        updateAvatarSrc(state, action) {
            state.user.avatarSrc = action.payload
        },
    },
})

// Reducer
export default slice.reducer

// Actions
export const { startLoading, getInitialize, registerSuccess, loginSuccess, logoutSuccess, stopLoading, updateAvatarSrc } = slice.actions

const setAvatarFromB64 = (b64: string) => async (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.updateAvatarSrc(`data:image/png;base64,${b64}`))
}

/** THROWS ERRORS */
export const setAvatarFromURL = (url: string) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const userId = getState()?.authJwt?.user?.id
    if (isEmpty(userId)) throw new Error('Redux store does not contain authJwt.user.id')

    const fileBlob = (await axiosInstance({ url, responseType: 'blob' })).data as Blob | undefined
    if (!fileBlob) throw new Error(`Could not get image from url: ${url}`)
    const fileB64 = await blobToB64(fileBlob)

    await axiosInstance.post('/umm/api/User/SaveUserAvatar?request=d', { image: fileB64 })
    dispatch(setAvatarFromB64(fileB64))
}

export const getAvatar = () => async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
        const userId = getState()?.authJwt?.user?.id
        if (isEmpty(userId)) throw new Error('Redux store does not contain authJwt.user.id')

        const imageB64 = undefined// ((await fetchResponseData(`/umm/api/User/GetUserAvatar?request=d&userId=${userId}`)) as any)?.image as string | undefined
        if (imageB64) {
            ///throw new Error('/umm/api/User/GetUserAvatar did not return image');
            dispatch(setAvatarFromB64(imageB64))
        }
    } catch (error: any) {
        reportError(error)
    }
}
