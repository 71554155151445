import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isEmpty, keyBy, pick } from 'lodash'
import { reportError } from 'utils/errorReport'
import { AIDGroup, ImpactAid } from '../../../@types/impact'
import { createSelector } from 'reselect';
import { RootState } from 'store/store';


const aidType = (aidType: number): string => {
    switch (aidType) {
        case 1:
            return 'low'
        case 2:
            return 'medium'
        case 3:
            return 'high'
        default:
            return 'unknown'
    }
}

const initialState = {
    isLoading: false,
    saving: null,
    error: false,
    aids: {},
}

const slice = createSlice({
    name: 'aids',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        setSaving(state, action) {
            state.saving = action.payload
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        consumeError(state) {
            state.error = initialState.error
        },

        // GET PRODUCTS
        getAidsSuccess(state, action) {
            state.isLoading = false
            state.aids = action.payload
        },

        getAidSuccess(state: any, action) {
            state.isLoading = false
            state.aids[aidType(action.payload.Type)] = action.payload
        },
    },
})

export default slice.reducer

export const consumeError = () => (dispatch: Dispatch<any>) => dispatch(slice.actions.consumeError())


export const hasEnabledAIDS = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): boolean => Object.values<AIDGroup>(aids).some((aid: AIDGroup) => aid.Enable)
)

export function getAids(byType?: number) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.get('/api/CompanySettings/GetAllImpactAid?request=d')
            const data: AIDGroup[] = responseData(response.data)

            const aids = keyBy(data, (group: AIDGroup) => aidType(group.Type))
            // save only one? or all?
            if (byType === undefined) dispatch(slice.actions.getAidsSuccess(aids))
            else {
                const toSave = aids[aidType(byType)]

                if (toSave === undefined) throw new Error(`Could not get ${aidType(byType)} Impact Aid`)
                else dispatch(slice.actions.getAidSuccess(toSave))
            }
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const saveAid = async (item: AIDGroup): Promise<any> => {
    const { Enable, ScaleIds, Id, Type } = item
    await axiosInstance.post('/api/CompanySettings/SaveImpactAidGroup?request=d', { Enable, ScaleIds, Id, Type })
    await Promise.all(item.ImpactAids.map((i: any) => axiosInstance.post('/api/CompanySettings/SaveImpactAid?request=d', [i])))
}


export const saveAidItem = async (item: ImpactAid): Promise<string> => {
    const savedItemId = (await axiosInstance.post('/api/CompanySettings/SaveImpactAid?request=d', [item]))?.data?.responseData[0] as string | undefined
    if (isEmpty(item.Id) && isEmpty(savedItemId)) throw new Error('api SaveImpactAid did not return id')
    return savedItemId ?? item.Id
}

export const saveAidGroup = async (group: AIDGroup) => {
    await axiosInstance.post('/api/CompanySettings/SaveImpactAidGroup?request=d', pick(group, ['Enable', 'ScaleIds', 'Id', 'Type']))
}

/**
 * @deprecated use deleteAidItem
 */
export function deleteAid(item: any) {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axiosInstance.post('/api/CompanySettings/DeleteImpactAid?request=d&Id=' + item?.Id, {})
            return dispatch(getAids())
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const deleteAidItem = async (item: ImpactAid) => {
    if (isEmpty(item.Id)) return
    await axiosInstance.post('/api/CompanySettings/DeleteImpactAid?request=d&Id=' + item.Id, {})
}
