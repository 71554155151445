import { BaseTreeModel } from '../@types/base';
import { CompanyHierarchyItem, CompanyHierarchyTree } from '../@types/settings';
import { sortByCaseSensitive } from 'utils/string';
import { GeographyItem } from '../@types/impact';
import { filter, find } from 'lodash';


interface CompanyHierarchyListResponse {
    title: string,
    value: CompanyHierarchyItem,
    Id: string
}

export const companyHierarchyList = (hierarchyTree: CompanyHierarchyTree, useTeam = false): CompanyHierarchyListResponse[] => {
    const res: Array<CompanyHierarchyListResponse> = []
    sortByCaseSensitive<CompanyHierarchyItem>(hierarchyTree?.Division, 'Name').forEach((i: CompanyHierarchyItem) => {
        res.push({ title: i.Name, value: i, Id: i.Id })
        const subDivisions = filter(hierarchyTree.SubDivision, { ParentCompanyHierarchyItemId: i.Id }) || []
        sortByCaseSensitive(subDivisions, 'Name').forEach((sub: any) => {
            res.push({ title: ' - ' + sub.Name, value: sub, Id: sub.Id })
            if (useTeam) {
                const teams = filter(hierarchyTree.Team, { ParentCompanyHierarchyItemId: sub.Id }) || []
                sortByCaseSensitive(teams, 'Name').forEach((team: any) => {
                    res.push({ title: '  -- ' + team.Name, value: team, Id: team.Id })
                })
            }
        })
    })
    return res
}

export const findHierarchyItem = (id: string, companyHierarchyItem: CompanyHierarchyTree): CompanyHierarchyItem | undefined => {
    let found = find(companyHierarchyItem.Division, { Id: id })
    if (found) {
        return found as CompanyHierarchyItem
    }
    found = find(companyHierarchyItem.SubDivision, { Id: id })
    if (found) {
        return found as CompanyHierarchyItem
    }
    return find(companyHierarchyItem.Team, { Id: id })
}


export const getChildHierarchyItems = (items: Array<BaseTreeModel | CompanyHierarchyItem>, ids: string[]): Array<BaseTreeModel | CompanyHierarchyItem> =>
    sortByCaseSensitive(
        items.filter((i: BaseTreeModel | CompanyHierarchyItem) => ids.includes((i as BaseTreeModel)?.ParentId || i?.ParentCompanyHierarchyItemId)),
        'Name'
    )

export const getGeographyHierarchyItems = (items: GeographyItem[], ids: string[]): GeographyItem[] =>
    sortByCaseSensitive(
        items.filter((i: GeographyItem) => ids.includes(i.ParentGeographyItemId)),
        'Name'
    )