import { Theme } from '@mui/material/styles'
import { Components } from '@mui/material'
import { responsiveFontSizes } from 'utils/formatFontSize';

// ----------------------------------------------------------------------

export default function Button(theme: Theme): Components {
    return {
        MuiButton: {
            defaultProps: { variant: 'contained', style: { textTransform: 'none' } },
            styleOverrides: {
                root: {
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '@media (max-width: 1400px)': {
                        //minHeight: '32px',
                       // height: '32px',
                        padding: "2px 5px",
                        //paddingTop: '1px !important'
                    },
                    ...responsiveFontSizes({ sm: 9, md: 9, lg: 12 }),
                },
                sizeLarge: {
                    height: 48,
                },
                // contained
                containedInherit: {
                    color: theme.palette.grey[800],
                    boxShadow: theme.customShadows.z8,
                    '&:hover': {
                        backgroundColor: theme.palette.grey[400],
                    },
                },
                containedPrimary: {
                    boxShadow: theme.customShadows.primary,
                },
                containedSecondary: {
                    boxShadow: theme.customShadows.secondary,
                },
                // @ts-ignore
                containedInfo: {
                    boxShadow: theme.customShadows.info,
                },
                containedSuccess: {
                    boxShadow: theme.customShadows.success,
                },
                containedWarning: {
                    boxShadow: theme.customShadows.warning,
                },
                containedError: {
                    boxShadow: theme.customShadows.error,
                },
                // outlined
                outlinedInherit: {
                    border: `1px solid ${theme.palette.grey[500_32]}`,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
                textInherit: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
            },
        },
    }
}
