import React from 'react'
import { Skeleton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import Logo from 'components/Logo';

const SkeletonAuthenticatedApp = () => {
    return (
        <Stack spacing={0} sx={{ p: '0 !important', height: '100vh' }}>
            <Skeleton animation="wave" variant="rectangular" width={'20vw'} height={'100vh'} sx={{ fontSize: '3rem' }} />
            <Skeleton animation="wave" variant="rectangular" width={'80vw'} sx={{
                fontSize: '3rem',
                position: 'absolute',
                top: 0,
                ml: '20vw',
            }} />
            <Box sx={{ position: 'absolute', top: 15 , left: 15, display: 'inline-flex' }}>
                <Logo />
                <Typography variant='h6' sx={{ ml: 2, color: 'rgb(99, 115, 129)' }}>The Change Compass</Typography>
            </Box>
            <Box sx={{
                left: '22vw',
                top: '10vh',
                width: '75vw',
                position: 'absolute',
            }}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={'85vh'}
                    sx={{
                        fontSize: '3rem',
                    }} />
            </Box>
        </Stack>
    )
}

export default SkeletonAuthenticatedApp