import { createSlice } from '@reduxjs/toolkit'
import { fetchResponseData, getResponseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'
import {
    PartnerGeographyTree,
    PartnerOrganizationTree,
    PartnerScaleProps,
    PartnerStakeholder
} from '../../../@types/impact';
import { sortByCaseSensitive } from 'utils/string';
import { createSelector } from 'reselect'
import { RootState } from '../../store'
import { isSandBox } from '../settings'
import { CompanyHierarchyTree } from '../../../@types/settings'

interface PartnerStateProps {
    isLoading: boolean
    error: boolean
    partnerStakeholderList: PartnerStakeholder[]
    partnerOrganizationTree: PartnerOrganizationTree
    partnerGeographyTree: PartnerGeographyTree
    partnerScales: PartnerScaleProps[]
}

const initialState: PartnerStateProps = {
    isLoading: false,
    error: false,
    partnerStakeholderList: [],
    partnerOrganizationTree: {
        PartnerName: [],
        PartnerTeam: [],
        PartnerType: []
    },
    partnerGeographyTree: {
        Region: [],
        NetworkOffice: [],
        DistrictOffice: []
    },
    partnerScales: []
}

const slice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        setPartnerStakeholderList(state, action) {
            state.isLoading = false
            state.partnerStakeholderList = action.payload
        },
        setPartnerOrganizationTree(state, action) {
            state.isLoading = false
            state.partnerOrganizationTree = {
                PartnerName: sortByCaseSensitive(action.payload?.PartnerName, 'Name'),
                PartnerTeam: sortByCaseSensitive(action.payload?.PartnerTeam, 'Name'),
                PartnerType: sortByCaseSensitive(action.payload?.PartnerType, 'Name')
            }
        },
        setPartnerGeographyTree(state, action) {
            state.isLoading = false
            state.partnerGeographyTree = {
                Region: sortByCaseSensitive(action.payload?.Region, 'Name'),
                NetworkOffice: sortByCaseSensitive(action.payload?.NetworkOffice, 'Name'),
                DistrictOffice: sortByCaseSensitive(action.payload?.DistrictOffice, 'Name')
            }//  action.payload
        },
        setPartnerScales(state, action) {
            state.partnerScales = action.payload
        }
    },
})

export default slice.reducer

export function getPartnerStakeholderList() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response: any = await getResponseData('/api/PartnerStakeholderType/GetPartnerStakeholderTypeList?request=d', {})
            dispatch(slice.actions.setPartnerStakeholderList(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            reportError(error)
        }
    }
}

export function getPartnerOrganization() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const data = (await fetchResponseData('/api/CompanySettings/GetOrganizationHierarchyList2?request=d')) as PartnerOrganizationTree[]
            dispatch(slice.actions.setPartnerOrganizationTree({ ...data }))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const getPartnerOrganizationTree = createSelector(
    (state: RootState) => state.impact.partner,
    (partner): PartnerOrganizationTree => partner.partnerOrganizationTree
)

export function getPartnerGeography() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const data = (await fetchResponseData('/api/CompanySettings/GetPartnerGeographyList2?request=d')) as PartnerGeographyTree[]
            dispatch(slice.actions.setPartnerGeographyTree({ ...data }))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getPartnerScales() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const data = (await getResponseData('/api/PartnerImpact/GetImpactScaleList?request=d')) as PartnerScaleProps[]
            dispatch(slice.actions.setPartnerScales([ ...data ]))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}