import React from 'react'
import { Stack } from '@mui/system';
import { GridContainer, GridItem } from 'components/GridElements';
import { Skeleton } from '@mui/material';
import { range } from 'lodash';

const SkeletonCardsLoading = () => {

    const renderCards = (index: number) => (
        <GridItem key={`card-index${index}`}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={'90%'}
                height={'178px'}
                sx={{ borderRadius: 1 }} />
        </GridItem>
    )

    return (
        <Stack>
            <GridContainer>
                {
                    range(0, 4).map(renderCards)
                }
            </GridContainer>
            <GridContainer>
                {
                    range(0, 4).map(renderCards)
                }
            </GridContainer>
        </Stack>
    )
}
export default SkeletonCardsLoading