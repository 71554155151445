import React, { useMemo } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SectionComponentProps } from 'views/insights/sections/type';
import {
    getTopItem,
    highestPeriodFromWeek
} from 'views/insights/sections/BusinessCapacitySubSections/utils';
import { useAppSelector } from 'hooks/useAppRedux';
import usePrevious from 'hooks/usePrevious';
import { sortImpactReportByWorkingHours } from 'utils/iniatives';
import { ItemEntity } from 'views/insights/sections/BusinessCapacitySubSections/types';
import InitiativeLink from 'components/InitiativeLink';
import DisplayPeriods from 'components/DisplayPeriods';
import { getInsightsEntityData } from 'store/slices/insights';


interface SequencingRisksImpactsHoursItem {
    division: ItemEntity | null,
    subDivision: ItemEntity | null,
    impacts: Record<string, any>[]
}

const EMPTY_ENTRY = {
    division: null,
    subDivision: null,
    impacts: []
}

const SequencingRisksImpactsHoursSection = ({
                                                expanded,
                                                onChange,
                                                data,
                                                weeks
                                            }: SectionComponentProps) => {


    const divisions = useAppSelector(getInsightsEntityData).hierarchyEntities

    const [ previous, setPrevious ] = usePrevious<SequencingRisksImpactsHoursItem>(EMPTY_ENTRY)


    const impacts = useMemo(() => {
        if (!expanded || (previous.impacts||[]).length > 0) {
            return previous
        }
        const division = divisions[0]

        if (!division) {
            return EMPTY_ENTRY
        }
        const subDivision = getTopItem(division.children) || null
        let impacts = []
        if (subDivision) {
            impacts = sortImpactReportByWorkingHours(subDivision.impacts)
        } else {
            impacts = sortImpactReportByWorkingHours(division.impacts)
        }
        const res = {
            division,
            subDivision,
            impacts
        }
        setPrevious(res)
        return res
    }, [ expanded ])

    const renderhierarchyTree  = () => {
        if (impacts.subDivision) {
            return (<><b>{impacts.subDivision.name}</b> within <b>{impacts.division?.name}</b></>)
        }
        return (<b>{impacts.division?.name}</b>)
    }

    const renderContent = (): React.ReactElement => {
        if ((impacts?.impacts||[]).length === 0) {
            return (<Typography variant={'body2'}>No data</Typography>)
        }
        const highestPeriod =  highestPeriodFromWeek(impacts.impacts, weeks)
        return (
            <Typography variant={'body2'}>
                For { renderhierarchyTree() } that has high impacts between <DisplayPeriods periods={highestPeriod} />,
                the highest impact activities are
                {
                    impacts.impacts.slice(0, 5).map(
                        (impact: Record<string, any>) => {
                            return (<p key={`${impact.ChangeImpactName} from ${impact.Initiatives}`}><b>{impact.ChangeImpactName}</b> from <InitiativeLink id={impact.InitiativeId} name={impact.Initiatives}/></p>)
                        }
                    )
                }
                may need to be re-sequenced.
            </Typography>
        )
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    The part of the organization with most capacity risks
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}

export default SequencingRisksImpactsHoursSection