import React, { useMemo } from 'react';
import { SectionComponentProps } from 'views/insights/sections/type';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import usePrevious from 'hooks/usePrevious';
import { groupBy } from 'lodash';
import InitiativeLink from 'components/InitiativeLink';


const RecommendationsSectionsAddAdoption: React.FC<SectionComponentProps> = ({ expanded, onChange, data, weeks }) => {

    const [ previous, setPrevious ] = usePrevious<any[]>([])

    const initiatives = useMemo(() => {
        if (!expanded || previous.length > 0) {
            return previous
        }
        const initiatives = groupBy(data?.impacts || [], 'Initiatives')

        const result = []
        for (const [key, value] of Object.entries(initiatives)) {
            if (value.length < 4) {
                continue
            }
            // adoption or embedment
            const activities = value
                .map((impact: Record<string, any>) => impact.Activities)
                .flat()
                .some((activity: string) => {
                    const p_activity = activity.replaceAll(' ', '').toLowerCase()
                    return p_activity.includes('adoption') || p_activity.includes('embedment')
                })

            if (!activities) {
                result.push(<InitiativeLink id={value[0].InitiativeId} name={key}/>)
            }
        }
        setPrevious(result)
        return result
    }, [expanded])

    const renderContent = (): React.ReactElement => {
        if (initiatives.length === 0) {
            return (<Typography variant={'body2'}>No data</Typography>)
        }
        return (
            <Typography variant={'body2'}>
                {
                    initiatives.slice(0, 5).map((initiative: any, index) => <p key={`add-addoption-initiative-${index}`}>{initiative}</p>)
                }
                does not have any impact activity entered on adoption or embedment.  Consider adding this.
            </Typography>
        )
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    Add Adoption or Embedment activities
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}

export default RecommendationsSectionsAddAdoption