import * as d3_7 from 'd3-7'

var sqrt3 = Math.sqrt(3);

export const triangleDown = {
    draw: function(context, size) {
        var y = -Math.sqrt(size / (sqrt3 * 3));
        context.moveTo(0, -y );
        context.lineTo(-sqrt3 * y, y);
        context.lineTo(sqrt3 * y, y);
        context.closePath();
    }
} as d3_7.SymbolType;

export const  triangleLeft = {
    draw: function(context, size) {
        var x = -Math.sqrt(size / (sqrt3 * 3));
        context.moveTo(x * 2, 0);
        context.lineTo(-x, -sqrt3 * x);
        context.lineTo(-x, sqrt3 * x);
        context.closePath();
    }
} as d3_7.SymbolType;

export const triangleRight = {
    draw: function(context, size) {
        var x = -Math.sqrt(size / (sqrt3 * 3));
        context.moveTo(-x * 2, 0);
        context.lineTo(x, -sqrt3 * x);
        context.lineTo(x, sqrt3 * x);
        context.closePath();
    }
} as d3_7.SymbolType;;