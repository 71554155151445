import React from 'react';
import { SectionComponentProps } from './type';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RecommendationsSectionsAddAdoption
    from 'views/insights/sections/RecommendationsSections/RecommendationsSectionsAddAdoption';


const RecommendationsSection: React.FC<SectionComponentProps> = ({ expanded, onChange, data, weeks })  => {

    const [ page, setPage ] = React.useState<Record<string, boolean>>({})

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Recommendations</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <RecommendationsSectionsAddAdoption
                    data={data}
                    weeks={weeks}
                    expanded={ page['add_adoption'] === true }
                    onChange={() => setPage({ ...page, add_adoption: !Boolean(page['add_adoption']) })} />
            </AccordionDetails>
        </Accordion>
    )
}

export default RecommendationsSection