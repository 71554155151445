import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessCapacityDivisionSection from './BusinessCapacitySubSections/BusinessCapacityDivisionSection';
import BusinessCapacityGeographySection from './BusinessCapacitySubSections/BusinessCapacityGeographySection';
import BusinessCapacityStakeHolderSection from './BusinessCapacitySubSections/BusinessCapacityStakeHolderSection';
import { SectionComponentProps } from 'views/insights/sections/type';
import { useAppSelector } from 'hooks/useAppRedux';
import { getInsightsEntityData } from 'store/slices/insights';
import { getTopItem } from 'views/insights/sections/BusinessCapacitySubSections/utils';
import BusinessCapacityChangeResourceSection
    from 'views/insights/sections/BusinessCapacitySubSections/BusinessCapacityChangeResourceSection';

const BusinessCapacitySection = ({ expanded, onChange, data, weeks } : SectionComponentProps) => {

    const [ page, setPage ] = React.useState<Record<string, boolean>>({})
    const { geographyEntities, stakeholdersEntities, hierarchyEntities } = useAppSelector(getInsightsEntityData)


    const topSubDivsion = getTopItem((hierarchyEntities[0]||{})?.children||{})
    const topTeam   = getTopItem((topSubDivsion||{})?.children||{})
    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Business Capacity</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <BusinessCapacityDivisionSection
                    data={data}
                    weeks={weeks}
                    expanded={ page['division'] === true }
                    onChange={() => setPage({ ...page, division: !Boolean(page['division']) })} />

                {
                    topSubDivsion && (
                        <BusinessCapacityDivisionSection
                            data={data}
                            weeks={weeks}
                            expanded={ page['subdivision'] === true }
                            showSubDivisions={true}
                            onChange={() => setPage({ ...page, subdivision: !Boolean(page['subdivision']) })} />
                    )
                }

                {
                    topTeam && (
                        <BusinessCapacityDivisionSection
                            data={data}
                            expanded={ page['teams'] === true }
                            showSubDivisions={true}
                            showTeams={true}
                            weeks={weeks}
                            onChange={() => setPage({ ...page, teams: !Boolean(page['teams']) })} />
                    )
                }

                {
                    geographyEntities.length > 0 && (
                        <BusinessCapacityGeographySection
                            data={data}
                            weeks={weeks}
                            expanded={ page['geography'] === true }
                            onChange={() => setPage({ ...page, geography: !Boolean(page['geography']) })} />
                    )
                }

                {
                    stakeholdersEntities.length > 0 && (
                        <BusinessCapacityStakeHolderSection
                            data={data}
                            weeks={weeks}
                            expanded={ page['stakeholder'] === true }
                            onChange={() => setPage({ ...page, stakeholder: !Boolean(page['stakeholder']) })} />
                    )
                }
                <BusinessCapacityChangeResourceSection
                    data={data}
                    weeks={weeks}
                    expanded={ page['change_resource'] === true }
                    onChange={() => setPage({ ...page, change_resource: !Boolean(page['change_resource']) })} />
            </AccordionDetails>
        </Accordion>
    )
}

export default BusinessCapacitySection