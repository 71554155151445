import axios from 'axios';
import { getItemLocal, saveItemLocal } from 'utils/storage';

/*

{
   "status":"success",
   "country":"Ukraine",
   "countryCode":"UA",
   "region":"71",
   "regionName":"Cherkasy Oblast",
   "city":"Uman",
   "zip":"20300",
   "lat":48.7477,
   "lon":30.2151,
   "timezone":"Europe/Kyiv",
   "isp":"TOV TV\u0026Radio Company 'TIM'",
   "org":"TOV TV\u0026Radio Company 'TIM'",
   "as":"AS41096 TOV TV\u0026Radio Company 'TIM'",
   "query":"176.98.92.110"
}


 */

export interface IpApiLocation {
    status: string
    country: string
    countryCode: string
    region: string
    regionName: string
    city:  string
    zip: string
    lat: number
    lon: number
    timezone: string
    isp: string
    org: string
    as: string
    query: string
}

const IpApiLocationEmpty = {
    status: 'failed',
    country: '',
    countryCode: '',
    region: '',
    regionName: '',
    city: '',
    zip: '',
    lat: 0,
    lon: 0,
    timezone: '',
    isp: '',
    org: '',
    as: '',
    query: '',
}

export const getIpApiLocation = async () : Promise<IpApiLocation> =>  {
    try {
        const data: IpApiLocation =  (await axios.get('http://ip-api.com/json'))?.data || {}
        if (data?.status !== 'success') {
            return IpApiLocationEmpty
        }
        return data
    } catch (ex: any) {
        return IpApiLocationEmpty
    }
}

/*
{
    "ip": "176.98.92.110",
    "network": "176.98.88.0/21",
    "version": "IPv4",
    "city": "Uman",
    "region": "Cherkasy Oblast",
    "region_code": "71",
    "country": "UA",
    "country_name": "Ukraine",
    "country_code": "UA",
    "country_code_iso3": "UKR",
    "country_capital": "Kyiv",
    "country_tld": ".ua",
    "continent_code": "EU",
    "in_eu": false,
    "postal": "20300",
    "latitude": 48.7477,
    "longitude": 30.2151,
    "timezone": "Europe/Kyiv",
    "utc_offset": "+0300",
    "country_calling_code": "+380",
    "currency": "UAH",
    "currency_name": "Hryvnia",
    "languages": "uk,ru-UA,rom,pl,hu",
    "country_area": 603700.0,
    "country_population": 44622516,
    "asn": "AS41096",
    "org": "TOV TV&Radio Company 'TIM'"
}
 */
export interface IpApiCoLocation {
    ip: string
    network: string
    version: string
    city: string
    region: string
    region_code: string
    country: string
    country_name: string
    country_code: string
    country_code_iso3: string
    country_capital: string
    country_tld: string
    continent_code: string
    in_eu: boolean
    postal: string
    latitude: number
    longitude: number
    timezone: string
    utc_offset: string
    country_calling_code: string
    currency: string
    currency_name: string
    languages: string
    country_area: number
    country_population: number
    asn: string
    org: string
}

const IpApiCoLocationDefault = {
    "ip": "",
    "network": "",
    "version": "",
    "city": "",
    "region": "",
    "region_code": "",
    "country": "",
    "country_name": "",
    "country_code": "",
    "country_code_iso3": "",
    "country_capital": "",
    "country_tld": "",
    "continent_code": "",
    "in_eu": false,
    "postal": "",
    "latitude": 4.7477,
    "longitude": 4.2151,
    "timezone": "",
    "utc_offset": "",
    "country_calling_code": "",
    "currency": "",
    "currency_name": "",
    "languages": "",
    "country_area": 3.0,
    "country_population": 3,
    "asn": "",
    "org": ""
}

export const getIpAPiCoLocation = async () : Promise<IpApiCoLocation> => {
    try {
        const data: IpApiCoLocation =  (await axios.get('https://ipapi.co/json'))?.data || {}
        if (!data?.country) {
            return IpApiCoLocationDefault
        }
        return data
    } catch (ex: any) {
        return IpApiCoLocationDefault
    }
}
/*

 const data = await axios.get('https://extreme-ip-lookup.com/json/')

{
   "businessName" : "",
   "businessWebsite" : "",
   "city" : "",
   "continent" : "",
   "country" : "",
   "countryCode" : "",
   "ipName" : "",
   "ipType" : "",
   "isp" : "",
   "lat" : "",
   "lon" : "",
   "message" : "No API Key found, please get your API Key at https://extreme-ip-lookup.com",
   "org" : "",
   "query" : "176.98.92.110",
   "region" : "",
   "status" : "fail",
   "timezone" : "",
   "utcOffset" : ""
}
 */

interface ExtremeIpLookupInterface {
    businessName : string
    businessWebsite : string
    city: string
    continent: string
    country: string
    countryCode: string
    ipName: string
    ipType: string
    isp: string
    lat: string
    lon: string
    message: string
    org: string
    query: string
    region: string
    timezone: string
    utcOffset: string
}


const ExtremeIpLookupEmtpy = {
    "businessName" : "",
    "businessWebsite" : "",
    "city" : "",
    "continent" : "",
    "country" : "",
    "countryCode" : "",
    "ipName" : "",
    "ipType" : "",
    "isp" : "",
    "lat" : "",
    "lon" : "",
    "message" : "No API Key found, please get your API Key at https://extreme-ip-lookup.com",
    "org" : "",
    "query" : "176.98.92.110",
    "region" : "",
    "status" : "fail",
    "timezone" : "",
    "utcOffset" : ""
}

export const getExtremeIpLocation = async () : Promise<ExtremeIpLookupInterface> => {
    try {
        return (await axios.get('https://extreme-ip-lookup.com/json/'))?.data as ExtremeIpLookupInterface
    } catch (e) {
        return ExtremeIpLookupEmtpy
    }
}


export interface IpInfoLocation {
    ip: string
    city: string
    region: string
    country: string
    loc: string
    org: string
    postal: string
    timezone: string
}

const IpInfoEmpty = {
    "ip": '',
    "city": "",
    "region": "",
    "country": "",
    "loc": "",
    "org": "",
    "postal": "",
    "timezone": ""
}

export const getIPInfoIOLocation = async () : Promise<IpInfoLocation> => {
    try {
        return (await axios.get('https://ipinfo.io/?token=1c0223af8b541e'))?.data as IpInfoLocation
    } catch (e) {
        return IpInfoEmpty
    }
}


export const getUserLocation = async () => {
    if (getItemLocal('location', {})?.country) {
        return
    }

    const data = await getIPInfoIOLocation()
    if (data?.country) {
        saveItemLocal('location', data)
        return
    }

    const data2 = await getIpApiLocation()
    if (data2?.country) {
        saveItemLocal('location', data2)
        return
    }

    const data3 = await getIpAPiCoLocation()
    if (data3?.country) {
        saveItemLocal('location', data3)
        return
    }

    const data4 = await getExtremeIpLocation()
    if (data4?.country) {
        saveItemLocal('location', data4)
        return
    }
}