// ----------------------------------------------------------------------

export function remToPx(value: string) {
    return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value: number) {
    return `${value / 16}rem`
}

// ----------------------------------------------------------------------

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:1300px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1500px)': {
            fontSize: pxToRem(lg),
        },
    }
}

export function responsiveFontSizesSx({ sm, md, lg }: { sm: number; md: number; lg: number }) {
    return {
        '@media (minWidth:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (minWidth:1300px)': {
            fontSize: pxToRem(md),
        },
        '@media (minWidth:1500px)': {
            fontSize: pxToRem(lg),
        },
    }
}