import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { addMinutes, endOfDay, format, parseISO, startOfDay } from 'date-fns'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import axios from 'axios';
import { createSelector } from 'reselect';

const initialState = {
    isLoading: false,
    error: false,
    data: {},
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        partnerImpactScales: []
    }
}

const slice = createSlice({
    name: 'speedometer',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getSpeedometerSuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },
        setSpeedometerFilterData(state, action) {
            state.filtersData = action.payload
        },
    },
})

export default slice.reducer

let bubbleAxiosSource = axios.CancelToken.source()

export function getSpeedometer(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getSpeedometerSuccess({}))
        dispatch(slice.actions.startLoading())
        bubbleAxiosSource.cancel()
        bubbleAxiosSource = axios.CancelToken.source()
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/BubbleData', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                    useDefaultDiv: false,
                },
                cancelToken: bubbleAxiosSource.token
            })
            const data = responseData(response.data) || {}

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const contact = new Set()
            const partnerImpactScales = new Set()

            const bubbles = (data.bubbles||[]).map((bubble: Record<string, any>) => {
                const { Impacts = [], ...rest } = bubble
                return {
                    ...rest,
                    Impacts: Impacts.map((i: any) => {
                        const impact = {
                            ...i,
                            ...rest,
                            From: addMinutes(startOfDay(parseISO(i.ImpactFromDate)), 10),
                            To: addMinutes(endOfDay(parseISO(i.ImpactToDate)), -10),
                            Division: i.DivisionNames,
                            Team: i.TeamNames,
                            Initiatives: bubble.InitiativeName,
                            ChangeType: bubble?.ChangeTypes || [],
                            Contact: bubble?.ContactName || '',
                            Phase: bubble?.InitiativePhaseName || '',
                            DivisionStrategy: bubble?.DivisionStrategy || [],
                        }
                        initiatives.add(impact.Initiatives)
                        impact.CompanyStrategy?.forEach(companyStrategy.add.bind(companyStrategy))
                        phase.add(impact.Phase)
                        owner.add(impact.Owner)
                        impact.ChangeType.forEach(changeType.add.bind(changeType))
                        contact.add(impact.Contact)
                        partnerImpactScales.add(impact?.PartnerImpactScaleId)
                        impact.Activities.forEach(activities.add.bind(activities))
                        impact.Stakeholders.forEach(stakeholders.add.bind(stakeholders))
                        return impact
                    })
                }
            })
            dispatch(slice.actions.setSpeedometerFilterData({
                initiatives: Array.from(initiatives).sort(),
                companyStrategy: Array.from(companyStrategy).sort(),
                phase: Array.from(phase).sort(),
                owner: Array.from(owner).sort(),
                changeType: Array.from(changeType).sort(),
                activities: Array.from(activities).sort(),
                stakeholders: Array.from(stakeholders).sort(),
                contact: Array.from(contact).sort(),
                partnerImpactScales: Array.from(partnerImpactScales).filter((i) => i),
            }))
            dispatch(slice.actions.getSpeedometerSuccess({ ...data, bubbles }))
        } catch (error) {
            if (axios.isCancel(error)) {
                return;
            }
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}


export const getSpeedometerFiltersData = createSelector(
    (state: RootState) => state.reports.speedometer,
    (speedometer): Record<string, any> => speedometer.filtersData
)