import { combineReducers } from 'redux'

import { default as ScaleReducer } from './scales'
import { default as AidReducer } from './aids'
import { default as ActivityReducer } from './activity'
import { default as CustomerReducer } from './customer'
import { default as StakeholdersReducer } from './stakeholders'
import { default as PartnersReducer } from './partner'

const impactReducer = combineReducers({
    scales: ScaleReducer,
    aids: AidReducer,
    activity: ActivityReducer,
    stakeholders: StakeholdersReducer,
    customer: CustomerReducer,
    partner: PartnersReducer,
})

export default impactReducer
