import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Radio(theme: Theme) {
    return {
        MuiRadio: {
            defaultProps: { sx: { color: '#637381', '&.Mui-checked': { color: '#F07060' } } },
            styleOverrides: {
                root: {
                    padding: theme.spacing(1),
                    svg: {
                        fontSize: 24,
                        '&[font-size=small]': {
                            fontSize: 20,
                        },
                    },
                },
            },
        },
        /*MuiRadio: {
          defaultProps: {
              sx: {
                  color: '#F07060'
              }
          }
        }*/
    }
}
