import { AES, enc }  from 'crypto-js'

export const saveItemLocal = (name: string, data: any): void => {
    localStorage.setItem(name, JSON.stringify(data))
    storageEvent(name, data)
}

export const getItemLocal = (name: string, defaultValue: any = null): any => {
    try {
        return JSON.parse(localStorage.getItem(name) as string)  || defaultValue
    } catch (_) {
        return defaultValue
    }
}

export const storageEvent = (key: string, newValue: any = null) => {
    try {
        window.dispatchEvent(
            new StorageEvent('storage', {
                key,
                newValue,
            })
        );
    } catch (e) {
        ///
    }
}

export const removeItemLocal = (name: string): void => {
    localStorage.removeItem(name)
    storageEvent(name)
}

const secret = [
    'M$vSqaN&QxX4+tc-!p3UL*zDG9xL^Rw#dyiek94993i49dkqcamcsrc',
    'VJn%?JFYj!RE$@BS9$Le**QVBgEd98393TAp78Z9v+x9Pt@a?G'
].join('-')

export const saveItemSession = (name: string, data: any, useCrypto: boolean = false): void => {
    sessionStorage.setItem(name, useCrypto? AES.encrypt(JSON.stringify(data), secret).toString() : JSON.stringify(data))
}

export const getItemSession = (name: string, defaultValue: any = null, useCrypto: boolean = false): any => {
    try {
        let data = sessionStorage.getItem(name) as string;
        if (data && useCrypto) {
            data = AES.decrypt(data, secret).toString(enc.Utf8)
        }
        return JSON.parse(data || JSON.stringify(defaultValue)) || defaultValue
    } catch (_) {
        return defaultValue
    }
}

export const removeItemSession = (name: string): void => {
    sessionStorage.removeItem(name)
}
