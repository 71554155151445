import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Activity, ScaleProps } from '../../../@types/impact'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'
import { filterEmpty } from '../../../utils/array'
import { sortBy, uniqBy } from 'lodash'

interface ScalesProps {
    isLoading: boolean
    error: boolean
    scales: ScaleProps[]
}

const initialState: ScalesProps = {
    isLoading: false,
    error: false,
    scales: [],
}

const slice = createSlice({
    name: 'scales',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getScaleSuccess(state, action) {
            state.isLoading = false
            state.scales = action.payload
        },
    },
})

export default slice.reducer


export const getScales = createAsyncThunk(
    'thunk/fetchImpactScales',
    async (_, { dispatch, getState }) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/ImpactScale/GetImpactScaleList')
            const res = responseData(response.data)
            dispatch(slice.actions.getScaleSuccess(responseData(response.data)))
            return res
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
        return []
    }
)
