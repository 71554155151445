import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { fetchResponseData, getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { format, parseISO, startOfDay, endOfDay, addMinutes } from 'date-fns'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import { GetScenarioResponse, InitiativeScenario } from '../../../@types/initiative'
import { ExecutiveSummaryReportResponse } from '../../../@types/reports'
import axios from 'axios';
import { createSelector } from 'reselect';
import { buildActivitySymbols } from '../../../views/reports/ExecutiveSummary/utils'
import { fetchCompanySettings, setReportSettings } from '../company'
import {
    Activity,
    ImpactTiming,
    ImpactTimingRangeResponse,
    ImpactTimingResponse,
} from '../../../@types/impact'

interface ExecutiveSummaryStore {
    isLoading: boolean
    error: boolean
    data: ExecutiveSummaryReportResponse
    scenarios: InitiativeScenario[]
    filtersData: {
        initiatives: any[],
        companyStrategy: any[],
        phase: any[],
        owner: any[],
        changeType: any[],
        activities: any[],
        stakeholders: any[],
        contact: any[],
        partnerStakeholders: any[],
        partnerImpactScales: any[],
        impactType: any[],
    }
}

const initialState: ExecutiveSummaryStore = {
    isLoading: false,
    error: false,
    data: {
        Activity: [],
        EditableFields: [],
        LevelTimeRange: [],
        Scenarios: [],
        bubbles: [],
        colors: [],
        defaultDivision: null,
        hierarchyItems: {
            division: [],
            subDivision: [],
            team: [],
        },
        highlightDates: [],
        isCompanyAdmin: false,
    },
    scenarios: [],
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        partnerStakeholders: [],
        partnerImpactScales: [],
        impactType: [],
    }
}

const slice = createSlice({
    name: 'executiveSummary',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getExecutiveSummarySuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },

        setScenarios(state, action) {
            state.scenarios = action.payload
        },
        setExecutiveSummaryFilterData(state, action) {
            state.filtersData = action.payload
        },
    },
})

export default slice.reducer

let scenariosAxiosSource = axios.CancelToken.source()

const fetchScenarios = async (dispatch: Dispatch<any>) => {
    scenariosAxiosSource.cancel()
    scenariosAxiosSource = axios.CancelToken.source()
    const scenarios = await fetchResponseData('/api/Initiative/GetInitiativeScenarios', {
        cancelToken: scenariosAxiosSource.token
    }).then((data) => {
        /// @ts-ignore
        const { Scenario, ScenarioInitiatives = [], ...rest } = data
        return {
            ...rest,
            Scenario: (Scenario || ScenarioInitiatives).map((i: InitiativeScenario) => ({
                ...i,
                CreatedAt: parseISO(i.CreatedAt as string),
                Expired: parseISO(i.Expired as string),
                DateStart: parseISO(i.DateStart as string),
                LastModifiedAt: parseISO(i.LastModifiedAt as string),
            })),
        } as GetScenarioResponse
    })
    ///scenarios
    dispatch(slice.actions.setScenarios([...(scenarios?.Scenario || [])]))
}

export function getExecutiveSummaryScenarios() {
    return async (dispatch: Dispatch<any>) => {
        try {
            await fetchScenarios(dispatch)
        } catch (error) {
            reportError(error)
        }
    }
}

let bubbleAxiosSource = axios.CancelToken.source()

export function getExecutiveSummaryReport(startDate: Date, endDate: Date) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getExecutiveSummarySuccess({}))
        dispatch(slice.actions.startLoading())
        bubbleAxiosSource.cancel()
        bubbleAxiosSource = axios.CancelToken.source()
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/BubbleData', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                    useDefaultDiv: false,
                },
                cancelToken: bubbleAxiosSource.token
            })

            try {
                await fetchScenarios(dispatch)
            } catch (error) {
                reportError(error)
            }

            const data = responseData(response.data)

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const contact = new Set()
            const partnerStakeholders = new Set()
            const impactType = new Set()
            const partnerImpactScales = new Set()


            const bubbles = (data?.bubbles || []).map((bubble: Record<string, any>) => {
                const { Impacts, ...rest } = bubble


                return {
                    ...rest,
                    Impacts: Impacts.map((i: any) => {
                        let Timing = { Dates: [], Ranges: [] } as ImpactTiming
                        try {
                            const res = JSON.parse(i?.Period || '{ "Dates": [], "Ranges": []}') as ImpactTimingResponse
                            Timing = {
                                Dates: res.Dates.map((x) => parseISO(x)),
                                Ranges: res.Ranges.map((x: ImpactTimingRangeResponse) => ({ From: parseISO(x.From), To: parseISO(x.To) })),
                            } as ImpactTiming
                        } catch (e) {
                            console.log(e)
                        }

                        /*let TimingRange = [
                            ...Timing?.Dates?.map(x => ({ start: startOfDay(x) ,end: endOfDay(x) })),
                            ...Timing?.Ranges?.map((x: ImpactTimingRange) => ({ start: startOfDay(x.From) ,end: endOfDay(x.To)}))
                        ]
                        let Dates =  impactTimingDates(Timing)


                        if (isEmpty(Dates)) {
                            Dates = eachDayOfInterval({ start: parseISO(i.ImpactFromDate), end: parseISO(i.ImpactToDate) })

                        }
                         */
                        const ActivityFromDate  = parseISO(i.ImpactFromDate)

                        ///let TimingRange = [{ start: startOfDay(from), end: endOfDay(from)}]

                        const impact = {
                            ...i,
                            ...rest,
                            From: addMinutes(startOfDay(parseISO(i.ImpactFromDate)), 10),
                            To: addMinutes(endOfDay(parseISO(i.ImpactToDate)), -10),
                            Division: i.DivisionNames,
                            Team: i.TeamNames,
                            Initiatives: bubble.InitiativeName,
                            ChangeType: bubble?.ChangeTypes || [],
                            Contact: bubble?.ContactName || '',
                            Phase: bubble?.InitiativePhaseName || '',
                            DivisionStrategy: bubble?.DivisionStrategy || [],
                            Timing,
                            ActivityFromDate
                            //Dates,
                           // TimingRange
                        }
                        initiatives.add(impact.Initiatives)
                        impact.Strategy?.forEach(companyStrategy.add.bind(companyStrategy))
                        phase.add(impact.Phase)
                        owner.add(impact.Owner)
                        impact.PartnerStakeholder?.forEach(partnerStakeholders.add.bind(partnerStakeholders))
                        partnerImpactScales.add(impact?.PartnerImpactScaleId)

                        impactType.add(impact.ImpactType)
                        impact.ChangeType.forEach(changeType.add.bind(changeType))
                        contact.add(impact.Contact)
                        impact.Activities.forEach(activities.add.bind(activities))
                        impact.Stakeholders.forEach(stakeholders.add.bind(stakeholders))
                        return impact
                    }),
                }

            })

            const { ReportSettings={} } = getState().company.settings || {}
            const isSandbox = await isSandBox(getState())

            const impactActivities = (getState().impact?.activity?.activities || []).map((activity: Activity) => activity.Name)
            const symbols = buildActivitySymbols(Array.from(impactActivities) as string[], ReportSettings, isSandbox).filter((i) => activities.has(i.name))
            /// update settings
            dispatch(fetchCompanySettings())

            dispatch(slice.actions.setExecutiveSummaryFilterData({
                initiatives: Array.from(initiatives).sort(),
                companyStrategy: Array.from(companyStrategy).sort(),
                phase: Array.from(phase).sort(),
                owner: Array.from(owner).sort(),
                changeType: Array.from(changeType).sort(),
                activities: Array.from(activities).sort(),
                stakeholders: Array.from(stakeholders).sort(),
                contact: Array.from(contact).sort(),
                impactType: Array.from(impactType).sort(),
                partnerStakeholders: Array.from(partnerStakeholders).sort(),
                partnerImpactScales: Array.from(partnerImpactScales).filter((i) => i),
            }));

            dispatch(setReportSettings({ ...ReportSettings, symbolSettings: symbols }))
            dispatch(slice.actions.getExecutiveSummarySuccess({ ...data, bubbles, symbols }))
        } catch (error) {
            if (axios.isCancel(error)) {
                return;
            }
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}


export const getExecutiveSummaryFiltersData = createSelector(
    (state: RootState) => state.reports.executiveSummary,
    (executiveSummary): Record<string, any> => executiveSummary.filtersData
)