import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const MenuFill = (props: SvgIconProps) => <SvgIcon
    width="1em"
    height="1em"
    {...props}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24">
    <circle cx="4" cy="12" r="1" fill="currentColor"/>
    <rect width="14" height="2" x="7" y="11" fill="currentColor" rx=".94" ry=".94"/>
    <rect width="18" height="2" x="3" y="16" fill="currentColor" rx=".94" ry=".94"/>
    <rect width="18" height="2" x="3" y="6" fill="currentColor" rx=".94" ry=".94"/>
</SvgIcon>

