import React from 'react'
import { PATH_INITIATIVES } from './paths'
// layouts
import AuthGuard from 'guards/AuthGuard'
import { RouteObject } from 'react-router-dom';
import DashboardLayout from 'layouts/dashboard';
import { lazyLoad } from '../utils/routes'


const InitiativePage = lazyLoad(() => import('views/initiatives/InitiativePage'), 'InitiativePage')
const InitiativeHistoryPage = lazyLoad(() => import('views/initiatives/InitiativeHistory'), 'InitiativeHistoryPage')
const InitiativeWizardPage = lazyLoad(() => import('views/initiatives/InitiativesWizard'), 'InitiativeWizardPage')
const RedirectInitiativePage = lazyLoad(() => import('views/RedirectInitiative'), 'RedirectInitiativePage')
const RoadMapPage = lazyLoad(() => import('views/initiatives/RoadMap/RoadMapPage'), 'RoadMapPage')

const InitiativesRoutes = [
    {
        path: PATH_INITIATIVES.actions.newItem,
        element:
            <AuthGuard>
                <DashboardLayout defaultTitle={'Initiatives / Initiative'}>
                    <InitiativeWizardPage />
                </DashboardLayout>
            </AuthGuard>,
    },
    {
        path: PATH_INITIATIVES.actions.addEdit,
        element:
            <AuthGuard>
                <DashboardLayout defaultTitle={'Initiatives / Initiative'}>
                    <InitiativeWizardPage />
                </DashboardLayout>
            </AuthGuard>,
    },
    {
        path: PATH_INITIATIVES.actions.roadmap,
        element:
            <AuthGuard>
                <DashboardLayout title={'Initiatives / RoadMap'}>
                    <RoadMapPage />
                </DashboardLayout>
            </AuthGuard>,
    },
    {
        path: '/initiative/report/:initiativeId',
        element:
            <AuthGuard>
                <RedirectInitiativePage />
            </AuthGuard>,
    },
    {
        path: PATH_INITIATIVES.actions.addEditWithStep,
        element:
            <AuthGuard>
                <DashboardLayout defaultTitle={'Initiatives / Initiative'}>
                    <InitiativeWizardPage />
                </DashboardLayout>
            </AuthGuard>,
    },
    {
        path: PATH_INITIATIVES.actions.addEditImpactWithStep,
        element:
            <AuthGuard>
                <DashboardLayout title={'Initiatives / Initiative'}>
                    <InitiativeWizardPage />
                </DashboardLayout>
            </AuthGuard>,
    },
    {
        path: PATH_INITIATIVES.actions.history,
        element:
            <AuthGuard>
                <DashboardLayout title={'Initiatives / History'}>
                    <InitiativeHistoryPage />
                </DashboardLayout>
            </AuthGuard>,
    },
    {
        path: PATH_INITIATIVES.root,
        element: <AuthGuard>
                <DashboardLayout title={'Initiatives'}>
                    <InitiativePage />
                </DashboardLayout>
            </AuthGuard>,
    },
] as RouteObject[]

export default InitiativesRoutes