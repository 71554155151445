import React from 'react'
import { PATH_REPORTS } from 'routes/paths'
import { getIcon } from 'utils/images'

export interface ReportConfig {
    title: string
    path: string
    name: string
    image: string
    icon: React.ReactElement
}

export const AVAILABLE_REPORTS = [
    {
        title: 'Heatmaps',
        path: PATH_REPORTS.reports.heatmaps,
        name: 'heatmaps',
        image: '/static/charts/heatmap.svg',
        icon: getIcon('/static/charts/heatmap.svg'),
    },
    {
        title: 'Total Impact',
        path: PATH_REPORTS.reports.totalImpact,
        name: 'TotalImpact',
        image: '/static/charts/total_impact.svg',
        icon: getIcon('/static/charts/total_impact.svg'),
    },
    {
        title: 'Stakeholder Chart',
        path: PATH_REPORTS.reports.stakeholderChart,
        name: 'StakeholderReport',
        image: '/static/charts/stakeholder.svg',
        icon: getIcon('/static/charts/stakeholder.svg'),
    },
    {
        title: 'Executive Summary',
        path: PATH_REPORTS.reports.executiveSummary,
        name: 'ExecutiveSummary',
        image: '/static/charts/executive_summary.svg',
        icon: getIcon('/static/charts/executive_summary.svg'),
    },
    {
        title: 'TimeLine Chart',
        path: PATH_REPORTS.reports.timelineChart,
        name: 'TimeLineReport',
        image: '/static/charts/timeline.svg',
        icon: getIcon('/static/charts/timeline.svg'),
    },
    {
        title: 'Bubble Chart',
        path: PATH_REPORTS.reports.bubblechart,
        name: 'BubblesReport',
        image: '/static/charts/bubble.svg',
        icon: getIcon('/static/charts/bubble.svg'),
    },
    {
        title: 'Strategy Impact',
        path: PATH_REPORTS.reports.strategyimpact,
        name: 'StrategyImpactReport',
        image: '/static/charts/strategy_impact.svg',
        icon: getIcon('/static/charts/strategy_impact.svg'),
    },
    {
        title: 'Speedometer',
        path: PATH_REPORTS.reports.speedometer,
        name: 'SpeedometerReport',
        image: '/static/charts/speedometer.svg',
        icon: getIcon('/static/charts/speedometer.svg'),
    },
    {
        title: 'Resourcing Chart',
        path: PATH_REPORTS.reports.resourcingChart,
        name: 'ResourcingReport',
        image: '/static/charts/resourcing.svg',
        icon: getIcon('/static/charts/resourcing.svg'),
    },
    {
        title: 'Impact-Readiness',
        path: PATH_REPORTS.reports.readinessChart,
        name: 'ReadinessReport',
        image: '/static/charts/impact_readness.svg',
        icon: getIcon('/static/charts/impact_readness.svg'),
    },
    /*{
        title: 'Radar Chart',
        path: PATH_REPORTS.reports.radarChart,
        name: 'RadarReport',
        image: '/static/charts/radar.svg',
        icon: getIcon('/static/charts/radar.svg'),
    },*/
]
