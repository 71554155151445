import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ColumnHeader } from './EnhancedTableHead'
import { IconButton, Input, Switch, TableCell, TableRow, TextField, Tooltip } from '@mui/material'

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { styled } from '@mui/material/styles'
import {isEmpty, trim} from "lodash";

import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Box } from '@mui/system'
import { useSortable } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';


interface MEditableRowProps {
    isEditable: boolean
    row: Record<string, any>
    columns: ColumnHeader[]
    onEdit: (item: Record<string, any>) => void
    onSave: (item: Record<string, any>) => void
    onDelete: (item: Record<string, any>) => void
    canDelete: boolean
    selectable: boolean
    editable: boolean
    selected: boolean
    onSelect: (item: Record<string, any>) => void
    draggable: boolean
    index: number,
    uniqueKey: any
}

const StyledIconButton = styled(IconButton)(() => ({
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
}))


const MEditableRow = ({
    isEditable,
    row,
    uniqueKey,
    columns,
    onEdit,
    onSave,
    index,
    onDelete,
    canDelete = false,
    selectable = true,
    onSelect,
    draggable,
    selected = false,
    editable = true,
}: MEditableRowProps) => {
    const [state, setState] = useState<Record<string, any>>({})

    const elementRef = useRef<HTMLTableRowElement>(null)

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({id: index + 1});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    //if (!isNewInitiative(row) && onDropped && moveCard) {

    const isSaveDisabled = useMemo(
        () => columns
            .some((column) => {
                if (column?.required) {
                    if (!Boolean(trim(state[column.field]))) {
                        return true
                    }
                }
                if (column.allowEmpty === false) {
                    if (isEmpty(state[column.field])) {
                        return true
                    }
                }
                if (column?.hasValidatorErrors) {
                    return column.hasValidatorErrors(state[column.field], row, state)
                }
            }),
        [ state, columns ]
    )

    useEffect(() => {
        if (row.Id === -1) {
            setState(row)
        }
    }, [row])

    useEffect(() => {
        if (!isEditable) {
            setState({})
        }
    }, [isEditable]);


    useEffect(() => {
        if (isEditable && row?.Id && elementRef.current) {
            elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [isEditable, row?.Id, elementRef.current ]);

    const dragIconAction = () => (
        <Box { ...listeners } sx={{ '&:hover': { cursor: 'ns-resize !important' }, ...(isDragging ? { visibility: 'hidden' } : {}) }}>
            <Tooltip title='Drag & Drop to sort'>
                <DragHandleIcon fontSize={'small'} sx={{ '&:hover': { cursor: 'grab !important' } }} />
            </Tooltip>
        </Box>
    )

    const renderEdit = () => {
        if (!editable) {
            if (canDelete) {
                return (
                    <TableCell
                        align='right'
                        size='small'
                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                        <StyledIconButton
                            aria-label='delete'
                            onClick={() => {
                                onDelete(row)
                            }}
                        >
                            <DeleteRoundedIcon fontSize={'small'}/>
                        </StyledIconButton>
                        { draggable && dragIconAction() }
                    </TableCell>
                )
            }
            return draggable ? dragIconAction() : ''
        }
        return (
            <TableCell
                align='right'
                size='small'
                style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
                {canDelete && (
                    <>
                        <StyledIconButton
                            aria-label='delete'
                            onClick={() => {
                                onDelete(row)
                            }}
                        >
                            <DeleteRoundedIcon fontSize={'small'}/>
                        </StyledIconButton>
                        &nbsp;&nbsp;
                    </>
                )}
                <StyledIconButton
                    aria-label='edit'
                    onClick={() => {
                        setState(row)
                        onEdit(row)
                    }}
                >
                    <EditRoundedIcon fontSize={'small'}/>
                </StyledIconButton>
                &nbsp;&nbsp;
                { draggable && dragIconAction() }
            </TableCell>
        )
    }

    const renderCellValue = (column: ColumnHeader) => {
        //const currentValue = state[column.field]||row[column.field]

        let errors = {}
        if (column?.required) {
            if (!Boolean(trim(state[column.field])) && state[column.field] !== undefined) {
                errors = { error: !Boolean(trim(state[column.field])), helperText: !Boolean(trim(state[column.field])) && 'Field is Required' }
            } else if (column?.hasValidatorErrors) {
                const res = column.hasValidatorErrors(state[column.field] ?? row[column.field], row, state)
                if (res) {
                    errors = { error: res, helperText: res }
                }
            }
        }

        if (isEditable && column.editable) {
            if (column?.editFormatter) {
                return column.editFormatter(
                    state[column.field] ?? row[column.field],
                    (data) => {
                        setState({ ...state, ...data })
                    },
                    row,
                    state,
                    errors
                )
            }
            if (column?.boolean) {
                return (
                    <Switch
                        size='small'
                        checked={state[column.field]}
                        onChange={() => {
                            setState({ ...state, [column.field]: !state[column.field] })
                        }}
                    />
                )
            }

            const inputProps: Record<string, any> = {
                name: column.field,
                value: state[column.field] ?? row[column.field],
                placeholder: column.placeholder,
                onChange: (event: any) => {
                    setState({ ...state, [event.target.name]: event.target.value })
                },
                ...errors
            }
            if (column?.editTextArea) {
                return (
                    <TextField
                        fullWidth
                        variant='standard'
                        multiline
                        minRows={3}
                        {...inputProps}
                    />
                )
                /*return <TextareaAutosize
                                    style={{width: '100%'}}
                                    minRows={3}
                                    {...inputProps} />*/
            }

            if (column?.numeric) {
                inputProps.type = 'number'
            }
            return <Input {...inputProps} />
        }
        const res = state[column.field] ?? row[column.field]
        if (column?.formatter) {
            return column.formatter(res, row)
        }
        return res
    }


    const renderSave = () => (
        <TableCell align='right' size='small'>
            <StyledIconButton
                className={'table-row-save-icon-button'}
                disabled={isSaveDisabled}
                onClick={() => {
                    onSave(state)
                    onEdit({})
                }}
            >
                <SaveRoundedIcon fontSize={'small'}/>
            </StyledIconButton>
            &nbsp;&nbsp;
            <StyledIconButton
                aria-label='revert'
                onClick={() => {
                    setState({})
                    onEdit({})
                }}
            >
                <CloseRoundedIcon fontSize={'small'}/>
            </StyledIconButton>
        </TableCell>
    )


    return (
            <TableRow
               // data-handler-id={handlerId}
                ref={(elem) => {
                    // @ts-ignore
                    elementRef.current = elem
                    setNodeRef(elem)
                }}
                key={uniqueKey}
                onClick={() => {
                    if (selectable) {
                        onSelect(row)
                    }
                }}
                selected={selected}
                sx={{ height: 33, cursor: selectable ? 'pointer' : 'default' , ...style }}
            >
                {columns.map((column, columnIndex) => (
                    <TableCell key={`cell-${row.Id}-${columnIndex}`}>{renderCellValue(column)}</TableCell>
                ))}
                {!isEditable ? renderEdit() : renderSave()}
            </TableRow>

    )
}

export default MEditableRow
