import { ComponentType, lazy, LazyExoticComponent } from 'react';
import Loadable from 'components/Loadable';
import { getItemSession, removeItemSession, saveItemSession } from 'utils/storage';


export const lazyRetry = <T extends ComponentType<any>>(
    componentImport: () => Promise<{ default: T }>,
    name: string = "chunk",
): LazyExoticComponent<ComponentType<any>> =>
    lazy(async (): Promise<{ default: T }> => {
        // check if window has already refreshed
        const hasWindowRefreshed = Boolean(getItemSession(`retry-${name}-refreshed`, 0));
        try {
            const component = await componentImport();
            // successfully restored the lazy module
            removeItemSession(`retry-${name}-refreshed`);
            return component;
        } catch (error) {
            // if window has not refreshed so far
            if (!hasWindowRefreshed) {
                saveItemSession(`retry-${name}-refreshed`, 1);
                saveItemSession('page-reloaded-due-network-issue', 1);
                // @ts-ignore
                return window.location.reload(); // refresh window
            }
            throw error; // default error behaviour to execute since refresh no more required
        }
    });

export const lazyLoad = <T extends ComponentType<any>>(importName: () => Promise<{ default: T }>, name: string) =>
    Loadable(lazyRetry(importName, name))