import useAuth from 'hooks/useAuth'
import React, { useRef, useState } from 'react'
import { formatDistanceToNow } from 'date-fns'

import NotificationsIcon from '@mui/icons-material/Notifications';

import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
// material
import {
    Avatar,
    Badge,
    Box,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Tooltip,
    Typography,
} from '@mui/material'
// components
import Scrollbar from 'components/Scrollbar'
import MenuPopover from 'components/MenuPopover'
import { MIconButton } from 'components/@material-extend'

// ----------------------------------------------------------------------
import { dateWithoutOffset } from '../../utils/formatDate'
import { useQueryGetMutableArray } from 'hooks/useQuery'
import { partition } from 'lodash'
import axiosInstance from 'utils/axios'
import { reportError } from 'utils/errorReport'
import { useAppSelector } from 'hooks/useAppRedux';

interface Notification {
    Notification: {
        Title: string
        Content: string
        IsNotificationAllCompany: boolean
        CreatedAt: string
        LastModifiedAt: string
        Id: string
        ImageHeaderUrl: string
    }
    NotificationIdId: string
    IsMarkAsRead: boolean
}

interface NotificationTransformed extends Omit<Notification['Notification'], 'CreatedAt'> {
    IsMarkAsRead: boolean
    CreatedAt: Date
}

const useQueryNotification = () => {
    const isLoading = useAppSelector((state) => state.authJwt.isLoading)
    const isAuthenticated = useAppSelector((state) => state.authJwt.isAuthenticated)
    const { isViewer, isSuperAdmin } = useAuth()

    const notifications = useQueryGetMutableArray(
        'Notifications',
        '/api/Notification/GetNotificationByUser?request=d&limit=20',
        (result: Notification[]) =>
            (result || [])
                .map((n) => ({
                    ...n.Notification,
                    IsMarkAsRead: n.IsMarkAsRead,
                    CreatedAt: dateWithoutOffset(new Date(n.Notification.CreatedAt)) || new Date(),
                }))
                .sort((a, b) => b.CreatedAt.getTime() - a.CreatedAt.getTime()),
        {
            trigger: !isLoading && isAuthenticated && !isViewer && !isSuperAdmin,
            onDelete: async (n) => {
                try {
                    await axiosInstance.delete(`/api/Notification/DeleteNotificationByUser?id=${n.Id}`)
                    return true
                } catch (error: any) {
                    reportError(error)
                    return error?.message || (error?.data?.message || false)
                }
            },
            onUpdate: async (pair) => {
                if (!pair[0].IsMarkAsRead && pair[1].IsMarkAsRead) {
                    try {
                        await axiosInstance.post('/api/Notification/MarkAsReadByUser', {
                            NotificationId: pair[0].Id,
                            IsMarkAsRead: true,
                        })
                        return true
                    } catch (error: any) {
                        reportError(error)
                        return false
                    }
                }
                return true
            },
            onUpdateAll: async (pairs) => {
                if (pairs.length > 0 && pairs[0][1].IsMarkAsRead) {
                    try {
                        await axiosInstance.post('/api/Notification/MarkAllAsRead')
                        return true
                    } catch (error: any) {
                        reportError(error)
                        return false
                    }
                }
                return true
            },
        }
    )

    //const lastWeek = subWeeks(new Date(), 1);
    const [fresh, old] = partition(notifications.result, (n) => !n.IsMarkAsRead)

    return {
        ...notifications,
        fresh,
        old,
        isMarkingAllAsRead: !notifications.isLoading && notifications.updating.length >= notifications.result.length,
    }
}

// ----------------------------------------------------------------------
interface ItemProps {
    notification: NotificationTransformed
    isMarkingAsRead?: boolean
    onRead?: () => void
    isDeleting: boolean
    onDelete: () => void
}

function NotificationItem({ notification, isMarkingAsRead, onRead, isDeleting, onDelete }: ItemProps) {
    return (
        <ListItem
            disableGutters
            sx={{
                py: 1,
                px: 2.5,
                backgroundColor: notification.IsMarkAsRead ? undefined : 'rgba(145, 158, 171, 0.16)',
                '&:not(:last-of-type)': { mb: '1px' },
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral' }}>
                    <img
                        alt={notification.Title}
                        src={`/${notification.ImageHeaderUrl}`}
                    />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                sx={{}}
                primary={<div dangerouslySetInnerHTML={{ __html: `<b>${notification.Title}</b> ${notification.Content}` }} />}
                secondary={
                    notification.CreatedAt.getTime() > 0 && (
                        <Typography
                            variant='caption'
                            sx={{
                                mt: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                color: 'text.disabled',
                            }}
                        >
                            <Box
                                component={QueryBuilderIcon}
                                sx={{ mr: 0.5, width: 16, height: 16 }}
                            />
                            {formatDistanceToNow(notification.CreatedAt)}
                        </Typography>
                    )
                }
            />
            <Box
                display='flex'
                alignItems='center'
            >
                {!notification.IsMarkAsRead && (
                    <>
                        {!isMarkingAsRead && (
                            <Tooltip
                                title='Mark as read'
                                PopperProps={{ style: { zIndex: 40000 } }}
                            >
                                <IconButton
                                    onClick={onRead}
                                    size='small'
                                >
                                    <RemoveRedEyeRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isMarkingAsRead && <CircularProgress style={{ width: '16px', height: '16px', margin: '6px' }} />}
                    </>
                )}
                {!isDeleting && (
                    <Tooltip
                        title='Delete'
                        PopperProps={{ style: { zIndex: 40000 } }}
                    >
                        <IconButton
                            onClick={onDelete}
                            size='small'
                        >
                            <DeleteRoundedIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {isDeleting && <CircularProgress style={{ width: '16px', height: '16px', margin: '6px' }} />}
            </Box>
        </ListItem>
    )
}

export default function NotificationsPopover() {
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const query = useQueryNotification()

    return (
        <>
            <MIconButton
                ref={anchorRef}
                onClick={() => {
                    if (query.isLoading || query.result.length > 0) setOpen(true)
                }}
                color={open ? 'primary' : 'default'}
                disabled={!query.isLoading && query.old.length === 0 && query.fresh.length === 0}
            >
                <Badge
                    badgeContent={query.fresh.length ?? 0}
                    color='primary'
                >
                    <NotificationsIcon width={24} height={24} />
                </Badge>
            </MIconButton>

            <MenuPopover
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={anchorRef.current}
                sx={{ width: 450 }}
            >
                {query.isLoading && (
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='400px'
                    >
                        <CircularProgress />
                    </Box>
                )}
                {!query.isLoading && (
                    <Scrollbar sx={{ maxHeight: '60vh' }}>
                        {query.fresh.length > 0 && (
                            <>
                                <Box
                                    p='16px 20px'
                                    display='flex'
                                    justifyContent='space-between'
                                >
                                    <Typography color='rgb(99, 115, 129)'>
                                        You have {query.fresh.length} unread notification{query.fresh.length > 1 && 's'}
                                    </Typography>
                                    {!query.isMarkingAllAsRead && (
                                        <Tooltip
                                            title='Mark all as read'
                                            PopperProps={{ style: { zIndex: 40000 } }}
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    query.updateAll(
                                                        query.result.map((n) => [
                                                            n,
                                                            {
                                                                ...n,
                                                                IsMarkAsRead: true,
                                                            },
                                                        ])
                                                    )
                                                }
                                                size='small'
                                            >
                                                <RemoveRedEyeRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {query.isMarkingAllAsRead && <CircularProgress style={{ width: '16px', height: '16px', margin: '6px' }} />}
                                </Box>
                                <List
                                    disablePadding
                                    subheader={
                                        <ListSubheader
                                            disableSticky
                                            sx={{ py: '1px', px: 2.5, typography: 'overline', textTransform: 'uppercase' }}
                                        >
                                            Fresh
                                        </ListSubheader>
                                    }
                                >
                                    {query.fresh.map((n, i) => (
                                        <NotificationItem
                                            key={i + n.Id}
                                            notification={n}
                                            isMarkingAsRead={query.updating.some((pair) => pair[0] === n)}
                                            onRead={() => query.update([n, { ...n, IsMarkAsRead: true }])}
                                            isDeleting={query.removing.includes(n)}
                                            onDelete={() => query.delete(n)}
                                        />
                                    ))}
                                </List>
                            </>
                        )}

                        {query.old.length > 0 && (
                            <List
                                disablePadding
                                subheader={
                                    <ListSubheader
                                        disableSticky
                                        sx={{ py: '1px', px: 2.5, typography: 'overline', textTransform: 'uppercase' }}
                                    >
                                        Old
                                    </ListSubheader>
                                }
                            >
                                {query.old.map((n, i) => (
                                    <NotificationItem
                                        key={i + n.Id}
                                        notification={n}
                                        isDeleting={query.removing.includes(n)}
                                        onDelete={() => query.delete(n)}
                                    />
                                ))}
                            </List>
                        )}
                    </Scrollbar>
                )}
            </MenuPopover>
        </>
    )
}
