import { find, isEmpty } from 'lodash'
import { BaseModel } from '../@types/base';
import { ImpactLevelsTree } from '../@types/settings';
import axiosInstance, { getResponseData } from 'utils/axios';

export const isNewInitiative = (initiative: BaseModel | Record<string, any>) => initiative.Id === '00000000-0000-0000-0000-000000000000' || isEmpty(initiative.Id)
export const isNewRecord = isNewInitiative

export const impactWorkingHours = (impact: Record<string, any>, impactLevelsTree: ImpactLevelsTree): number => {
    const { ImpactHours } = impact
    if (ImpactHours > 0) {
        return ImpactHours
    }
    const impactType = impact?.ImpactType||''
    const level = find(
        impactLevelsTree[impactType]||[],
        { Level: ""+impact?.Level }
    )

    if (level) {
        return ((level.Max + level.Min) / 2.0)
    }

    return 0;
}

export const impactReportWorkingHours = (impact: Record<string, any>): number => (impact?.ImpactHours || impact?.WeeklyHours) || 0

export const sortImpactReportByWorkingHours = (impacts: Record<string, any>[]): Record<string, any>[] => {
    const res = [ ...impacts ]
    res.sort((a, b) => impactReportWorkingHours(b) - impactReportWorkingHours(a))
    return res
}


export const saveInitiative = (requestData: NewInitiativeValuesProps) => getResponseData('/api/Initiative/SaveInitiatives?request=d', requestData)


export const saveInitiativeAndImpact = async (requestData: Partial<NewInitiativeValuesProps>, impacts: Record<string, any>) => {
    let initiativeId = ''
    try {
        initiativeId = await saveInitiative({ ...NewInitiativeValues, ...requestData }) as string
        await Promise.allSettled(impacts.map(
            (impact: Record<string, any>) =>
                getResponseData('/api/Initiative/SaveChangeImpact?request=d', { ...impact, InitiativeId: initiativeId })
        ))
    } catch (err) {
        if (initiativeId) {
            axiosInstance.post('/api/Initiative/DeleteInitiatives?request=d&id=' + initiativeId)
                .catch((err) => console.log(err))
        }
        throw err
    }
    return initiativeId
}


export interface NewInitiativeValuesProps {
    Id: string
    Name: string
    TypeId: string
    TypeIds: string[],
    PrimaryContact: string
    Owner: string
    InitiativePhaseId: string
    EditableFieldOptionIds: string[],
    InitiativeStrategicAlignmentsIds: string[],
    InitiativeDivisionThemesIds: string[],
    DivisionId: string,
    ChangeResources: string[],
    ParticipatingUsersIds: string[],
    TagIds: string[],
    Description: string,
    Priority: number,
    ChangeReadiness: number
    scenario: string,
    UseScenarios: boolean,
    DeleteWhenScenarioExpired: boolean,
    ScenarioInitiatives: string[],
    EditableFieldValue: string[],
}

export const NewInitiativeValues: NewInitiativeValuesProps = {
    Id: '00000000-0000-0000-0000-000000000000',
    Name: '',
    TypeId: '',
    TypeIds: [],
    PrimaryContact: '',
    Owner: '',
    InitiativePhaseId: '',
    EditableFieldOptionIds: [],
    EditableFieldValue: [],
    InitiativeStrategicAlignmentsIds: [],
    InitiativeDivisionThemesIds: [],
    DivisionId: '',
    ChangeResources: [],
    ParticipatingUsersIds: [],
    TagIds: [],
    Description: '',
    Priority: 0,
    ChangeReadiness: 0,
    scenario: '',
    UseScenarios: false,
    DeleteWhenScenarioExpired: false,
    ScenarioInitiatives: [],
}

export const InitiativeImpactInitialValues = {
    Id: '00000000-0000-0000-0000-000000000000',
    InitiativeId: '',
    Description: '',
    StakeholderIds: [],
    Level: '',
    IsTraining: false,
    IsMarketingCampaign: false,
    ImpactActivityIds: [],
    ImpactScaleId: '0826bd30-b139-4c30-aadb-2abce1060444',
    Name: '',
    Order: 1,
    Comment: '',
    DivisionsIds: [],
    SubDivisionsIds: [],
    Teams: [],
    Timing: { Ranges: [], Dates: [] },
    IsCustomerImpact: false,
    NumberOfCustomersID: null,
    Care: 0,
    ImpactMagnitude: 0,
    PositiveNegative: 0,
    Score: 1,
    ImpactCustomerTypeId: null,
    ImpactCustomerTypeIds: null,
    ImpactType: '',
    ImpactHours: 0,
    PartnerNameIds: [],
    PartnerTypeIds: [],
    PartnerStakeholderIds: [],
    RegionIds: [],
    RegionName: [],
    DistrictOfficeIds: [],
    DistrictOfficeName: [],
    NetworkOfficeIds: [],
    NetworkOfficeName: [],
    PartnerOrgName: [],
    PartnerOrgNameIds: [],
    PartnerOrgTeamIds: [],
    PartnerOrgTeamName: [],
    PartnerOrgTypeIds: [],
    PartnerOrgTypeName: [],
    ImpactScalePartnerId: '',
    EmpGeographyRegionIds: [],
    EmpGeographyNetworkOfficeIds: [],
    EmpGeographyDistrictOfficeIds: [],
}
