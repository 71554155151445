import React, { useMemo } from 'react'
import { SectionComponentProps } from 'views/insights/sections/type';
import usePrevious from 'hooks/usePrevious';
import { impactReportWorkingHours, sortImpactReportByWorkingHours } from 'utils/iniatives';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InitiativeLink from 'components/InitiativeLink';


const SequencingRisksActivitySection:React.FC<SectionComponentProps> = ({
    expanded,
    onChange,
    data,
    weeks
}) => {

    const [ previous, setPrevious ] = usePrevious<Record<string, any>[]>([])

    const impacts = useMemo(() => {
        if (!expanded || previous.length > 0) {
            return previous
        }
        const res = sortImpactReportByWorkingHours(
            (data?.impacts || []).filter((impact: Record<string, any>) => impactReportWorkingHours(impact) >= 4)
        )
        setPrevious(res)
        return res
    }, [expanded])

    const renderContent = (): React.ReactElement => {
        if ((impacts||[]).length === 0) {
            return (<Typography variant={'body2'}>No data</Typography>)
        }
        return (
            <Typography variant={'body2'}>
                There are activities that are more than 4 hours, then
                {
                    impacts.slice(0, 5).map(
                        (impact: Record<string, any>) => {
                            return (<p key={`${impact.ChangeImpactName} from ${impact.Initiatives}`}><b>{impact.ChangeImpactName}</b> within <InitiativeLink id={impact.InitiativeId} name={impact.Initiatives}/></p>)
                        }
                    )
                }
                seems to have a lot of time impacts.
                You may want to consider chunking this down to smaller durations of impact activities.
            </Typography>
        )
    }

    if (impacts?.length === 0) {
        return (<></>)
    }


    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    Risk of high impact activities
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}

export default SequencingRisksActivitySection