import React from 'react'
import { PATH_SETTINGS } from './paths'
import { Navigate, RouteObject } from 'react-router-dom'
// layouts
import CompanyAdminGuard from 'guards/CompanyAdminGuard'
import DashboardLayout from 'layouts/dashboard';
import { lazyLoad } from '../utils/routes'

// ----------------------------------------------------------------------

const SetupPage = lazyLoad(() => import('views/settings/Setup/Setup'), 'SetupPage')
const CompanyHierarchyPage = lazyLoad(() => import('views/settings/CompanyHierarchy'), 'CompanyHierarchyPage')
const UsersPage = lazyLoad(() => import('views/settings/Users'), 'UsersPage')
const CompanyProfilePage = lazyLoad(() => import('views/settings/CompanyProfile'), 'CompanyProfilePage')
const IntegrationPage = lazyLoad(() => import('views/settings/Integrations'), 'IntegrationPage')

const SettingsRoutes = [
    { path: PATH_SETTINGS.root, element: <Navigate to={PATH_SETTINGS.settings.profile} /> },
    { path: PATH_SETTINGS.settings.setup, element: <Navigate to={PATH_SETTINGS.settings.setupInitiative} /> },
    {
        path: `${PATH_SETTINGS.settings.setup}/:tab`,
        element: <CompanyAdminGuard>
            <DashboardLayout title={'Settings / Setup'}>
                <SetupPage />
            </DashboardLayout>
        </CompanyAdminGuard>
    },
    {
        path: PATH_SETTINGS.settings.hierarchy,
        element: <CompanyAdminGuard>
            <DashboardLayout title={'Settings / Company Hierarchy'}>
                <CompanyHierarchyPage />
            </DashboardLayout>
        </CompanyAdminGuard>
    },
    {
        path: PATH_SETTINGS.settings.integration,
        element: <Navigate to={PATH_SETTINGS.settings.integrationJira} />
    },
    {
        path: `${PATH_SETTINGS.settings.integration}/:tab`,
        element: <CompanyAdminGuard>
            <DashboardLayout title={'Settings / Integration'}>
                <IntegrationPage />
            </DashboardLayout>
        </CompanyAdminGuard>
    },
    {
        path: PATH_SETTINGS.settings.users,
        element: <CompanyAdminGuard>
            <DashboardLayout title={'Settings / Users'}>
                <UsersPage />
            </DashboardLayout>
        </CompanyAdminGuard>
    },
    {
        path: PATH_SETTINGS.settings.profile,
        element: <CompanyAdminGuard>
            <DashboardLayout title={'Settings / Company Profile'}>
                <CompanyProfilePage />
            </DashboardLayout>
        </CompanyAdminGuard>
    },
    /*{
        path: PATH_SETTINGS.settings.subscription,
        element: <CompanyAdminGuard>
            <DashboardLayout title={'Settings / Subscriptions'}>
                <SubscriptionsPage />
            </DashboardLayout>
        </CompanyAdminGuard>
    },*/
] as RouteObject[]

export default SettingsRoutes
