import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { fetchResponseData, getResponseData } from 'utils/axios'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import { createSelector } from 'reselect'
import { ColorIntensiveType } from '../../../@types/reports'

interface ColorIntensive {
    isLoading: boolean
    error: boolean
    colorIntensity: Array<ColorIntensiveType>
}

const initialState: ColorIntensive = {
    isLoading: false,
    error: false,
    colorIntensity: [],
}

const slice = createSlice({
    name: 'colorIntensity',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
        setColorIntensity(state, action) {
            state.colorIntensity = action.payload
        },
        addColorIntensity(state, action) {
            const newItem = action.payload
            const colorIntensity = state.colorIntensity.filter((i) => i.Id !== newItem.Id)
            colorIntensity.push(newItem)
            state.colorIntensity = [...colorIntensity]
        },
    },
})

export default slice.reducer

export function loadColorIntensity() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const values = ((await fetchResponseData('/api/Report/GetColorIntensity?request=d')) as Array<any>) || undefined
            dispatch(slice.actions.setColorIntensity(values ? [...values] : []))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const getColorIntensity = (key: string) =>
    createSelector(
        (state: RootState) => state.reports.colorIntensity.colorIntensity,
        (colorIntensity: ColorIntensiveType[]): number =>
            colorIntensity.find((value: ColorIntensiveType) => value?.ReportType === key)?.ColorIntensityValue || 0
    )

export const saveColorIntensity = (key: string, value: number) => async (dispatch: Dispatch<any>) => {
    try {
        const data = await getResponseData('/api/Report/SaveColorIntensity', {
            ReportType: key,
            ColorIntensityValue: value,
        })
        dispatch(slice.actions.addColorIntensity(data))
    } catch (error: any) {
        reportError(error)
    }
}
