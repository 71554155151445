const appConfig = {
    planListUrl: 'https://thechangecompass.com/pricing-tcc/',
    policyUrl: 'https://thechangecompass.com/customer-agreement/',
    baseApiUrl: 'https://api.thechangecompass.com/',
    sandboxApiUrl: 'https://changecompassws-apisanbox.azurewebsites.net/',
    stagingApiUrl: 'https://changecompassws-api-staging.azurewebsites.net/',
    googlePlacesApiKey: 'AIzaSyBCzL5xRyIjI9eE84B12o7qdGaSwBfbRUg',
    getApiUrl: () => {
        if (!process.env.NODE_ENV || (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_TOKEN)) {
            return appConfig.stagingApiUrl
        }
        return appConfig.baseApiUrl
    },
}
export default appConfig
