import React, { useMemo } from 'react'
import { SectionComponentProps } from 'views/insights/sections/type';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'hooks/useAppRedux';
import { getInsightsEntityData } from 'store/slices/insights';
import { groupBy } from 'lodash';
import { areIntervalsOverlapping } from 'date-fns';
import DisplayPeriods from 'components/DisplayPeriods';
import { mergeWeeksInMonth } from 'utils/date';
import { WeeksInMonth } from '../../../../@types/reports';


const BusinessCapacityChangeResourceSection:React.FC<SectionComponentProps>  = (
    { expanded, onChange, data, weeks } : SectionComponentProps
) => {

    const changeResources = useAppSelector(getInsightsEntityData).changeResources

    const resources = useMemo(() => {
        const grouped = groupBy(changeResources, (item) => item?.NameOfUser)

        const res = Object.entries(grouped).map(([key, values]) => {
            const _weeks: number[] = []
            weeks.forEach((week, index) => {
                _weeks[index] = _weeks[index] || 0
                values.forEach((value) => {
                    const range = { start: week.from, end: week.to }
                    const range2 = { start: value.From as Date, end: value.To as Date }
                    if (areIntervalsOverlapping(range, range2, { inclusive: true })) {
                        _weeks[index] = (_weeks[index] || 0) + value.Percentage
                    }
                })
            })
            return {
                name: key,
                weeks: _weeks.map((week, index) => week > 99 ? weeks[index] : null).filter((i) => i)
            }
        }).filter((i) => i.weeks.length > 0)

        return res
    }, [ changeResources ])

    const renderContent = (): React.ReactElement => (
        <>
            {
                resources.map((resource) => (
                    <Typography variant={'body2'} key={resource.name}>
                        <b>{resource.name}</b> will be over 100% capacity between <DisplayPeriods periods={mergeWeeksInMonth(resource.weeks as WeeksInMonth[])} />
                    </Typography>
                ))
            }
        </>
    )

    if (resources.length === 0) {
        return (<></>)
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    If there Resourcing will exceed the capacity of the business?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}


export default BusinessCapacityChangeResourceSection
