// ----------------------------------------------------------------------

/// /opt/github/compass-frontend/node_modules/@mui/system/createTheme/shape.d.ts
declare module '@mui/system/createTheme/shape' {
    interface Shape {
        borderRadius: number
        borderRadiusSm: number | string
        borderRadiusMd: number | string
    }
}

declare module '@mui/system/createTheme' {
    interface Shape {
        borderRadius: number | string
        borderRadiusSm: number | string
        borderRadiusMd: number | string
    }
}

const shape = {
    borderRadius: 8,
    borderRadiusSm: 12,
    borderRadiusMd: 16,
}

export default shape
