import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { GridContainer, GridItem } from 'components/GridElements';
import { range } from 'lodash';

interface SkeletonReportLoaderProps {
    tabsNum?: number
}

const SkeletonReportLoader = ({ tabsNum = 2 }: SkeletonReportLoaderProps) => (
    <Stack spacing={1}>
        <GridContainer spacing={1}>
            <GridItem>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: '3rem' }} />
            </GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>
            <GridItem sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'flex-end',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <Skeleton animation="wave" variant="text" width={190} sx={{ fontSize: '3rem', mr: '20px' }} />
                &nbsp;
                <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton variant="circular" animation="wave" width={30} height={30} sx={{ zIndex: 9999 }} />
                    <Skeleton variant="rectangular"  width={50} height={20} sx={{ ml: '-5px !important', mt: '5px !important' }}/>
                </Stack>

            </GridItem>
        </GridContainer>

        <br/>
        {
            tabsNum > 0 && (
                <GridContainer spacing={1}>
                    {
                        range(0, tabsNum).map((i) => (
                            <GridItem key={`keyTabs-${i}`}>
                                <Skeleton variant="rectangular"  height={60} />
                            </GridItem>
                        ))
                    }
                    <GridItem>&nbsp;</GridItem>
                    <GridItem>&nbsp;</GridItem>
                </GridContainer>
            )
        }
        <Skeleton variant="rounded" width={'100%'} height={'30vh'} animation="wave" />
        <br/>
        <Skeleton variant="rounded" width={'100%'} height={'100vh'} animation="wave" />
    </Stack>
)
export default SkeletonReportLoader