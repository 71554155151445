import React from 'react';
import { DateRange } from 'components/DateRangePicker';
import { formatDate } from 'utils/formatDate';

interface DisplayPeriodsProps {
    periods: DateRange<Date>[] | undefined
}

const DisplayPeriods = ({ periods }: DisplayPeriodsProps) => {

    if (!periods) {
        return (<></>)
    }

    const data = (periods || []) as DateRange<Date>[]
    const lastIndexOfPeriods = data.length - 1

    return (
        <>
            {
                data.map((period: DateRange<Date>, index: number) => (
                    <span key={'DisplayPeriods' + index}>
                        {formatDate(period[0])} - {formatDate(period[1])} { index < lastIndexOfPeriods ? ' and ' : '' }
                    </span>
                ))
            }
        </>
    )
}

export default DisplayPeriods;