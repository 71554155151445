import React from 'react';
import { generatePath } from 'react-router';
import { PATH_INITIATIVES } from 'routes/paths';
import { Link } from 'react-router-dom';

type InitiativeLinkProps = {
    id: string
    name: string
    impactId?: string
}

const InitiativeLink: React.FC<InitiativeLinkProps> = ({ id , name, impactId }) => {
    return (
        <Link
            style={{ color: '#1b67c9' }}
            target='_blank'
            to={generatePath(
                impactId ? PATH_INITIATIVES.actions.addEditImpactWithStep : PATH_INITIATIVES.actions.addEdit,
                { initiativeId: id,  ...(impactId && { impactId }) }
            )}
        >
            {name}
        </Link>
    )
}

export default InitiativeLink