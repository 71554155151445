import { Order } from './EnhancedTableHead'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

export function getComparatorCallback<Key extends keyof any>(
    order: Order,
    orderBy: Key,
    comparator: any
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    const format = comparator !== undefined ? comparator : (i: any) => ({ [orderBy]: i })
    return order === 'desc'
        ? (a, b) => descendingComparator(format(a[orderBy] || '', a), format(b[orderBy] || '', b), orderBy)
        : (a, b) => -descendingComparator(format(a[orderBy] || '', a), format(b[orderBy] || '', b), orderBy)
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = (array || []).map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}
