import { get, isArray, isEmpty, isEqual, isObject, isString, omitBy, transform } from 'lodash'

export const objectEquals = (i: Record<string, any>, ii: Record<string, any>, keys: string[] = []): boolean => {
    const origin = i || {}
    const _keys = isEmpty(keys) ? Object.keys(origin) : keys
    return _keys.every((k) => origin[k] === ii[k])
}

export const markDuplicate = (items: Array<Record<string, any>>) =>
    (items || []).reduce(
        // @ts-ignore
        (p, c) => ((c.isDuplicate = p.some((o: Record<string, any>) => objectEquals(o, c))), p.concat(c)),
        []
    )

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @param  {array} ignore  - list of attributes to ignore
 * @return {object} differences
 */
export const objectDifference = (origObj: Record<string, any>, newObj: Record<string, any>, ignore: string[] = []): Record<string, any> => {
    function changes(newObj: Record<string, any>, origObj: Record<string, any>) {
        let arrayIndexCounter = 0
        return transform(newObj, function (result, value, key) {
            if (ignore.includes(key)) {
                return;
            }
            if (!isEqual(value, origObj[key])) {
                const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
                result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
            }
        }) as Record<string, any>
    }

    return changes(newObj, origObj)
}

export const anyToArr = (val: any) => isString(val) ? [val].filter((i) => i) : (val ? val : [])

export const cleanObject = (val: Record<string, any>) : Record<string, any> => {
    return omitBy(val, (v: any) => {
        if (!v || isEmpty(v)) {
            return true
        }
        if (isArray(v)) {
            return v.filter((i) => i).length === 0
        }
        return false
    })
}

export const randomItem = (arr: Array<any>): any => arr[Math.floor(Math.random()*arr.length)]

export const calculateSum = (filtered: Record<string, any>[], field: string, floatPoint=2): number => {
    const res = filtered.reduce((x:number, y: Record<string, any>) => x + get(y, field, 0), 0)
    return isNaN(res) || !res ? parseFloat(0.0.toFixed(floatPoint)) : parseFloat(res.toFixed(floatPoint))
}

export const calculateAvg = (filtered: Record<string, any>[], field: string, floatPoint=2) : number => {
    const res = calculateSum(filtered, field, floatPoint) / filtered.length
    return isNaN(res) ? parseFloat(0.0.toFixed(floatPoint)) : parseFloat(res.toFixed(floatPoint))
}

export const average = (filtered: number[], floatPoint=2) : number => {
    const res = filtered.reduce((x:number, y: number) => x + y, 0) / filtered.length
    return isNaN(res) ? parseFloat(0.0.toFixed(floatPoint)) : parseFloat(res.toFixed(floatPoint))
}

export const filterEmpty = <T> (arr: T[]): T[] =>  (arr||[]).filter((i) => i) as T[]

/**
 * Gets a set of integers that are evenly distributed along a closed interval
 * @param {int} begin - Beginning endpoint (inclusive)
 * @param {int} end   - Ending endpoint (inclusive)
 * @return {Array} Range of integers
 */
export const rangeInt = ( begin: number, end: number ) => {
    if ( !Number.isInteger(begin) || !Number.isInteger(end) ) {
        throw new Error('All arguments must be integers')
    }
    return rangePoints(begin, end, Math.abs(end - begin) - 1)
}

/**
 * Gets a set of numbers that are evenly distributed along a closed interval
 * @param {Number} begin  - Beginning endpoint (inclusive)
 * @param {Number} end    - Ending endpoint (inclusive)
 * @param {int}    points - How many numbers to retrieve from the open interval
 * @param {bool}   floatable - return float range
 * @return {Array} Range of numbers
 */
export const rangePoints = (begin: number, end:number, points: number, floatable: boolean = false ) => {
    if ( begin !== end ) {
        return [ begin, ...between(begin, end, points -1, floatable) ]
    }
    else if ( Number.isFinite(begin) ) {
        return [ begin ] // singleton set
    }
    else throw new Error('Endpoints must be finite')
}

/**
 * Gets a subset of numbers that are evenly distributed along an open interval
 * @param {Number} begin  - Beginning endpoint (exclusive)
 * @param {Number} end    - Ending endpoint (exclusive)
 * @param {int}    points - How many numbers to retrieve from the interval
 * @param {bool}   floatable - return float numbers
 * @return {Array} Retrieved numbers
 */
export const between = ( begin: number, end: number, points = 1, floatable: boolean = false ) => {
    if ( !Number.isFinite(begin) || !Number.isFinite(end) || !Number.isFinite(points) ) {
        throw new Error('All arguments must be finite')
    }
    const set = []
    // Skip if an open interval does not exist
    if ( begin !== end ) {
        const step = (end - begin) / (points + 1)
        for ( let i = 0; i < points; i++ ) {
            const num = begin + (i + 1) * step
            set[i] = floatable ? num : Math.floor(num)
        }
    }
    return set
}


export const topPercentItems = <T>(arr: T[], percents: number = 20): T[] => {
    if (!arr) {
        return []
    }
    if (arr.length === 0) {
        return []
    }
    const amount =   Math.ceil( (arr.length * percents / 100) ) || 1
    return arr.slice(0, amount)
}