import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'
import { Stakeholder } from '../../../@types/impact';
import { createSelector } from 'reselect';
import { RootState } from 'store/store';


interface StakeholdersState {
    isLoading: boolean,
    error: boolean,
    stakeholders: Stakeholder[],
}

const initialState: StakeholdersState = {
    isLoading: false,
    error: false,
    stakeholders: [],
}

const slice = createSlice({
    name: 'stakeholders',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getStakeholdersSuccess(state, action) {
            state.isLoading = false
            state.stakeholders = action.payload
        },
    },
})

export default slice.reducer

export const getStakeholderList = createAsyncThunk(
    'thunk/fetchImpactStakeholder',
    async (_, { dispatch }) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/Stakeholder/GetStakeholderList')
            const res = responseData(response.data)
            dispatch(slice.actions.getStakeholdersSuccess(responseData(response.data)))
            return res
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
        return []
    }
)

export const getStakeholders = createSelector(
    (state: RootState) => state.impact.stakeholders,
    (stakeholders): Stakeholder[] => stakeholders.stakeholders
)

