import * as d3_7 from 'd3-7'

//const format = '113.71429,125.21933 328,339.50504 533.71429,125.21933'
export default {
    draw(context, size) {
        const r = Math.sqrt(size / 5) / 2;
        context.moveTo(-r * 3, -r * 3);
        context.lineTo(r, r * 4)
        context.moveTo(r, r * 4);
        context.lineTo(r * 3 , r * -4)
        context.closePath()
    }
} as d3_7.SymbolType;