import React from 'react'

import App from './App'

import { createRoot } from 'react-dom/client';


try {
    if (!window.location.host.includes('localhost')) {
        import('@bugsnag/js').then((Bugsnag) => {
            import('@bugsnag/plugin-react').then(({ default: BugsnagPluginReact }) => {
                //@ts-ignore
                Bugsnag.start({
                    apiKey: 'ff43c293598c6bd08902ba4f7b38f26f',
                    //@ts-ignore
                    plugins: [new BugsnagPluginReact()],
                })
                //@ts-ignore
                window.Bugsnag = Bugsnag
            })
        })
    }
} catch (_) {
    ///
}

const container = document.getElementById('root');
const root = createRoot(container as Element | DocumentFragment); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);