import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function Paper(theme: Theme) {
    return {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },

            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    boxShadow: 'none !important',
                    border: 'solid 1px rgba(145, 158, 171, 0.24)',
                    /// rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px
                },
            },
        },
    }
}
