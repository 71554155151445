import { fromPairs, isString } from 'lodash'
import * as yup from 'yup'
import axios from 'axios';
import { isProduction } from 'utils/general';
import { StringSchema } from 'yup';

export const reportError = (error: any): void => {

    try {
        if (error?.Message?.includes('Authorization')
            || error?.includes('Authorization')
            || error === 'Request canceled'
            || axios.isCancel(error)
            || error?.Message === 'Request canceled') {
            console.error(error)
            return
        }
    } catch (err) {
        /// pass
    }
    try {
        console?.error(error)
    } catch (err) {
        ///pass
    }

    try {
       if (isProduction()) {
            // @ts-ignore
            window.Bugsnag?.notify(error, (...rest) => console?.error(rest))
       }
    } catch (err) {
        // pass
    }
}

export const validateSchema = (validationSchema: yup.ObjectSchema<any, any, any, any>, value: any): Record<string, string[]> => {
    try {
        validationSchema.validateSync(value, { abortEarly: false })
    } catch (error: any) {
        return fromPairs(error.inner?.map((error: yup.ValidationError) => [error.path, error.errors]))
    }
    return {}
}



export const validateName = (names: string[], value: any) => {
    const scheme = yup.object().shape({
        Name: yup
            .string()
            .trim()
            .required('Name is required')
            .typeError('Name is required')
            .test('Unique', 'Item already exists with that name', (values) => !names.includes(values as string)),
    })

    return validateSchema(scheme, value)
}

export const passwordValidation = () => matchPasswordComplexity(
    yup
        .string()
        .typeError('Password is required')
        .required('Password is required')
)

export const matchPasswordComplexity = (schema: StringSchema) => schema
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        { message: 'Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character', excludeEmptyString:true }
    )


export const getErrorMsg = (err: any, defaultMsg: string = 'Something went wrong!.') => {
    if (isString(err)) {
        return err
    }
    return  err?.message || err?.rm?.message || err?.data?.message || err?.data?.rm?.message || defaultMsg
}
