import React from 'react'
import { SymbolType } from 'd3-shape';
import useD3Chart from 'hooks/useD3Chart';
import * as d3 from 'd3-7'
import { isString } from 'lodash'


interface D3SignProps {
    symbol:SymbolType|string,
    color: string,
    width: number,
    height: number,
    transform?: string
}

const D3Sign = ({ symbol, color, width = 24, height = 24, transform }: D3SignProps) => {
    const ref = useD3Chart((svg) => {
        svg.select('*').remove()
        svg.append('path')
            .attr('d', isString(symbol) ? symbol : d3.symbol(symbol, width * 4))
            //.classed('impact-stars', true)
            .attr('fill', color||'yellow')
            .attr("transform", transform ? transform : `translate(${width/2}, ${(height/2)+2})`)
            .attr('stroke', '#2f3030')
            .attr('stroke-width', '1px')
    })
    return <svg
        ref={ref}
        width={width}
        height={height}
        preserveAspectRatio='xMinYMin meet'
        viewBox={`0 0 ${width} ${height}`}
        //className='svg-content-responsive'
    >
        <defs />
    </svg>
}


export default D3Sign