import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance, { fetchResponseData, getResponseData, getSandboxInstance, responseData } from 'utils/axios'
import { filter, find, orderBy, sortBy } from 'lodash'
import {
    CompanyHierarchyItem,
    CompanyHierarchyTree,
    CompanySettingsField,
    CompanySettingsProps,
    ImpactLevelsTree
} from '../../@types/settings'


import { EditableFieldOptionsProps, EditableFieldRespData, EditableFieldTree, EditableFieldProps } from '../../@types/initiative'
import { createSelector } from 'reselect'
import { RootState } from 'store'
import { CompanySubscription, CompanyViewerSubscription } from '../../@types/payments'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'
import { parseISO } from 'date-fns'
import { isSandBox } from 'store/slices/settings';
import { sortByCaseSensitive } from 'utils/string';
import { BaseModel } from '../../@types/base';

export interface Benefit {
    Id: string
    CompanyId: string
    Name: string
}

export interface CompanyState {
    isCompanyLoading: boolean
    settings: CompanySettingsProps
    settingsField: CompanySettingsField
    benefits: Benefit[]
    hierarchyTree: CompanyHierarchyTree
    sandboxHierarchyTree: CompanyHierarchyTree
    editableFields: EditableFieldRespData
    companySubscription: CompanySubscription
    companyViewerSubscription: CompanyViewerSubscription
}

const initialState: CompanyState = {
    isCompanyLoading: true,
    settingsField: {
        NumDaysNotifyUpdateInitiatives: -1
    },
    settings: {
        DateFormat: null,
        DateTimeFormat: null,
        Name: '',
        Id: '',
        LogoData: '',
        LastUpdateLogo: '',
        CompanyAdmin: {
            Id: '',
            FullName: '',
        },
        DisableNotifications: false,
        LoadingLevel: '',
        ResourceData: '',
        WithImpactlvl0: false,
        UploadDocuments: '',
        CompanyHierarchyItems: '',
        CompanyHierarchyItemId: '',
        baseCompanyId: '',
        HasDeliverablesAccess: false,
        HasIntegrationsAccess: false,
        ReportSettings: {}
    },
    benefits: [],
    editableFields: {
        EditableField: [],
        EditableFieldOptions: [],
    },
    hierarchyTree: {
        Division: [],
        SubDivision: [],
        Team: [],
    },
    sandboxHierarchyTree: {
        Division: [],
        SubDivision: [],
        Team: [],
    },
    companySubscription: {
        AccessType: '',
        CountUser: 0,
        CreatedAt: new Date(),
        CustomerId: null,
        ExpiryDate: new Date(),
        Id: '00000000-0000-0000-0000-000000000000',
        LastModifiedAt: new Date(),
        PlanId: null,
        SubscriptionId: null,
        SubscriptionType: '',
    },
    companyViewerSubscription: {
        CountUser: 0,
        Amount: 0,
        CurrentPeriodStart: new Date(),
        CurrentPeriodEnd: new Date(),
    },
}

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isCompanyLoading = true
        },

        loaded(state) {
            state.isCompanyLoading = false
        },

        setSettings(state, action) {
            const settings = { ...action.payload }
            let ReportSettings = {}
            if (settings.ReportSettings) {
                try {
                    ReportSettings = JSON.parse(settings.ReportSettings)
                } catch (err) {
                    // pass
                }
            }
            state.settings = { ...settings, ReportSettings }
        },

        setBenefits(state, action) {
            state.benefits = action.payload
        },

        setSettingField(state, action) {
            state.settingsField = action.payload
        },

        setHierarchyTree(state, action) {
            const { Division, SubDivision, Team } = action.payload
            state.hierarchyTree = {
                Division: sortByCaseSensitive(Division||[], 'Name'),
                SubDivision: sortByCaseSensitive(SubDivision||[], 'Name'),
                Team: sortByCaseSensitive(Team||[], 'Name'),
            }
        },

        setSendboxHierarchyTree(state, action) {
            const { Division, SubDivision, Team } = action.payload
            state.sandboxHierarchyTree = {
                Division: sortByCaseSensitive(Division||[], 'Name'),
                SubDivision: sortByCaseSensitive(SubDivision||[], 'Name'),
                Team: sortByCaseSensitive(Team||[], 'Name'),
            }
        },

        setEditableFields(state, action) {
            state.editableFields = {
                EditableField: action.payload.EditableField.map((i: Record<string, any>) => ({ ...i, Name: i.FieldText })),
                EditableFieldOptions: action.payload.EditableFieldOptions.map((i: Record<string, any>) => ({ ...i, Name: i.OptionText })),
            }
        },
        setCompanySubscription(state, action) {
            state.companySubscription = action.payload
        },
        setCompanyViewerSubscription(state, action) {
            state.companyViewerSubscription = action.payload
        },
        setReportSettings(state, action) {
            state.settings.ReportSettings = action.payload
        }
    },
})

export const { setReportSettings } = slice.actions;
// Reducer
export default slice.reducer

export const financialBenefit = (state: RootState) => find(state.benefits, { Name: 'Financial' })
export const nonFinancialBenefit = (state: RootState) => find(state.benefits, { Name: 'Non-financial' })

export const employeeLevel = (state: RootState) => sortBy(filter(state.company.settings.CompanyLevels, { LevelType: 'EmpImpt' }), 'Level')
export const partnerLevel = (state: RootState) => sortBy(filter(state.company.settings.CompanyLevels, { LevelType: 'PartImpt' }), 'Level')
export const customerLevel = (state: RootState) => sortBy(filter(state.company.settings.CompanyLevels, { LevelType: 'CusImpt' }), 'Level')
export const systemLevel = (state: RootState) => sortBy(filter(state.company.settings.CompanyLevels, { LevelType: 'SysImpt' }), 'Level')
export const processLevel = (state: RootState) => sortBy(filter(state.company.settings.CompanyLevels, { LevelType: 'ProcImpt' }), 'Level')


export const getImpactLevelsTree = createSelector(
    (state: RootState) => state,
    (state): ImpactLevelsTree => ({
        Employee: employeeLevel(state),
        System: systemLevel(state),
        Customer: customerLevel(state),
        Process: processLevel(state),
        Partner: partnerLevel(state),
    })
)

export const getCompanyHierarchyTree = createSelector(
    (state: RootState) => state.company,
    (state: RootState) => isSandBox(state),
    (company, sandbox): CompanyHierarchyTree => sandbox ? company.sandboxHierarchyTree : company.hierarchyTree
)


export const updateEditableFields = (_editableFields: EditableFieldProps[], options: EditableFieldOptionsProps[]) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const editableFields = _editableFields.map((i: EditableFieldProps) => {
            // @ts-ignore
            const { Options, ...rest } = i
            return rest as EditableFieldProps
        })

        // EditableFieldOptionsProps
        if (options.length > 0) {
            const fields = getState().company.editableFields.EditableField
            axiosInstance.post('/api/EditableField/SaveUpdateEditableFieldOptions?request=d', options).then((data) => {
                dispatch(slice.actions.setEditableFields(
                    {
                        EditableField: fields,
                        EditableFieldOptions: getState().company.editableFields.EditableFieldOptions
                            .map((i: EditableFieldOptionsProps) => find(options, { 'Id': i.Id }) || i)
                    }
                ))
            })
        } else {
            const fields = getState().company.editableFields.EditableFieldOptions

            axiosInstance.post('/api/EditableField/SaveUpdateEditableFields?request=d', editableFields).then((data) => {
                dispatch(slice.actions.setEditableFields(
                    {
                        EditableField: getState().company.editableFields.EditableField
                            .map((i: EditableFieldProps) => find(editableFields, { 'Id': i.Id }) || i),
                        EditableFieldOptions: fields
                    }
                ))
            })
        }
    }
}

export const fetchEditableFields = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        // @ts-ignore
        fetchResponseData('/api/EditableField/GetEditableFieldDataForCompany?request=d&CompanyId=' + getState().company.settings.Id).then((data: EditableFieldRespData) => {
            const mapFn = (i: Record<string, any>, index: number) => ({ ...i, Order: i.Order || index })
            // @ts-ignore
            dispatch(slice.actions.setEditableFields(
                {
                    EditableField: orderBy(data.EditableField.map(mapFn), 'Order'),
                    EditableFieldOptions: orderBy(data.EditableFieldOptions.map(mapFn), 'Order')
                }
            ))
        }).catch(() => {})
    }
}

export const fetchCompanySettingsField = createAsyncThunk(
    'thunk/companySettingsField',
    /// @ts-ignore
    async (_, { dispatch }) => {
        dispatch(slice.actions.startLoading())
        try {
            const resp = await fetchResponseData('/api/CompanySettings/GetSettingField') as Record<string, any>
            dispatch(slice.actions.setSettingField({
                NumDaysNotifyUpdateInitiatives: parseInt(resp?.NumDaysNotifyUpdateInitiatives||'2')
            }))
        } finally {
            dispatch(slice.actions.loaded())
        }
    }
)

export const getCompanySettings = createSelector(
    (state: RootState) => state.company,
    (company): CompanySettingsProps => company.settings || {}
)

export const fetchCompanySettings = createAsyncThunk(
    'thunk/companySettings',
    /// @ts-ignore
    async (_, { dispatch, getState }) => {
        dispatch(slice.actions.startLoading())
        try {
            await Promise.all([

                fetchResponseData('/api/CompanySettings/GetCompanySettings').then((data) => {
                    // @ts-ignore
                    dispatch(slice.actions.setSettings({ ...data }))
                }),
                getResponseData('/api/BenefitType/GetBenefitTypeList?request=d').then((data) => {
                    // @ts-ignore
                    dispatch(slice.actions.setBenefits([...data]))
                }),
            ])

            // @ts-ignore
            fetchResponseData('/api/EditableField/GetEditableFieldDataForCompany?request=d&CompanyId=' + getState().company.settings.Id).then((data) => {
                // @ts-ignore
                dispatch(slice.actions.setEditableFields({ ...data }))
            }).catch(() => {})
        } finally {
            dispatch(slice.actions.loaded())
        }
        const { company } = getState() as RootState
        return Promise.resolve(company?.settings||{})
    }
)


export const deleteCompanyHierarchyItem = createAsyncThunk(
    'thunk/DeleteGetHierarchyListItem',
    async (item: CompanyHierarchyItem|BaseModel|Record<string, any>, { dispatch , getState }) => {
        try {
            await axiosInstance
                .post('/api/CompanySettings/DeleteHierarchyItem?request=d&id=' + item?.Id, {})
            await dispatch(fetchCompanyHierarchyList2())

        } finally {
            dispatch(slice.actions.loaded())
        }
    })
// Actions

export const fetchCompanyHierarchyList2 = createAsyncThunk(
    'thunk/GetHierarchyList2',
    async (_, { dispatch , getState }) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/CompanySettings/GetHierarchyList2')
            const data = responseData(response.data)
            if (isSandBox(getState())) {
                dispatch(slice.actions.setSendboxHierarchyTree({ ...data }))
            } else {
                dispatch(slice.actions.setHierarchyTree({ ...data }))
            }

        } finally {
            dispatch(slice.actions.loaded())
        }
})


export const saveUpdateEditableFieldOption = createAsyncThunk(
    'company/SaveUpdateEditableFieldOption',
    async (option: Partial<EditableFieldOptionsProps> | Record<string, any>, thunkAPI) => {
        const data = await getResponseData(`/api/EditableField/SaveUpdateEditableFieldOption?request=d`, option)
        await thunkAPI.dispatch(fetchEditableFields())
        return data
    }
)

export const getEditableFields = createSelector(
    (state: RootState): EditableFieldRespData => state.company.editableFields,
    (editableFields: EditableFieldRespData): EditableFieldTree[] =>
        sortBy(editableFields.EditableField, 'Order').map(
            (i: Record<string, any>) =>
                ({
                    ...i,
                    Options: sortBy(editableFields.EditableFieldOptions.filter((o: EditableFieldOptionsProps) => o.FieldId === i.Id), 'Order'),
                } as EditableFieldTree)
        ) as EditableFieldTree[]
)


export function getCompanySubscription() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const resp = await axiosInstance.get('/api/CompanySubscription/CompanySubscription?request=d', {
                params: {
                    // @ts-ignore
                    userEmail: getState()?.authJwt?.user?.email || '',
                },
            })
            const data = responseData(resp.data) || {}
            /// CreatedAt LastModifiedAt ExpiryDate
            dispatch(
                slice.actions.setCompanySubscription({
                    ...data,
                    CreatedAt: data?.CreatedAt ? parseISO(data?.CreatedAt) : new Date(),
                    LastModifiedAt: data?.LastModifiedAt ? parseISO(data?.LastModifiedAt) : new Date(),
                    ExpiryDate: data?.ExpiryDate ? parseISO(data?.ExpiryDate) : new Date(),
                })
            )
        } catch (error) {
            reportError(error)
        }
    }
}

export function getCompanyViewerSubscription() {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const resp = await axiosInstance.get('/api/CompanySubscription/CompanySubscriptionViewer?request=d', {
                params: {
                    // @ts-ignore
                    userEmail: getState()?.authJwt?.user?.email || '',
                },
            })

            const data = responseData(resp.data) || {}
            dispatch(
                slice.actions.setCompanyViewerSubscription({
                    ...data,
                    CurrentPeriodEnd: data?.CurrentPeriodEnd ? parseISO(data?.CurrentPeriodEnd) : new Date(),
                    CurrentPeriodStart: data?.CurrentPeriodStart ? parseISO(data?.CurrentPeriodStart) : new Date(),
                })
            )
        } catch (error) {
            reportError(error)
        }
    }
}

export const isTrialSubscription = createSelector(
    (state: RootState) => state.company.companySubscription,
    (companySubscription: CompanySubscription): boolean => companySubscription.AccessType === 'Subscription' && companySubscription.SubscriptionType === 'Free'
)

export const isPaidSubscription = createSelector(
    (state: RootState) => state.company.companySubscription,
    (companySubscription: CompanySubscription): boolean => companySubscription.AccessType === 'Subscription' && companySubscription.SubscriptionType === 'Paid'
)

export const subscriptionExpired = createSelector(
    (state: RootState) => state.company.companySubscription,
    (companySubscription: CompanySubscription): boolean => companySubscription.ExpiryDate < new Date()
)

export const validSubscription = createSelector(
    isTrialSubscription,
    isPaidSubscription,
    subscriptionExpired,
    (isTrial, isPaid, expired) => {
    if (isPaid || isTrial) {
        return !expired
    }
    return true
})

export const validViewerSubscription = createSelector(
    (state: RootState) => state.company.companyViewerSubscription,
    (companyViewerSubscription: CompanyViewerSubscription) => {
        if (companyViewerSubscription.CurrentPeriodEnd < new Date()) {
            return false
        }
        return companyViewerSubscription?.CountUser > 0
    }
)