/// New Zealand Automobile Association | AA New Zealand

import { LogLevel } from "@azure/msal-browser";
import { AzureCloudInstance } from '@azure/msal-common/dist/authority/AuthorityOptions';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
    auth: {
        clientId: "9f85f729-6ffe-4d01-81ea-72340da4f066",
        authority: "https://sts.windows.net/9f85f729-6ffe-4d01-81ea-72340da4f066",
        redirectUri: window.location.origin+'/auth/login',
        azureCloudOptions: {
            azureCloudInstance: AzureCloudInstance.AzurePublic,
            tenant: '7151a4ea-90c1-4a04-849a-3e6e6ed3645f'
        }
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            // @ts-ignore
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};
