import { Theme } from '@mui/material/styles'
import { pxToRem } from 'utils/formatFontSize';

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function Link(theme: Theme) {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },

            styleOverrides: {
                root: {
                    '@media (max-width:1400px)': {
                        fontSize: pxToRem(10),
                    },
                },
            },
        },
    }
}
