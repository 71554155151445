import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { HelmetProvider } from 'react-helmet-async'
import JwtProvider from './components/authentication/JwtProvider'
import LocalizationProviderCompass from 'contexts/LocalizationProviderCompass'
// theme
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext'
// redux
import { persistor, store } from 'store'
import ThemeConfig from 'theme'
import './theme/App.css'
import NotistackProvider from './components/NotistackProvider'
import ScrollToTop from './components/ScrollToTop'
import PageErrorHandler from './components/ErrorHandler/PageErrorHandler'

import './App.css'

import Router from './routes'
import { getUserLocation } from 'utils/location';
import SkeletonAppLoading from 'components/SkeletonLoaders/SkeletonAppLoading';
import { injectStoreAxios } from './utils/axios'

injectStoreAxios(store)

function App() {
    return (
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PageErrorHandler>
                    <PersistGate
                        loading={<SkeletonAppLoading />}
                        persistor={persistor}
                        onBeforeLift={ () => {
                            Promise.allSettled([getUserLocation()])
                        }}>
                        <ThemeConfig>
                            <LocalizationProviderCompass>
                                <CollapseDrawerProvider>
                                    <NotistackProvider>
                                        <BrowserRouter>
                                            <JwtProvider>
                                                <ScrollToTop />
                                                <Router />
                                            </JwtProvider>
                                        </BrowserRouter>
                                    </NotistackProvider>
                                </CollapseDrawerProvider>
                            </LocalizationProviderCompass>
                        </ThemeConfig>
                    </PersistGate>
                </PageErrorHandler>
            </ReduxProvider>
        </HelmetProvider>

    )
}

export default App
