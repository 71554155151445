import React, { useMemo } from 'react'
import { SectionComponentProps } from 'views/insights/sections/type';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'hooks/useAppRedux';
import { getInsightsEntityData } from 'store/slices/insights';
import { groupBy } from 'lodash';
import { dateRangeInImpactTiming, dayRange } from 'utils/date';
import InitiativeLink from 'components/InitiativeLink';


const BlackoutRisks:React.FC<SectionComponentProps>  = (
    { expanded, onChange, data, weeks } : SectionComponentProps
) => {

    const plainHierarchy = useAppSelector(getInsightsEntityData).plainHierarchy
    const highlightDates = useAppSelector(getInsightsEntityData).highlightDates

    const periods: Record<string, any> = useMemo(() => {
        const byDivision = groupBy(highlightDates, (item) => item.division)
        const res = Object.entries(byDivision).map(([key, values]) => {
            const hierarchyItem = plainHierarchy[key]
            if (!hierarchyItem) {
                return null;
            }
            let impacts: Record<string, any>[] = []
            let dates: Date[] = []
            values.map((i) => i.dates).flat().forEach((date) => {
                const { From, To } = dayRange(date)
                hierarchyItem.impacts.forEach((impact) => {
                    if (dateRangeInImpactTiming(impact.Periods, [From, To])) {
                        dates.push(date)
                        impacts.push(impact)
                    }
                })
            })

            if (dates.length === 0) {
                return null
            }

            return {
                ...hierarchyItem,
                dates,
                impacts
            }
        }).filter((i) => i)
        return res
    }, [highlightDates, plainHierarchy])

    const renderContent = (): React.ReactElement => {
        const initiatives = groupBy(periods, (item) => item.name)
        return (
            <Typography variant={'body2'}>
                There are impact activities from
                {

                    Object.values(initiatives).map((items: Record<string, any>[]) => {
                        const item = items[0]
                        const impact = item.impacts[0]
                        return (
                            <div key={impact.InitiativeId}>
                                <InitiativeLink id={impact.InitiativeId} name={impact.Initiatives}/>  during the blackout period for <b>{item.name}</b>
                            </div>
                        )
                    })
                }
                You may want to shift these accordingly.
            </Typography>
        )
    }

    if (periods.length === 0) {
        return (<></>)
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    If there are impacts during the blackout period?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                { renderContent() }
            </AccordionDetails>
        </Accordion>
    )
}

export default BlackoutRisks