import axios, { AxiosInstance } from 'axios'
import config from 'config'
import { get, isEmpty } from 'lodash'
import { getItemSession, saveItemSession } from 'utils/storage';
import { logoutFn } from 'hooks/useAuth';

import { sessionExists } from 'store/slices/authJwt/helpers';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore'

let store: EnhancedStore

export const injectStoreAxios = (_store: EnhancedStore) => {
    store = _store
}

const axiosInstance: AxiosInstance = axios.create({
    ///headers
})

export const sandBoxInstance = axios.create({
    baseURL: config.sandboxApiUrl,
})


axiosInstance.interceptors.response.use(
    (response) => {
        const { success, status, isSuccess, statusText } = response.data
        if (
            success === true ||
            status === 1 ||
            isSuccess === true ||
            ('' + success).toLowerCase() === 'success' ||
            ('' + statusText).toLowerCase() === 'ok' ||
            (success === undefined && (status === undefined || status === null) && isSuccess === undefined && response.status >= 200 && response.status < 400)
        ) {
            return Promise.resolve(response)
        }
        if (response?.config?.url?.includes('CreateUpdateUserSession')) {
            return Promise.resolve(response)
        }
        if ((response.headers['content-type']||'').includes('text/html')) {
            return Promise.reject('Failed to fetch response. Got some unknown text.')
        }
        return Promise.reject(response)
    },
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject(error)
        }
        if (axios.isAxiosError(error) || error?.response?.status) {
            const status = error?.response?.status
            if (status === 401 && sessionExists()) {
                logoutFn(store.dispatch)
                return Promise.reject(error)
            }
        }
        const contentType = get(error, 'response.headers.content-type')
        if ((contentType||'').includes('text/html')) {
            return Promise.reject('Failed to fetch response. Got some unknown text.')
        }
        return Promise.reject(error?.response?.data || 'Request got an error in response. Something went wrong')
    }
)

sandBoxInstance.interceptors.response.use(
    (response) => {
        const { success, status, isSuccess, statusText } = response.data
        if (
            success === true ||
            status === 1 ||
            isSuccess === true ||
            ('' + success).toLowerCase() === 'success' ||
            ('' + statusText).toLowerCase() === 'ok' ||
            (success === undefined && status === undefined && isSuccess === undefined && response.status >= 200 && response.status < 400)
        ) {
            return Promise.resolve(response)
        }
        if (response?.config?.url?.includes('CreateUpdateUserSession')) {
            return Promise.resolve(response)
        }
        if ((response.headers['content-type']||'').includes('text/html')) {
            return Promise.reject('Failed to fetch response. Got some unknown text')
        }
        return Promise.reject(response)
    },
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject(error)
        }
        const contentType = get(error, 'response.headers.content-type')
        if ((contentType||'').includes('text/html')) {
            return Promise.reject('Failed to fetch response. Got some unknown text.')
        }
        return Promise.reject(error?.response?.data || 'Request got an error in response. Something went wrong')
    }
)

const sandBoxAuth = async () => {
    //const formData = new URLSearchParams()
    //formData.append('demoData', 'true')
    //formData.append('grant_type', 'password')
    try {
        const resp = await sandBoxInstance.post('api/Sandbox/Token', {
            params: {
                request: 'd',
            }
        })
        const { access_token } = resp?.data || null
        if (!isEmpty(access_token)) {
            sandBoxInstance.defaults.headers.common.Authorization = `Bearer ${access_token}`
            sessionStorage.setItem('sandBoxExpire', resp.data['.expires'])
        }
    } catch (e) {
        const items = getItemSession('notification', []);
        items.push({
            type: 'warning',
            message: 'Failed to authorize into sandbox'
        })
        saveItemSession('notification', items)
    }
}

export const getSandboxInstance = async () => {
    const expires = sessionStorage.getItem('sandBoxExpire')
    // @ts-ignore
    if (new Date(expires || 'Thu, 14 Oct 2001 13:30:26 GMT') <= new Date() || isEmpty(sandBoxInstance.defaults.headers.common?.Authorization)) {
        await sandBoxAuth()
    }

    try {
        const resp = await sandBoxInstance.post('/api/Welcome/GetWelcome')
        if (resp?.data?.status !== 1) {
            await sandBoxAuth()
        }
    } catch (err) {
        await sandBoxAuth()
    }
    return sandBoxInstance
}

export const responseData = (data: any) => data?.responseData || data?.rm?.responseData || data?.rm || data


export const deleteEntityApi = (url: string, headers = {}) =>
    new Promise((resolve, reject) => {
        axiosInstance
            .delete(url, headers)
            .then(({ data }) => resolve(responseData(data)))
            .catch((error) => reject(error))
    })

export const getResponseData = (url: string, data = {}, headers = {}) =>
    new Promise((resolve, reject) => {
        axiosInstance
            .post(url, data, headers)
            .then(({ data }) => resolve(responseData(data)))
            .catch((error) => reject(error))
    })

export const fetchResponseData = (url: string, headers = {}) =>
    new Promise((resolve, reject) => {
        axiosInstance
            .get(url, headers)
            .then(({ data }) => resolve(responseData(data)))
            .catch((error) => reject(error))
    })

/** THROWS ERRORS! */
export const blobToB64 = async (blob: Blob) => {
    const reader = new FileReader()
    await new Promise((resolve, reject) => {
        reader.onload = resolve
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
    if (!reader.result) throw new Error('blobToB64 got empty result')
    if (reader.result instanceof ArrayBuffer) throw new Error('blobToB64 got wrong format result')
    return reader.result.replace(/^data:.+;base64,/, '')
}

export default axiosInstance

export const getErrorMessage = (error: any) => {
    const data = error?.response?.data || error?.data || error || {}
    const errors = data?.errors
    if (errors) {
        return Object.values(errors).join(', ')
    }
    return data?.message || data?.rm?.message || 'Something went wrong'
}


export const responseTrasnform = (resp: string) => {
    try {
        return JSON.parse(resp)
    } catch (e) {
        let arr = resp.split('\n')
        let pos = 0
        while (arr.length > 0) {
            if (arr[pos] === '({') {
                arr[pos] = '{'
            }
            if (arr[pos] === '```json') {
                arr[pos] = ''
            }
            if (arr[pos++].trim() !== "{") {
                arr.shift()
            } else {
                break
            }
        }
        pos = arr.length - 1
        while (arr.length > 0) {
            if (arr[pos] === '})') {
                arr[pos] = '}'
            }

            if (arr[pos] === '```') {
                arr.pop()
                break
            }

            if (arr[pos--]?.trim() !== "}") {
                arr.pop()
            } else {
                break
            }
        }
        return JSON.parse(arr.join('\n'))
    }
}
