// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'


export default class ErrorHandler extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null, hasError: false }
    }

    static getDerivedStateFromError(_error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.log(error)
        try {
            //@ts-ignore
            window.Bugsnag?.notify(error)
        } catch (err) {
           // console.log('notify bugsnag error', err)
        }
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    renderError() {
        return ''
    }

    render() {
        if (this.state.hasError) {
            return this.renderError()
        } else {
            return this.props.children
        }
    }
}

ErrorHandler.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
