import React from 'react'
import { AIInsightData } from '../../../store/slices/insights'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SectionComponentProps } from './type'
import useAuth from '../../../hooks/useAuth'

interface AIInsightsSectionProps extends Omit<SectionComponentProps, 'data'>{
    data: AIInsightData[]
}

interface AIInsightsSectionItemProps extends Omit<AIInsightsSectionProps, 'data'> {
    data: AIInsightData
}

const AIInsightsSectionItem: React.FC<AIInsightsSectionItemProps> = ({data, expanded, onChange}) => {

    return (<Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>
                {data['Insight Title']}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            {data.Details}
            <br />
            Recommendation:<br />
            {data.Recommendation}
        </AccordionDetails>
    </Accordion>)
}

const AIInsightsSection: React.FC<AIInsightsSectionProps> = ({data, weeks, expanded, onChange}) => {

    const { user } = useAuth()

    const [ page, setPage ] = React.useState<Record<string, boolean>>({})


    if (!['company@admin.com',].includes(user?.email)) {
        return (<></>)
    }

    if (data.length === 0) {
        return (<></>)
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>AI Assistant</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    data.map((item, index) => (
                        <AIInsightsSectionItem
                            key={"AIInsightsSectionItem"+index}
                            data={item}
                            weeks={weeks}
                            expanded={ page[`ai-${index}`] === true }
                            onChange={() => setPage({ ...page, [`ai-${index}`]: !Boolean(page[`ai-${index}`]) })} />
                    ))
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default AIInsightsSection;