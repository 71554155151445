import { useLayoutEffect, useRef } from 'react'

/** does not trigger re-rendering */
const useScrollSaver = (trigger: boolean) => {
    // original element
    const ref = useRef<Element>()

    // helpers
    const scrollPosSaved = useRef(0)
    const save = () => {
        if (ref.current) {
            //console.log('saved: ', ref.current.scrollTop)
            scrollPosSaved.current = ref.current.scrollTop
        }
    }
    const restore = () => {
        if (ref.current) {
            //console.log('restored: ', scrollPosSaved.current);
            ref.current.scrollTo(0, scrollPosSaved.current)
        }
    }

    // logic
    const saved = useRef(false)
    if (trigger && !saved.current) {
        save()
        saved.current = true
    }
    useLayoutEffect(() => {
        if (!trigger && saved.current) {
            restore()
            saved.current = false
        }
    }, [trigger])

    return ref
}

export default useScrollSaver
