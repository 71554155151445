import { useAppDispatch, useAppSelector } from 'hooks/useAppRedux';
import { useEffect } from 'react';
import { fetchUserSettings, getUserSettings, getUserSettingsState, saveUserSettings } from 'store/slices/settings';
import { ThemeDirection, ThemeMode } from '../@types/settings';
import { isObject } from 'lodash';
import { objectEquals } from 'utils/array';

function useSettings(isMounted: boolean = true) {
    const dispatch = useAppDispatch()

    const userSettings = useAppSelector(getUserSettings)

    const { loaded, loading } = useAppSelector(getUserSettingsState)

    useEffect(() => {
        if (!loaded && !loading) {
            dispatch(fetchUserSettings())
        }
    }, [ loaded ])

    const handleSettingsChanged = (key: string, value: any) => {
        if (!isMounted) {
            // we are setting default values
            return;
        }
        if (isObject(value)) {
            if (!objectEquals(userSettings[key], value, Object.keys(value))) {
                dispatch(saveUserSettings({ ...userSettings, [key]: value }))
            }
        }
        else if (userSettings[key] !== value) {
            dispatch(saveUserSettings({ ...userSettings, [key]: value }))
        }
    }

    return {
        // Mode
        themeDirection: userSettings.themeDirection,
        themeMode: userSettings.themeMode,
        isLightTheme: userSettings.themeMode === 'light',
        setSettingsItem: (key: string, value: any) => {
            handleSettingsChanged(key, value)
        },
        getSettingsItem: (key: string, defaultValue: any) => {
            return userSettings[key] !== undefined ? userSettings[key] : defaultValue
        },
        selectMode: (mode: ThemeMode) => handleSettingsChanged('themeMode', mode),
        selectDirection: (direction: ThemeDirection) => handleSettingsChanged('themeDirection', direction),
    }
}

export default useSettings
