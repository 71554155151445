// Octagon reference: http://mathworld.wolfram.com/Octagon.html
import {tau} from './math';
import {rotatePoint} from './rotatePoint';
import * as d3_7 from 'd3-7'

var circumradiusCoeff = 1/2 * Math.sqrt(4 + 2 * Math.sqrt(2)); // ~ 1.3065629648763766

function circumradius(side: number) { return side * circumradiusCoeff; }

function sideLength(area: number) {
    var num = area * (1 - Math.sqrt(2));
    var denom = 2;
    return Math.sqrt(-1 * num / denom);
}

function drawBuild(theta?: number): d3_7.SymbolType {
    var t = theta || 0;

    return {
        draw(context, size) {
            const s = sideLength(size);
            const R = circumradius(s);
            // @ts-ignore
            context.moveTo.apply(context, rotatePoint(R, 0, t));

            for (let i = 0; i < 8; ++i) {
                const a = tau * i / 8;
                const x = Math.cos(a) * R;
                const y = Math.sin(a) * R;
                // @ts-ignore
                context.lineTo.apply(context, rotatePoint(x, y, t));
            }
            context.closePath();
        }
    }
}

export var octagon = drawBuild(tau / 16)
