import React from 'react'
import { PATH_REPORTS } from './paths'
// layouts
import AuthGuard from 'guards/AuthGuard'
import { RouteObject } from 'react-router-dom';
import DashboardLayout from 'layouts/dashboard';
import { lazyLoad } from '../utils/routes'

// ----------------------------------------------------------------------

const HeatMapPage = lazyLoad(() => import('views/reports/Heatmaps'), 'HeatMapPage')
const TotalImpactPage = lazyLoad(() => import('views/reports/TotalImpact'), 'TotalImpactPage')
const StakeHolderPage = lazyLoad(() => import('views/reports/StakeholderChart'), 'StakeHolderPage')
const TimelinePage = lazyLoad(() => import('views/reports/TimeLine'), 'TimelinePage')
const ExecutiveSummaryPage = lazyLoad(() => import('views/reports/ExecutiveSummary'), 'ExecutiveSummaryPage')
const BubblePage = lazyLoad(() => import('views/reports/BubbleCharts'), 'BubblePage')
const StrategyImpactPage = lazyLoad(() => import('views/reports/StrategyImpact'), 'StrategyImpactPage')
const SpeedometerPage = lazyLoad(() => import('views/reports/Speedometer'), 'SpeedometerPage')
const ResourcingPage = lazyLoad(() => import('views/reports/Resourcing'), 'ResourcingPage')
const ReadinessPage = lazyLoad(() => import('views/reports/Readiness'), 'ReadinessPage')
const RadarPage = lazyLoad(() => import('views/reports/Radar'), 'RadarPage')

const ReportsRoute = [
    {
        path: PATH_REPORTS.reports.heatmaps,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Heatmaps'}>
                    <HeatMapPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.totalImpact,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Total Impact'}>
                    <TotalImpactPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.stakeholderChart,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Stakeholder Impact Chart'}>
                    <StakeHolderPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.timelineChart,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / TimeLine Chart'}>
                    <TimelinePage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.executiveSummary,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Executive Summary'}>
                    <ExecutiveSummaryPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.bubblechart,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Bubbles charts'}>
                    <BubblePage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.strategyimpact,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Strategy Impact'}>
                    <StrategyImpactPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.speedometer,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Speedometer'}>
                    <SpeedometerPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.resourcingChart,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Resourcing Chart'}>
                    <ResourcingPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.readinessChart,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Impact-Readiness Chart'}>
                    <ReadinessPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: PATH_REPORTS.reports.radarChart,
        element: (
            <AuthGuard>
                <DashboardLayout title={'Reports / Radar Chart'}>
                    <RadarPage />
                </DashboardLayout>
            </AuthGuard>
        ),
    }
] as RouteObject[]

export default ReportsRoute

