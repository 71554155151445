import React, { useMemo } from 'react'
import { SectionComponentProps } from 'views/insights/sections/type';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'hooks/useAppRedux';
import { getInsightsEntityData } from 'store/slices/insights';
import { getCompanyHierarchyTree } from 'store/slices/company';
import { groupBy } from 'lodash';
import { ChangeToleranceLevelExtended } from '../../../../@types/initiative';
import { findHierarchyItem } from 'utils/hierarchy';
import DisplayPeriods from 'components/DisplayPeriods';
import { isInWeekInMonthRange } from 'utils/date';

const RiskOfExceedingChangeTolerance:React.FC<SectionComponentProps>  = (
    { expanded, onChange, data, weeks } : SectionComponentProps
) => {
    const companyHierarchyTree = useAppSelector(getCompanyHierarchyTree)
    const changeToleranceLevels = useAppSelector(getInsightsEntityData).changeToleranceLevels
    const plainHierarchy = useAppSelector(getInsightsEntityData).plainHierarchy

    const results = useMemo(() => {
        return changeToleranceLevels.map((item) => {
            const entity = plainHierarchy[item.HierarchyItemId]
            if (!entity) {
                return null
            }
            if (item.Level === 0) {
                return null
            }
            const hours = entity.impacts.map((i: Record<string, any>) => {
                // @ts-ignore
                if (isInWeekInMonthRange(i.range, item )) {
                    return i.ImpactHours || 0
                }
                return 0
            }).reduce((a: number, b: number) => a + b, 0)
            const almostThere = hours - item.Level
            const almostExceeded = almostThere > 0 && almostThere < 1
            if (hours > item.Level || almostExceeded ) {
                return { ...item, hours, almostExceeded }
            }
            return false
        }).filter((i) => i) as ChangeToleranceLevelExtended[]

    }, [changeToleranceLevels, plainHierarchy])

    const renderContent = (): React.ReactElement => {
        const group = groupBy(results, (item) => item.HierarchyItemId)
        return (<>
            {
                Object.values(group).map((items: ChangeToleranceLevelExtended[]) => {
                    const item = items[0]
                    const hierarchyItem = findHierarchyItem(item.HierarchyItemId, companyHierarchyTree)
                    const reached = items.filter((item) => item.almostExceeded === false)
                    const almostReached = items.filter((item) => item.almostExceeded === true)
                    return (
                        <Typography variant={'body2'} key={item.HierarchyItemId}>
                            {
                                reached.length > 0 && (
                                    <>
                                        The change tolerance level for <b>{hierarchyItem?.Name}</b> is exceeded for
                                        <DisplayPeriods periods={reached.map((i) => [ i.from, i.to ])} />.
                                        Check out initiative impact activities during this time period to see what may need to be re-sequenced.
                                    </>
                                )
                            }
                            {
                                almostReached.length > 0 && (
                                    <>
                                        <br/>
                                        The change tolerance level for <b>{hierarchyItem?.Name}</b> is almost reached during
                                        <DisplayPeriods periods={almostReached.map((i) => [ i.from, i.to ])} />.
                                        , please monitor this carefully
                                    </>
                                )
                            }
                        </Typography>
                    )
                })
            }
        </>)
    }

    if ((results || []).length === 0) {
        return (<></>)
    }

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    Risk of exceeding Change Tolerance
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    renderContent()
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default RiskOfExceedingChangeTolerance