import React from 'react'
import { Skeleton, Stack } from '@mui/material';


const InsightsSkeleton = () => (
    <Stack>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
        <br/>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
        <br/>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
        <br/>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
        <br/>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
        <br/>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
        <br/>
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'20px'} />
    </Stack>
)

export default InsightsSkeleton