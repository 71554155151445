import { ItemEntity } from 'views/insights/sections/BusinessCapacitySubSections/types';
import { BaseModel } from '../../../../@types/base';
import { find, forEach } from 'lodash';
import { getChildHierarchyItems, getGeographyHierarchyItems } from 'utils/hierarchy';
import { CompanyHierarchyItem, CompanyHierarchyTree } from '../../../../@types/settings';
import { GeographyHierarchyTree, Stakeholder } from '../../../../@types/impact';
import { DateRange } from 'components/DateRangePicker';
import { WeeksInMonth } from '../../../../@types/reports';
import { isInWeekInMonthRange, mergeWeeksInMonth } from 'utils/date';


export const highestPeriodFromWeek = (impacts: Record<string, any>, weeks: WeeksInMonth[]): DateRange<Date>[] | undefined => {
    const res = weeks.map((week) => {
        const weekHours = impacts.map((impact: Record<string, any>) => {
            if (isInWeekInMonthRange(impact.range, week)) {
                return impact.ImpactHours
            }
            return 0
        }).reduce((a: number, b: number) => a + b, 0)
        return [ week, weekHours ]
    })

    res.sort((a, b) => b[1] - a[1])
    const [ week, value ] = res[0] || [ undefined, undefined ]
    if (!week) {
        return undefined
    }
    const weekArr = [ week ]
    res.slice(1).every(([ week, val ]) => {
        if (val === value) {
            weekArr.push(week)
            return true
        }
        return false
    })

    const result = mergeWeeksInMonth(weekArr)
    result.sort((a, b) => (a[0] as Date).getTime() - (b[0] as Date).getTime())
    return result
}

export const increaseCount = (obj: Record<string, ItemEntity>, model: BaseModel, impact:  Record<string, any>): ItemEntity => {
    const resObj = obj[model.Id] || {
        name: model.Name,
        count: 0,
        children: {},
        hours: [],
        impacts: [],
    }
    const hours = impact.ImpactHours || 0
    resObj.count += hours
    resObj.hours.push(hours)
    resObj.impacts.push(impact)
    return resObj
}

export const isGoLiveImpact = (impact: Record<string, any>): boolean => (impact?.Activities||[]).some((activity: string) => activity.replaceAll(' ', '').toLowerCase().includes('live'))


export const getTopItem = (items: Record<string, ItemEntity>): ItemEntity|undefined => {
    const children = Object.values(items||{}).filter((entity) => entity.count > 0)
    children.sort((a, b) => b.count - a.count)
    return children[0] || undefined
}

export const buildByGeography = (result: Record<string, ItemEntity>, impact: Record<string, any>, impactGeographyTree: GeographyHierarchyTree): Record<string, ItemEntity> => {
    let res = result
    forEach(impact.EmpGeographyRegion, (region_name: string) => {
        const region = find(impactGeographyTree?.Region, { Name: region_name })
        if (region) {
            const regionItems = increaseCount(res, region, impact)
            const networkOffices = getGeographyHierarchyItems(impactGeographyTree?.NetworkOffice || [], [region.Id])
            forEach(networkOffices, (networkOffice) => {
                if (impact.EmpGeographyNetworkOffice.includes(networkOffice.Name)) {
                    const networkOfficesItems = increaseCount(regionItems.children, networkOffice, impact)
                    const districtOffices = getGeographyHierarchyItems(impactGeographyTree.DistrictOffice, [networkOffice.Id])
                    forEach(districtOffices, (districtOffice) => {
                        if (impact.EmpGeographyDistrictOffice.includes(districtOffice.Name)) {
                            networkOfficesItems.children = {
                                ...networkOfficesItems.children,
                                [districtOffice.Id]: increaseCount(networkOfficesItems.children, districtOffice, impact)
                            }
                        }
                    })
                    regionItems.children = { ...regionItems.children, [networkOffice.Id]: networkOfficesItems }
                }
            })
            res = { ...res, [region?.Id || '']: regionItems }
        }
    })
    return res
}


export const sortByCount = (items: Record<string, ItemEntity>): ItemEntity[] => {
    const res = Object.values(items)
    res.sort((a, b) => b.count - a.count)
    return res
}

export const buildByStakeHolders = (
    result: Record<string, ItemEntity>,
    impact: Record<string, any>,
    hierarchyTree: CompanyHierarchyTree,
    employeeStakeHolderList: Stakeholder[]
): Record<string, ItemEntity> => {
    let res = result
    forEach(impact.Stakeholders, (stakeholderName: string) => {
        const stakeholder = find(employeeStakeHolderList, { Name: stakeholderName })
        if (stakeholder) {
            const stakeholderItems = increaseCount(res, stakeholder, impact)

            forEach(impact.Division, (division_str: string) => {
                const division = find(hierarchyTree?.Division, { Name: division_str })
                if (division) {
                    const divisionItems = increaseCount(res, division, impact)
                    stakeholderItems.children = { ...stakeholderItems.children, [division.Id]: divisionItems }
                }
            })
            res = { ...res, [stakeholderItems?.Id || '']: stakeholderItems }
        }
    })
    return res
}


export const buildByHierarchy = (result: Record<string, ItemEntity>, impact: Record<string, any>, hierarchyTree: CompanyHierarchyTree): Record<string, ItemEntity> => {
    let res = result
    forEach(impact.Division, (division_str: string) => {
        const division = find(hierarchyTree?.Division, { Name: division_str })
        if (division) {
            const divisionItems = increaseCount(res, division, impact)
            const subDivisions = getChildHierarchyItems(hierarchyTree?.SubDivision || [], [division?.Id || '']) as CompanyHierarchyItem[]
            forEach(subDivisions, (subDivision: CompanyHierarchyItem) => {
                if (impact.SubDivision.includes(subDivision.Name)) {
                    const subDivisionItems = increaseCount(divisionItems.children, subDivision, impact)
                    const teams = getChildHierarchyItems(hierarchyTree?.Team || [], [subDivision.Id]) as CompanyHierarchyItem[]
                    forEach(teams, (team: CompanyHierarchyItem) => {
                        if (impact.Team.includes(team.Name)) {
                            subDivisionItems.children = {
                                ...subDivisionItems.children,
                                [team.Id]: increaseCount(subDivisionItems.children, team, impact)
                            }
                        }
                    })
                    divisionItems.children = { ...divisionItems.children, [subDivision.Id]: subDivisionItems }
                }
            })
            res = { ...res, [division?.Id || '']: divisionItems }
        }
    })
    return res
}


export const buildTopCompanyHierarchy = (impacts: Record<string, any>[], hierarchyTree: CompanyHierarchyTree): ItemEntity[] => {
    let result = {} as Record<string, ItemEntity>
    (impacts || []).forEach((impact: Record<string, any>) => {
        forEach(impact.Division, (division_str: string) => {
            const division = find(hierarchyTree?.Division, { Name: division_str })
            if (division) {
                const divisionItems = increaseCount(result, division, impact)
                const subDivisions = getChildHierarchyItems(hierarchyTree?.SubDivision || [], [division?.Id || '']) as CompanyHierarchyItem[]
                forEach(subDivisions, (subDivision: CompanyHierarchyItem) => {
                    if (impact.SubDivision.includes(subDivision.Name)) {
                        const subDivisionItems = increaseCount(divisionItems.children, subDivision, impact)
                        const teams = getChildHierarchyItems(hierarchyTree?.Team || [], [subDivision.Id]) as CompanyHierarchyItem[]
                        forEach(teams, (team: CompanyHierarchyItem) => {
                            if (impact.Team.includes(team.Name)) {
                                subDivisionItems.children = {
                                    ...subDivisionItems.children,
                                    [team.Id]: increaseCount(subDivisionItems.children, team, impact)
                                }
                            }
                        })
                        divisionItems.children = { ...divisionItems.children, [subDivision.Id]: subDivisionItems }
                    }
                })
                result = { ...result, [division?.Id || '']: divisionItems }
            }
        })
    });

    const res = Object.values(result)
    res.sort((a, b) => b.count - a.count)
    return res
}