import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const IconMinus = (props: SvgIconProps) => <SvgIcon
    {...props}
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24">
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M20 12H4"/>
</SvgIcon>
