import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { PATH_AUTH } from 'routes/paths'
import SkeletonAuthenticatedApp from 'components/SkeletonLoaders/SkeletonAuthenticatedApp';

// ----------------------------------------------------------------------

type CompanyAdminGuardProps = {
    children: React.ReactNode
}

export default function SuperAdminGuard({ children }: CompanyAdminGuardProps) {
    const { isLoading, isAuthenticated, isSuperAdmin } = useAuth()

    if (isLoading) {
        return <SkeletonAuthenticatedApp />
    }

    if (!isAuthenticated) {
        return <Navigate to={PATH_AUTH.login} />
    }

    if (!isSuperAdmin) {
        return <Navigate to='/' />
    }

    return <>{children}</>
}
