import { createSlice } from '@reduxjs/toolkit'

// ----------------------------------------------------------------------

interface UserState {
    isLoading: boolean
    error: boolean
}

const initialState: UserState = {
    isLoading: false,
    error: false,
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------