import React from 'react';
import { useAppSelector } from 'hooks/useAppRedux';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SectionComponentProps } from 'views/insights/sections/type';
import { getInsightsEntityData } from 'store/slices/insights';
import { highestPeriodFromWeek } from 'views/insights/sections/BusinessCapacitySubSections/utils';
import DisplayPeriods from 'components/DisplayPeriods';

const BusinessCapacityGeographySection = ({
                                              expanded,
                                              onChange,
                                              data,
                                              weeks
                                          }: SectionComponentProps) => {
    const geographies = useAppSelector(getInsightsEntityData).geographyEntities

    const topGeography = (geographies||[])[0]

    const highestPeriod =  highestPeriodFromWeek(topGeography?.impacts, weeks)

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                    Which geography has the highest impacts?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    !topGeography && (
                        <Typography variant={'body2'}>
                            No data
                        </Typography>
                    )
                }
                {
                    topGeography && (
                        <Typography variant={'body2'}>
                            <b>{topGeography.name}</b> has the highest impacts between <DisplayPeriods periods={highestPeriod} />
                        </Typography>
                    )
                }

            </AccordionDetails>
        </Accordion>
    )
}

export default BusinessCapacityGeographySection